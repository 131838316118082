import * as _ from 'lodash';
import { strEnum } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
const DividerOrientation = strEnum(['horizontal', 'vertical']);
/**
 * A method that checks whether a value is of type DividerOrientation.
 */
function isDividerOrientation(val) {
  return _.includes(DividerOrientation, val);
}
export { DividerOrientation, isDividerOrientation };
