/**
 * The image info
 */
class ImageDimension {
  constructor(item = {}) {
    this.width = item.width;
    this.height = item.height;
    this.sourcePath = item.sourcePath;
    this.isExtensionValid = item.isExtensionValid;
  }
}
export { ImageDimension };
