import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
import { isTooltipContent } from '../../../common-utils-tooltip/esm2022/lib/tooltip.types.mjs';
import { isMenuContent } from '../../../popups-menu/esm2022/lib/menu.config.mjs';

/**
 * A method that checks whether a value is of type ICardButton.
 * @param value - value to be checked.
 * @private
 */
function _isCardButton(value) {
  return TypeUtils.isObjectValid(value, [{
    name: 'disabled',
    checkFunction: val => {
      return _.isBoolean(val);
    }
  }, {
    name: 'tabIndex',
    checkFunction: val => {
      return _.isNumber(val);
    }
  },
  //TODO: deprecated field, should be removed in 13.0.0
  {
    name: 'tooltipText',
    checkFunction: val => {
      return _.isString(val);
    }
  }, {
    name: 'tooltip',
    checkFunction: isTooltipContent
  }]);
}
/**
 * A method that checks whether a value is array of CardIcons.
 * @param value - value to be checked.
 */
function isCardIcons(value) {
  return TypeUtils.isArrayOf(value, val => {
    return _.isString(val) || isICardIcon(val);
  });
}
/**
 * A method that checks whether a value is of type ICardIcon.
 * @param value - value to be checked.
 */
function isICardIcon(value) {
  return TypeUtils.isObjectValid(value, [{
    name: 'icon',
    checkFunction: val => {
      return _.isString(val);
    }
  }, {
    name: 'tooltip',
    checkFunction: val => {
      return isTooltipContent(val.content);
    }
  }]);
}
/**
 * A method that checks whether a value is of type ICardIconButton.
 * @param value - value to be checked.
 */
function isCardIconButton(value) {
  return _isCardButton(value) && value.hasOwnProperty('icon') && _.isString(value.icon);
}
/**
 * A method that checks whether a value is of type ICardTextButton.
 * @param value - value to be checked.
 */
function isCardTextButton(value) {
  return _isCardButton(value) && value.hasOwnProperty('label') && _.isString(value.label);
}
/**
 * A method that checks whether a value is of type ICardIconButton[].
 * @param value - value to be checked.
 */
function isCardIconButtons(value) {
  return TypeUtils.isArrayOf(value, item => {
    return isCardIconButton(item);
  });
}
/**
 * A method that checks whether a value is of type ICardTextButton[].
 * @param value - value to be checked.
 */
function isCardTextButtons(value) {
  return TypeUtils.isArrayOf(value, item => {
    return isCardTextButton(item);
  });
}
/**
 * A method that checks whether a value is of type ICardHeader.
 * @param value - value to be checked.
 */
function isCardHeader(value) {
  return TypeUtils.isObjectValid(value, [{
    name: 'title',
    checkFunction: val => {
      return _.isString(val);
    },
    isMandatory: true
  }, {
    name: 'subtitle',
    checkFunction: val => {
      return _.isString(val);
    }
  }, {
    name: 'icons',
    checkFunction: isCardIcons
  }, {
    name: 'iconButtons',
    checkFunction: isCardIconButtons
  }, {
    name: 'contextMenu',
    checkFunction: isMenuContent
  }]);
}
/**
 * A method that checks whether a value is of type ICardFooter.
 * @param value - value to be checked.
 */
function isCardFooter(value) {
  return TypeUtils.isOptionalObjectValid(value, [{
    name: 'textButtons',
    checkFunction: isCardTextButtons
  }, {
    name: 'icons',
    checkFunction: isCardIcons
  }, {
    name: 'iconButtons',
    checkFunction: isCardIconButtons
  }]);
}
export { _isCardButton, isCardFooter, isCardHeader, isCardIconButton, isCardIconButtons, isCardIcons, isCardTextButton, isCardTextButtons, isICardIcon };
