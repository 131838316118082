class TruncateElementBase {
  constructor(el, renderer) {
    this.el = el;
    this.renderer = renderer;
  }
  /**
   * Method to remove any existing nodes which has been added by renderer for the truncation attempts
   */
  removeAllTruncateNodes() {
    const nodes = this.el.nativeElement.querySelectorAll('.ra-truncate-item');
    for (let i = nodes.length - 1; i >= 0; i--) {
      this.renderer.removeChild(this.el.nativeElement, nodes[i]);
    }
  }
  /**
   * Method that helps to create a styled element for the truncation attempts
   */
  createTruncateElement(text, className, styles) {
    const el = this.renderer.createElement('span');
    // default truncate element styles & class
    this.renderer.appendChild(el, this.renderer.createText(text));
    this.renderer.addClass(el, 'ra-truncate-item');
    this.renderer.setStyle(el, 'visibility', 'hidden');
    this.renderer.setStyle(el, 'position', 'absolute');
    this.renderer.addClass(el, className);
    styles && Object.keys(styles).forEach(key => {
      this.renderer.setStyle(el, key, styles[key]);
    });
    this.renderer.appendChild(this.el.nativeElement, el);
    return el;
  }
}
export { TruncateElementBase };
