import { fromEvent, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
class EventUtils {
  /**
   * Calls stopPropagation method on events of supplied names, which are triggered on given elements.
   * @param elements Array of elements which are listened for events to stop their propagation
   * @param eventNames Names of events which have to be stopped
   * @param destroy subject signalizing when event listeners have to be removed
   */
  static stopPropagationEvents(elements, eventNames, destroy) {
    const eventObservables = elements.map(element => {
      return eventNames.map(eventName => {
        return fromEvent(element.nativeElement, eventName);
      });
    }).flat();
    return merge(...eventObservables).pipe(takeUntil(destroy)).subscribe(event => {
      event.stopPropagation();
    });
  }
}
export { EventUtils };
