class BadgeUtils {
  /**
   * The method returns value depending on cap value.
   * value > cap, returns string cap value and char `+` -> cap+
   * value <= cap, returns value
   * value < 0, returns 0
   * cap   < 0, cap is set to 99 by default
   * @param value - input number value
   * @param cap - allowed upper limit of the input number value
   * @returns {string}
   */
  static getValueBadge(value, cap) {
    // negative cap is not allowed, so sets up 99 as default
    if (cap < 0) {
      cap = 99;
    }
    // negative value is not allowed, too
    if (value < 0) {
      return '0';
    }
    if (value > cap) {
      return `${cap}+`;
    }
    return value.toString();
  }
}
export { BadgeUtils };
