var Re = Object.defineProperty,
  be = Object.defineProperties;
var ye = Object.getOwnPropertyDescriptors;
var ie = Object.getOwnPropertySymbols;
var Se = Object.prototype.hasOwnProperty,
  Ie = Object.prototype.propertyIsEnumerable;
var ne = (a, e, n) => e in a ? Re(a, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: n
  }) : a[e] = n,
  C = (a, e) => {
    for (var n in e || (e = {})) Se.call(e, n) && ne(a, n, e[n]);
    if (ie) for (var n of ie(e)) Ie.call(e, n) && ne(a, n, e[n]);
    return a;
  },
  B = (a, e) => be(a, ye(e));
var E = (a, e, n) => new Promise((o, s) => {
  var l = R => {
      try {
        p(n.next(R));
      } catch (u) {
        s(u);
      }
    },
    m = R => {
      try {
        p(n.throw(R));
      } catch (u) {
        s(u);
      }
    },
    p = R => R.done ? o(R.value) : Promise.resolve(R.value).then(l, m);
  p((n = n.apply(a, e)).next());
});
import xe from "jwt-decode";
import Me from "mitt";
var P = (d => (d.READY = "ready", d.CONNECTED = "connected", d.DISCONNECTED = "disconnected", d.STOPPED = "stopped", d.POLLING = "polling", d.POLLING_STOPPED = "polling stopped", d.FLAGS_LOADED = "flags loaded", d.CACHE_LOADED = "cache loaded", d.CHANGED = "changed", d.ERROR = "error", d.ERROR_CACHE = "cache error", d.ERROR_METRICS = "metrics error", d.ERROR_AUTH = "auth error", d.ERROR_FETCH_FLAGS = "fetch flags error", d.ERROR_FETCH_FLAG = "fetch flag error", d.ERROR_STREAM = "stream error", d.ERROR_DEFAULT_VARIATION_RETURNED = "default variation returned", d))(P || {});
var Ce = {
    debug: !1,
    baseUrl: "https://config.ff.harness.io/api/1.0",
    eventUrl: "https://events.ff.harness.io/api/1.0",
    eventsSyncInterval: 6e4,
    pollingInterval: 6e4,
    streamEnabled: !0,
    cache: !1,
    authRequestReadTimeout: 0,
    maxStreamRetries: 1 / 0
  },
  ae = a => {
    let e = C(C({}, Ce), a);
    return e.pollingEnabled === void 0 && (e.pollingEnabled = e.streamEnabled), e.eventsSyncInterval < 6e4 && (e.eventsSyncInterval = 6e4), e.pollingInterval < 6e4 && (e.pollingInterval = 6e4), (!e.logger || !e.logger.debug || !e.logger.error || !e.logger.info || !e.logger.warn) && (e.logger = console), e;
  },
  U = (a, e = !0) => {
    e ? setTimeout(a, 0) : a();
  },
  N = (a, e) => Math.round(Math.random() * (e - a) + a),
  re = a => {
    let e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
      n = "",
      o = 0,
      s = Ae(JSON.stringify(a));
    for (; o < s.length;) {
      let l = s.charCodeAt(o++),
        m = s.charCodeAt(o++),
        p = s.charCodeAt(o++),
        R = l >> 2,
        u = (l & 3) << 4 | m >> 4,
        S = (m & 15) << 2 | p >> 6,
        A = p & 63;
      isNaN(m) ? S = A = 64 : isNaN(p) && (A = 64), n += e.charAt(R) + e.charAt(u) + e.charAt(S) + e.charAt(A);
    }
    return n;
  },
  Ae = a => a.replace(/\r\n/g, `
`).split("").map(e => {
    let n = e.charCodeAt(0);
    return n < 128 ? String.fromCharCode(n) : n > 127 && n < 2048 ? String.fromCharCode(n >> 6 | 192) + String.fromCharCode(n & 63 | 128) : String.fromCharCode(n >> 12 | 224) + String.fromCharCode(n >> 6 & 63 | 128) + String.fromCharCode(n & 63 | 128);
  }).join("");
function K(a) {
  return function (...n) {
    let [o, s] = a(n);
    return fetch(o, s);
  };
}
var oe = 3e4,
  V = class {
    constructor(e, n, o, s, l, m, p, R, u, S) {
      this.eventBus = e;
      this.configurations = n;
      this.url = o;
      this.apiKey = s;
      this.standardHeaders = l;
      this.fallbackPoller = m;
      this.logDebug = p;
      this.logError = R;
      this.eventCallback = u;
      this.maxRetries = S;
      this.closed = !1;
      this.connectionOpened = !1;
      this.disconnectEventEmitted = !1;
      this.reconnectAttempts = 0;
      this.retriesExhausted = !1;
    }
    start() {
      let e = u => {
          u.toString().split(/\r?\n/).forEach(n);
        },
        n = u => {
          if (u.startsWith("data:")) {
            let S = JSON.parse(u.substring(5));
            this.logDebugMessage("Received event from stream: ", S), this.eventCallback(S);
          }
        },
        o = () => {
          this.logDebugMessage("Stream connected"), this.eventBus.emit("connected"), this.reconnectAttempts = 0;
        },
        s = () => {
          clearInterval(this.readTimeoutCheckerId);
          let u = N(1e3, 1e4);
          if (this.reconnectAttempts++, this.logDebugMessage("Stream disconnected, will reconnect in " + u + "ms"), this.disconnectEventEmitted || (this.eventBus.emit("disconnected"), this.disconnectEventEmitted = !0), this.reconnectAttempts >= 5 && this.reconnectAttempts % 5 === 0 && this.logErrorMessage(`Reconnection failed after ${this.reconnectAttempts} attempts; attempting further reconnections.`), this.reconnectAttempts >= this.maxRetries) {
            this.retriesExhausted = !0, this.configurations.pollingEnabled ? this.logErrorMessage("Max streaming retries reached. Staying in polling mode.") : this.logErrorMessage("Max streaming retries reached. Polling mode is disabled and will receive no further flag updates until SDK client is restarted.");
            return;
          }
          setTimeout(() => this.start(), u);
        },
        l = u => {
          this.retriesExhausted || (u && this.logDebugMessage("Stream has issue", u), this.fallBackToPolling(), this.eventBus.emit("stream error", u), this.eventBus.emit("error", u), s());
        },
        m = C({
          "Cache-Control": "no-cache",
          Accept: "text/event-stream",
          "API-Key": this.apiKey
        }, this.standardHeaders);
      this.logDebugMessage("SSE HTTP start request", this.url), this.xhr = new XMLHttpRequest(), this.xhr.open("GET", this.url);
      for (let [u, S] of Object.entries(m)) this.xhr.setRequestHeader(u, S);
      this.xhr.timeout = 24 * 60 * 60 * 1e3, this.xhr.onerror = () => {
        this.connectionOpened = !1, l("XMLHttpRequest error on SSE stream");
      }, this.xhr.onabort = () => {
        this.connectionOpened = !1, this.logDebugMessage("SSE aborted"), this.closed || l(null);
      }, this.xhr.ontimeout = () => {
        this.connectionOpened = !1, l("SSE timeout");
      }, this.xhr.onload = () => {
        l(`Received XMLHttpRequest onLoad event: ${this.xhr.status}`);
      };
      let p = 0,
        R = Date.now();
      this.xhr.onprogress = () => {
        this.connectionOpened || (o(), this.connectionOpened = !0, this.disconnectEventEmitted = !1), this.stopFallBackPolling(), R = Date.now();
        let u = this.xhr.responseText.slice(p);
        p += u.length, this.logDebugMessage("SSE GOT: " + u), e(u);
      }, this.readTimeoutCheckerId = setInterval(() => {
        R < Date.now() - oe && (this.logDebugMessage("SSE read timeout"), this.xhr.abort());
      }, oe), this.xhr.send();
    }
    close() {
      this.connectionOpened = !1, this.closed = !0, this.xhr && this.xhr.abort(), clearInterval(this.readTimeoutCheckerId), this.eventBus.emit("stopped"), this.stopFallBackPolling();
    }
    fallBackToPolling() {
      !this.fallbackPoller.isPolling() && this.configurations.pollingEnabled && (this.logDebugMessage("Falling back to polling mode while stream recovers"), this.fallbackPoller.start());
    }
    stopFallBackPolling() {
      this.fallbackPoller.isPolling() && (this.logDebugMessage("Stopping fallback polling mode"), this.fallbackPoller.stop());
    }
    logDebugMessage(e, ...n) {
      this.configurations.debug && this.logDebug(`Streaming:  ${e}`, ...n);
    }
    logErrorMessage(e, ...n) {
      this.logError(`Streaming:  ${e}`, ...n);
    }
  };
function se(a, e, n, o, s, l) {
  let m = (a in n),
    p = m ? n[a] : e;
  if (m) o(a, p);else {
    let R = {
      flag: a,
      defaultVariation: e
    };
    s.emit("default variation returned", R);
  }
  return l ? {
    value: p,
    isDefaultValue: !m
  } : p;
}
var F = class {
  constructor(e, n, o, s, l) {
    this.fetchFlagsFn = e;
    this.configurations = n;
    this.eventBus = o;
    this.logDebug = s;
    this.logError = l;
    this.maxAttempts = 5;
  }
  start() {
    if (this.isPolling()) {
      this.logDebugMessage("Already polling.");
      return;
    }
    this.isRunning = !0, this.eventBus.emit("polling"), this.logDebugMessage(`Starting poller, first poll will be in ${this.configurations.pollingInterval}ms`), this.timeoutId = setTimeout(() => this.poll(), this.configurations.pollingInterval);
  }
  poll() {
    this.isRunning && this.attemptFetch().finally(() => {
      this.isRunning && (this.timeoutId = setTimeout(() => this.poll(), this.configurations.pollingInterval));
    });
  }
  attemptFetch() {
    return E(this, null, function* () {
      for (let e = 1; e <= this.maxAttempts; e++) {
        let n = yield this.fetchFlagsFn();
        if (n.type === "success") {
          this.logDebugMessage(`Successfully polled for flag updates, next poll in ${this.configurations.pollingInterval}ms. `);
          return;
        }
        if (this.logErrorMessage("Error when polling for flag updates", n.error), e >= this.maxAttempts) {
          this.logDebugMessage(`Maximum attempts reached for polling for flags. Next poll in ${this.configurations.pollingInterval}ms.`);
          return;
        }
        this.logDebugMessage(`Polling for flags attempt #${e} failed. Remaining attempts: ${this.maxAttempts - e}`, n.error);
        let o = N(1e3, 1e4);
        yield new Promise(s => setTimeout(s, o));
      }
    });
  }
  stop() {
    this.timeoutId && (this.isRunning = !1, clearTimeout(this.timeoutId), this.timeoutId = void 0, this.eventBus.emit("polling stopped"), this.logDebugMessage("Polling stopped"));
  }
  isPolling() {
    return this.isRunning;
  }
  logDebugMessage(e, ...n) {
    this.configurations.debug && this.logDebug(`Poller: ${e}`, ...n);
  }
  logErrorMessage(e, ...n) {
    this.logError(`Poller: ${e}`, ...n);
  }
};
function le(n) {
  return E(this, arguments, function* (a, e = {}) {
    let o = yield Oe(a),
      s = Pe(e);
    return {
      loadFromCache: () => j(o, s, e),
      saveToCache: l => W(o, s, l),
      updateCachedEvaluation: l => we(o, s, l),
      removeCachedEvaluation: l => De(o, s, l)
    };
  });
}
function j(o, s) {
  return E(this, arguments, function* (a, e, n = {}) {
    let l = parseInt(yield e.getItem(a + ".ts"));
    if (n != null && n.ttl && !isNaN(l) && l + n.ttl < Date.now()) return yield Te(a, e), [];
    let m = yield e.getItem(a);
    if (m) try {
      return JSON.parse(m);
    } catch (p) {}
    return [];
  });
}
function Te(a, e) {
  return E(this, null, function* () {
    yield e.removeItem(a), yield e.removeItem(a + ".ts");
  });
}
function W(a, e, n) {
  return E(this, null, function* () {
    yield e.setItem(a, JSON.stringify(n)), yield e.setItem(a + ".ts", Date.now().toString());
  });
}
function we(a, e, n) {
  return E(this, null, function* () {
    let o = yield j(a, e),
      s = o.find(({
        flag: l
      }) => l === n.flag);
    s ? Object.assign(s, n) : o.push(n), yield W(a, e, o);
  });
}
function De(a, e, n) {
  return E(this, null, function* () {
    let o = yield j(a, e),
      s = o.findIndex(({
        flag: l
      }) => l === n);
    s > -1 && (o.splice(s, 1), yield W(a, e, o));
  });
}
function Oe(a) {
  return E(this, null, function* () {
    var n, o;
    let e = a;
    if (globalThis != null && globalThis.TextEncoder && (o = (n = globalThis == null ? void 0 : globalThis.crypto) == null ? void 0 : n.subtle) != null && o.digest) {
      let l = new TextEncoder().encode(a),
        m = yield crypto.subtle.digest("SHA-256", l);
      e = Array.from(new Uint8Array(m)).map(R => R.toString(16).padStart(2, "0")).join("");
    } else globalThis.btoa && (e = btoa(a));
    return "HARNESS_FF_CACHE_" + e;
  });
}
function Pe(a) {
  let e;
  return !a.storage || typeof a.storage != "object" || !("getItem" in a.storage) || !("setItem" in a.storage) || !("removeItem" in a.storage) ? globalThis.localStorage ? e = globalThis.localStorage : globalThis.sessionStorage ? e = globalThis.sessionStorage : e = Fe : e = a.storage, {
    getItem(o) {
      return E(this, null, function* () {
        let s = e.getItem(o);
        return s instanceof Promise ? yield s : s;
      });
    },
    setItem(o, s) {
      return E(this, null, function* () {
        let l = e.setItem(o, s);
        l instanceof Promise && (yield l);
      });
    },
    removeItem(o) {
      return E(this, null, function* () {
        let s = e.removeItem(o);
        s instanceof Promise && (yield s);
      });
    }
  };
}
var Fe = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {}
};
var ue = "1.26.1",
  ce = `Javascript ${ue} Client`,
  Ne = 500,
  Ve = globalThis.fetch,
  J = !!globalThis.Proxy,
  mt = (a, e, n) => {
    let o = !1,
      s,
      l,
      m,
      p,
      R,
      u,
      S = !0,
      A = {},
      x = K(t => t),
      Y = 0,
      X = !1,
      _ = () => {
        S = !1;
      },
      k = () => {
        S = !0;
      },
      d = [],
      f = Me(),
      v = ae(n),
      b = (t, ...i) => {
        v.debug && v.logger.debug(`[FF-SDK] ${t}`, ...i);
      },
      T = (t, ...i) => {
        v.logger.error(`[FF-SDK] ${t}`, ...i);
      },
      de = (t, ...i) => {
        v.logger.warn(`[FF-SDK] ${t}`, ...i);
      },
      L = t => {
        let {
          value: i
        } = t;
        try {
          switch (t.kind.toLowerCase()) {
            case "int":
            case "number":
              i = Number(i);
              break;
            case "boolean":
              i = i.toString().toLowerCase() === "true";
              break;
            case "json":
              i = JSON.parse(i);
              break;
          }
        } catch (c) {
          T(c);
        }
        return i;
      },
      H = t => {
        if (S) {
          let i = Date.now();
          i - t.lastAccessed > Ne && (t.count++, t.lastAccessed = i);
        }
      },
      ge = () => E(void 0, null, function* () {
        if (v.cache) {
          b("initializing cache");
          try {
            let t = !0,
              i = yield le(e.identifier + a, typeof v.cache == "boolean" ? {} : v.cache),
              c = yield i.loadFromCache();
            c != null && c.length && U(() => {
              b("loading from cache", c), ee(c, !1), f.emit("cache loaded", c);
            }), q("flags loaded", h => E(void 0, null, function* () {
              yield i.saveToCache(h), t = !1;
            })), q("changed", h => E(void 0, null, function* () {
              t || (h.deleted ? yield i.removeCachedEvaluation(h.flag) : yield i.updateCachedEvaluation(h));
            }));
          } catch (t) {
            T("Cache error: ", t), f.emit("cache error", t), f.emit("error", t);
          }
        }
      }),
      fe = (t, i) => E(void 0, null, function* () {
        let c = `${i.baseUrl}/client/auth`,
          h = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Harness-SDK-Info": ce
            },
            body: JSON.stringify({
              apiKey: t,
              target: B(C({}, e), {
                identifier: String(e.identifier)
              })
            })
          },
          y,
          r;
        window.AbortController && v.authRequestReadTimeout > 0 ? (r = new AbortController(), h.signal = r.signal, y = window.setTimeout(() => r.abort(), i.authRequestReadTimeout)) : i.authRequestReadTimeout > 0 && de("AbortController is not available, auth request will not timeout");
        try {
          let g = yield Ve(c, h);
          if (!g.ok) throw new Error(`${g.status}: ${g.statusText}`);
          return (yield g.json()).authToken;
        } catch (g) {
          if (r && r.signal.aborted) throw new Error(`Request to ${c} failed: Request timeout via configured authRequestTimeout of ${v.authRequestReadTimeout}`);
          let D = g instanceof Error ? g.message : String(g);
          throw new Error(`Request to ${c} failed: ${D}`);
        } finally {
          y && clearTimeout(y);
        }
      }),
      $ = 0,
      G = () => {
        if (d.length) {
          b("Sending metrics...", {
            metrics: d,
            evaluations: I
          });
          let t = {
            metricsData: d.map(i => ({
              timestamp: Date.now(),
              count: i.count,
              metricsType: "FFMETRICS",
              attributes: [{
                key: "featureIdentifier",
                value: i.featureIdentifier
              }, {
                key: "featureName",
                value: i.featureIdentifier
              }, {
                key: "variationIdentifier",
                value: i.variationIdentifier
              }, {
                key: "target",
                value: e.identifier
              }, {
                key: "SDK_NAME",
                value: "JavaScript"
              }, {
                key: "SDK_LANGUAGE",
                value: "JavaScript"
              }, {
                key: "SDK_TYPE",
                value: "client"
              }, {
                key: "SDK_VERSION",
                value: ue
              }]
            }))
          };
          x(`${v.eventUrl}/metrics/${s}?cluster=${l}`, {
            method: "POST",
            headers: C({
              "Content-Type": "application/json"
            }, A),
            body: JSON.stringify(t)
          }).then(() => {
            d = [], $ = 0;
          }).catch(i => {
            $++ && (d = [], $ = 0), b(i), f.emit("metrics error", i);
          }).finally(() => {
            u = window.setTimeout(G, v.eventsSyncInterval);
          });
        } else u = window.setTimeout(G, v.eventsSyncInterval);
      },
      I = {},
      he = t => {
        b("Sending event for", t.flag), J ? f.emit("changed", new Proxy(t, {
          get(i, c) {
            var h;
            if (S && i.hasOwnProperty(c) && c === "value") {
              let y = i.flag,
                r = t.value,
                g = d.find(D => D.featureIdentifier === y && D.featureValue === r);
              g ? (H(g), g.variationIdentifier = ((h = I[y]) == null ? void 0 : h.identifier) || "") : d.push({
                featureIdentifier: y,
                featureValue: String(r),
                variationIdentifier: I[y].identifier || "",
                count: 1,
                lastAccessed: Date.now()
              }), b("Metrics event: Flag", c, "has been read with value via stream update", r);
            }
            return c === "value" ? L(t) : t[c];
          }
        })) : f.emit("changed", {
          deleted: t.deleted,
          flag: t.flag,
          value: L(t)
        });
      },
      z = function () {
        return J ? new Proxy({}, {
          get(t, i) {
            var h, y, r;
            let c = t[i];
            if (S && t.hasOwnProperty(i)) {
              let g = t[i],
                D = d.find(te => te.featureIdentifier === i && g === te.featureValue);
              D ? (D.variationIdentifier = ((h = I[i]) == null ? void 0 : h.identifier) || "", H(D)) : d.push({
                featureIdentifier: i,
                featureValue: g,
                variationIdentifier: ((y = I[i]) == null ? void 0 : y.identifier) || "",
                count: 1,
                lastAccessed: Date.now()
              }), b("Metrics event: Flag:", i, "has been read with value:", g, "variationIdentifier:", (r = I[i]) == null ? void 0 : r.identifier);
            }
            return c;
          }
        }) : {};
      },
      w = z();
    ge().then(() => fe(a, v).then(t => E(void 0, null, function* () {
      if (o) return;
      R = t;
      let i = xe(t);
      A = {
        Authorization: `Bearer ${R}`,
        "Harness-AccountID": i.accountID,
        "Harness-EnvironmentID": i.environmentIdentifier,
        "Harness-SDK-Info": ce
      };
      let c = re(e);
      c.length < 262144 && (A["Harness-Target"] = c), b("Authenticated", i), u = window.setTimeout(G, v.eventsSyncInterval), s = i.environment, l = i.clusterIdentifier;
      let h = !!Object.keys(I).length;
      if ((yield M()).type === "success" && b("Fetch all flags ok", w), !o) {
        if (v.streamEnabled ? (b("Streaming mode enabled"), me()) : v.pollingEnabled ? (b("Polling mode enabled"), ve()) : b("Streaming and polling mode disabled"), !h) {
          _();
          let r = C({}, w);
          k(), f.emit("ready", r);
        }
        X = !0;
      }
    })).catch(t => {
      T("Authentication error: ", t), f.emit("auth error", t), f.emit("error", t);
    }));
    let M = () => E(void 0, null, function* () {
        try {
          let t = yield x(`${v.baseUrl}/client/env/${s}/target/${e.identifier}/evaluations?cluster=${l}`, {
            headers: A
          });
          if (t.ok) {
            let i = yield t.json();
            return i.forEach(O), f.emit("flags loaded", i), {
              type: "success",
              data: i
            };
          } else return T("Features fetch operation error: ", t), f.emit("fetch flags error", t), f.emit("error", t), {
            type: "error",
            error: t
          };
        } catch (t) {
          return T("Features fetch operation error: ", t), f.emit("fetch flags error", t), f.emit("error", t), {
            type: "error",
            error: t
          };
        }
      }),
      Q = t => E(void 0, null, function* () {
        try {
          let i = yield x(`${v.baseUrl}/client/env/${s}/target/${e.identifier}/evaluations/${t}?cluster=${l}`, {
            headers: A
          });
          if (i.ok) {
            let c = yield i.json();
            O(c);
          } else T("Feature fetch operation error: ", i), f.emit("fetch flag error", i), f.emit("error", i);
        } catch (i) {
          T("Feature fetch operation error: ", i), f.emit("fetch flag error", i), f.emit("error", i);
        }
      }),
      O = t => {
        _();
        let i = L(t);
        i !== w[t.flag] && (b("Flag variation has changed for ", t.identifier), w[t.flag] = i, I[t.flag] = B(C({}, t), {
          value: i
        }), he(t)), k();
      };
    p = new F(M, v, f, b, T);
    let me = () => {
        let t = r => {
            switch (r.event) {
              case "create":
                c(r.evaluations) ? r.evaluations.forEach(g => {
                  O(g);
                }) : setTimeout(() => Q(r.identifier), 1e3);
                break;
              case "patch":
                c(r.evaluations) ? r.evaluations.forEach(g => {
                  O(g);
                }) : Q(r.identifier);
                break;
              case "delete":
                delete w[r.identifier], f.emit("changed", {
                  flag: r.identifier,
                  value: void 0,
                  deleted: !0
                }), b("Evaluation deleted", {
                  message: r,
                  storage: w
                });
                break;
            }
          },
          i = r => !(!r || !r.flag || !r.identifier || !r.kind || !r.value),
          c = r => !(!r || r.length == 0 || !r.every(g => i(g))),
          h = r => {
            r.event === "patch" && (c(r.evaluations) ? r.evaluations.forEach(g => {
              O(g);
            }) : M());
          },
          y = `${v.baseUrl}/stream?cluster=${l}`;
        m = new V(f, v, y, a, A, p, b, T, r => {
          r.domain === "flag" ? t(r) : r.domain === "target-segment" && h(r);
        }, v.maxStreamRetries), m.start();
      },
      ve = () => {
        p.start();
      },
      q = (t, i) => f.on(t, i),
      pe = (t, i) => {
        t ? f.off(t, i) : Z();
      },
      Ee = (t, i) => {
        var r;
        if (!S || J || i === void 0) return;
        let c = i,
          h = t,
          y = d.find(g => g.featureIdentifier === h && g.featureValue === c);
        y ? (H(y), y.variationIdentifier = ((r = I[h]) == null ? void 0 : r.identifier) || "") : d.push({
          featureIdentifier: h,
          featureValue: c,
          count: 1,
          variationIdentifier: I[h].identifier || "",
          lastAccessed: Date.now()
        });
      },
      Z = () => {
        o = !0, v.streamEnabled && (b("Closing event stream"), typeof (m == null ? void 0 : m.close) == "function" && m.close(), f.all.clear()), v.pollingEnabled && p.isPolling() && (b("Closing Poller"), p.stop()), w = z(), I = {}, clearTimeout(u);
      },
      ee = (t, i = !0) => {
        t.length && U(() => {
          let c = !!Object.keys(I).length;
          if (t.forEach(O), !c) {
            _();
            let h = C({}, w);
            k(), f.emit("ready", h);
          }
        }, i);
      };
    return {
      on: q,
      off: pe,
      close: Z,
      setEvaluations: ee,
      registerAPIRequestMiddleware: t => {
        x = K(t);
      },
      refreshEvaluations: () => {
        X && !o && Date.now() - Y >= 6e4 && (M(), Y = Date.now());
      },
      variation: (t, i, c = !1) => se(t, i, w, Ee, f, c)
    };
  };
export { P as Event, mt as initialize };