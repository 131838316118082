import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';

/**
 * Controls the state of menu items
 */
class MenuControl {
  constructor() {
    /**
     * Holds disabled state of items
     */
    this.disabledModel = new SelectionModel(true);
    /**
     * Holds hidden state of items.
     */
    this.hiddenModel = new SelectionModel(true);
    this.children = new Map();
  }
  /**
   * Disabled target menu item
   */
  setIsDisabled(item, val) {
    val ? this.disabledModel.select(item) : this.disabledModel.deselect(item);
  }
  /**
   * Hides target menu item
   */
  setIsHidden(item, val) {
    val ? this.hiddenModel.select(item) : this.hiddenModel.deselect(item);
  }
  /**
   * Returns true if target node is disabled.
   */
  isDisabled(item) {
    return _.isBoolean(item.disabled) ? item.disabled : this.disabledModel.isSelected(item);
  }
  /**
   * Returns true if target node is hidden.
   */
  isHidden(item) {
    return _.isBoolean(item.hidden) ? item.hidden : this.hiddenModel.isSelected(item);
  }
  /**
   * Sets children of a specific item.
   */
  setChildren(item, val) {
    this.children.set(item, val);
  }
  /**
   * Gets children of a specific item.
   */
  getChildren(item) {
    return this.children.get(item);
  }
  /**
   * Clears children map.
   */
  _cleanUp() {
    this.children.clear();
  }
}
export { MenuControl };
