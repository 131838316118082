import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

//eslint-disable-next-line @typescript-eslint/naming-convention, space-before-function-paren
function Config(params) {
  return decoratedClass => {
    return class extends decoratedClass {
      constructor(...args) {
        super(...args);
        this.setDefaultsAndCheckyTypes(params, args[0], this);
      }
      setDefaultsAndCheckyTypes(props, options, instance) {
        for (const propName in props) {
          if (props.hasOwnProperty(propName)) {
            this.setDefaultAndCheckType(propName, props, options, instance);
          }
        }
      }
      setDefaultAndCheckType(propName, props, options, instance) {
        if (options && options.hasOwnProperty(propName)) {
          this.writeOption(propName, props, options, instance);
        } else {
          this.writeDefault(propName, props, instance);
        }
      }
      writeOption(propName, props, options, instance) {
        const prop = props[propName];
        if (this.checkType(options[propName], prop.type)) {
          instance[propName] = options[propName];
        } else {
          // type is invalid
          _.isFunction(instance.onInvalidType) && instance.onInvalidType(propName, prop.typeDisplayName || prop.type, options[propName]);
          this.writeDefault(propName, props, instance);
        }
      }
      writeDefault(propName, props, instance) {
        const prop = props[propName];
        let val;
        if (typeof prop.default === 'function') {
          val = prop.default();
        } else {
          val = _.cloneDeep(prop.default);
        }
        instance[propName] = val;
      }
      checkType(val, type) {
        switch (type) {
          case 'string':
            return _.isString(val);
          case 'nonEmptyString':
            return _.isString(val) && _.trim(val).length !== 0;
          case 'number':
            return _.isNumber(val);
          case 'boolean':
            return _.isBoolean(val);
          case 'array':
            return _.isArray(val);
          case 'object':
            return TypeUtils.isObject(val);
          case 'function':
            return _.isFunction(val);
          default:
            if (_.isFunction(type)) {
              return type(val);
            }
            throw Error(`Invalid config type for value ${val}`);
        }
      }
    };
  };
}
export { Config };
