import * as _ from 'lodash';
import { InjectionToken } from '@angular/core';
import { BigNumber } from 'bignumber.js';
import { StaticTextDefaultInputType } from '../../../cdk-input-types/esm2022/lib/static-text-default-input-type/static-text-default-input-type.mjs';
import { StringDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-string.mjs';
import { EMPTY_STRING, StringAsNumberUtils } from '../../../cdk-utils/esm2022/lib/string-as-number-utils/string-as-number-utils.mjs';
import { StringInputType } from '../../../cdk-input-types/esm2022/lib/string-input-type.mjs';
import { BinaryDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-binary.mjs';
import { IntegerDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-integer.mjs';
import { UnsignedIntegerDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-unsigned-integer.mjs';
import { HexadecimalDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-hexadecimal.mjs';
import { OctalDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-octal.mjs';
import { FloatDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-float.mjs';
import { UppercaseDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-uppercase.mjs';
import { LowercaseDisplayType } from '../../../cdk-display-types/esm2022/lib/display-type-lowercase.mjs';
import { NumericInputType } from '../../../cdk-input-types/esm2022/lib/numeric-input-type.mjs';
import { BigNumberInputType } from '../../../cdk-input-types/esm2022/lib/bignumber-input-type.mjs';
import { ValueTypeBigNumberInputType } from '../../../cdk-input-types/esm2022/lib/value-type-bignumber-input-type/value-type-big-number-input-type.mjs';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
import { LoggerUtils } from '../../../cdk-logger/esm2022/lib/loggerUtils.mjs';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isITransformType(val) {
  if (_.isNil(val)) {
    return false;
  }
  const inputTypeClass = val.hasOwnProperty('inputTypeClass');
  const displayTypeClass = val.hasOwnProperty('displayTypeClass');
  const transformFunctions = val.hasOwnProperty('transformFunctions');
  return inputTypeClass && displayTypeClass && transformFunctions;
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function areITransformType(val) {
  return !_.isNil(val) && TypeUtils.isArrayOf(val, item => {
    return isITransformType(item);
  });
}
class TransformationService {
  static logErrorOnNullTransformFunctions(displayType, inputType, logger) {
    LoggerUtils.logIncompatibleDisplayInputTypeError(displayType.id, inputType.id, logger);
  }
  constructor(transformPair) {
    /**
     * Shortcut for string transformations
     */
    this.defaultStringTransformation = {
      toInputTypeValue: (s,
      //eslint-disable-next-line unused-imports/no-unused-vars
      dt,
      //eslint-disable-next-line unused-imports/no-unused-vars
      it) => {
        return s;
      },
      toDisplayTypeValue: (s,
      //eslint-disable-next-line unused-imports/no-unused-vars
      dt,
      //eslint-disable-next-line unused-imports/no-unused-vars
      it) => {
        return _.isString(s) ? s : '';
      }
    };
    /**
     * Map that holds InputType and DisplayType Classes as key-matrix to get transformation functions.
     */
    //tslint:disable-next-line no-inferred-empty-object-type
    this.transformationMap = new Map();
    this.initDefaultTextTransformations();
    this.initStringTransformations();
    this.initNumericTransformations();
    this.initBigNumberTransformations();
    this.initValueTypeBigNumberTransformations();
    if (_.isNil(transformPair)) {
      return;
    } else if (_.isArray(transformPair)) {
      transformPair.forEach(pair => {
        this.setTransformFunctions(pair.inputTypeClass, pair.displayTypeClass, pair.transformFunctions);
      });
    } else {
      this.setTransformFunctions(transformPair.inputTypeClass, transformPair.displayTypeClass, transformPair.transformFunctions);
    }
  }
  /**
   * Method that is called from Input Component with two params that are instances of InputType and DisplayType class.
   * It returns transformation functions or null in case they are not defined.
   */
  getTransformFunctions(it, dt) {
    const inputTypeTransMap = this.transformationMap.get(it.constructor);
    if (!_.isNil(inputTypeTransMap)) {
      const transformFunctions = inputTypeTransMap.get(dt.constructor);
      if (!_.isNil(transformFunctions)) {
        return transformFunctions;
      }
    }
    //eslint-disable-next-line no-null/no-null
    return null;
  }
  /**
   * Method that updates transformationMap with new transformation functions.
   * It requires InputType and DisplayType references to classes and the transform functions.
   */
  setTransformFunctions(it, dt, tf) {
    let inputTypeTransMap = this.transformationMap.get(it);
    if (_.isNil(inputTypeTransMap)) {
      //tslint:disable-next-line no-inferred-empty-object-type
      this.transformationMap.set(it, new Map());
      inputTypeTransMap = this.transformationMap.get(it);
    }
    if (!_.isNil(inputTypeTransMap)) {
      inputTypeTransMap.set(dt, {
        toInputTypeValue: tf.toInputTypeValue,
        toDisplayTypeValue: this.getEnhancedToDisplayTypeValue(tf.toDisplayTypeValue)
      });
    }
  }
  /**
   * Method that returns enhanced toDisplayTypeValue function.
   * toDisplayTypeValue is function dedicated to use when value comes from API
   * so if there exists function onBlur in the DisplayType, it should be called everytime after that function
   */
  getEnhancedToDisplayTypeValue(toDisplayTypeValue) {
    return (val, displayType, inputType) => {
      if (!_.isNil(inputType) && !inputType.typeCheckFn(val)) {
        //eslint-disable-next-line max-len
        throw new Error(`inputType "${inputType.id}" typeCheckFn does not allow to use type you are passing to function "toDisplayTypeValue"`);
      }
      const valAsString = toDisplayTypeValue(val, displayType, inputType);
      // if(isOnBlurAccessible)
      if (!_.isNil(displayType) && !_.isNil(inputType) && !inputType.disableDisplayTypeBlur && displayType.onBlur) {
        return displayType.onBlur(valAsString);
      }
      return valAsString;
    };
  }
  initDefaultTextTransformations() {
    this.setTransformFunctions(StaticTextDefaultInputType, StringDisplayType, {
      toInputTypeValue: (str,
      //eslint-disable-next-line unused-imports/no-unused-vars
      dt,
      //eslint-disable-next-line unused-imports/no-unused-vars
      it) => {
        return str;
      },
      toDisplayTypeValue: (val,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        if (_.isString(val)) {
          return val;
        }
        if (_.isNumber(val) || _.isBoolean(val)) {
          return val.toString();
        }
        return EMPTY_STRING;
      }
    });
  }
  initStringTransformations() {
    this.setTransformFunctions(StringInputType, StringDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, BinaryDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, IntegerDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, UnsignedIntegerDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, HexadecimalDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, OctalDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, FloatDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, UppercaseDisplayType, this.defaultStringTransformation);
    this.setTransformFunctions(StringInputType, LowercaseDisplayType, this.defaultStringTransformation);
  }
  initNumericTransformations() {
    this.setTransformFunctions(NumericInputType, BinaryDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (!StringAsNumberUtils.isBase(s, 2)) {
          return NaN;
        }
        return parseInt(s, 2);
      },
      toDisplayTypeValue: (n,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(n) ? EMPTY_STRING : n.toString(2);
      }
    });
    this.setTransformFunctions(NumericInputType, IntegerDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (!StringAsNumberUtils.isBase(s, 10)) {
          return NaN;
        }
        return parseInt(s, 10);
      },
      toDisplayTypeValue: (n,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(n) ? EMPTY_STRING : new BigNumber(n).toFixed();
      }
    });
    this.setTransformFunctions(NumericInputType, UnsignedIntegerDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (!StringAsNumberUtils.isBase(s, 10)) {
          return NaN;
        }
        return parseInt(s, 10);
      },
      toDisplayTypeValue: (n,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(n) ? EMPTY_STRING : new BigNumber(n).toFixed();
      }
    });
    this.setTransformFunctions(NumericInputType, HexadecimalDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (!StringAsNumberUtils.isBase(s, 16)) {
          return NaN;
        }
        return parseInt(s, 16);
      },
      toDisplayTypeValue: (n,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(n) ? EMPTY_STRING : n.toString(16);
      }
    });
    this.setTransformFunctions(NumericInputType, OctalDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (!StringAsNumberUtils.isBase(s, 8)) {
          return NaN;
        }
        return parseInt(s, 8);
      },
      toDisplayTypeValue: (n,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(n) ? EMPTY_STRING : n.toString(8);
      }
    });
    this.setTransformFunctions(NumericInputType, FloatDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        s = s.replace(displayType.decimalPointChar, '.');
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        if (_.isNaN(Number(s))) {
          return NaN;
        }
        return parseFloat(s);
      },
      toDisplayTypeValue: (n, displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        if (!_.isNil(n) && !_.isFinite(n)) {
          return n.toString();
        }
        if (!_.isNil(n) && !_.isNil(displayType.opts.maxDigitsAfterPoint)) {
          n = _.round(n, displayType.opts.maxDigitsAfterPoint);
        }
        return _.isNil(n) ? EMPTY_STRING : n.toString().replace('.', displayType.decimalPointChar);
      }
    });
  }
  initBigNumberTransformations() {
    this.setTransformFunctions(BigNumberInputType, IntegerDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        return new BigNumber(s);
      },
      toDisplayTypeValue: (val,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(val) ? EMPTY_STRING : val.toFixed();
      }
    });
    this.setTransformFunctions(BigNumberInputType, UnsignedIntegerDisplayType, {
      toInputTypeValue: (s, displayType, inputType) => {
        if (s === EMPTY_STRING) {
          return inputType.emptyValue;
        }
        return new BigNumber(s);
      },
      toDisplayTypeValue: (val,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return _.isNil(val) ? EMPTY_STRING : val.toFixed();
      }
    });
  }
  initValueTypeBigNumberTransformations() {
    this.setTransformFunctions(ValueTypeBigNumberInputType, StringDisplayType, {
      toInputTypeValue: (str,
      //eslint-disable-next-line unused-imports/no-unused-vars
      displayType,
      //eslint-disable-next-line unused-imports/no-unused-vars
      inputType) => {
        return str;
      },
      toDisplayTypeValue: (val, displayType,
      //eslint-disable-line unused-imports/no-unused-vars
      inputType) => {
        if (_.isString(val)) {
          return val;
        }
        if (_.isNumber(val) || _.isBoolean(val) || BigNumber.isBigNumber(val)) {
          return val.toString();
        }
        return EMPTY_STRING;
      }
    });
  }
}
const INPUT_TRANSFORMATION = new InjectionToken('Default transformations for input component', {
  providedIn: 'root',
  factory: transformObjArr => {
    return new TransformationService(transformObjArr);
  }
});
export { INPUT_TRANSFORMATION, TransformationService, areITransformType, isITransformType };
