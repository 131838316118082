import * as _ from 'lodash';
import { LoggerUtils } from '../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
const DEFAULT_LABEL_VALUE = '';
const DEFAULT_CONFIG_VALUE = {};
const DEFAULT_TRANSLATE_PARAMS_VALUE = {};
const DEFAULT_DISABLED_VALUE = false;
class RaUiInputValidationService {
  constructor(logger) {
    this.logger = logger;
    this._label = DEFAULT_LABEL_VALUE;
    this._config = DEFAULT_CONFIG_VALUE;
    this._translateParams = DEFAULT_TRANSLATE_PARAMS_VALUE;
    this._disabled = DEFAULT_DISABLED_VALUE;
  }
  /**
   * Initializes the necessary fields
   * @param config - set of information needed for proper config validation
   */
  init(config) {
    this.setConfig(config);
  }
  /**
   * Performs label validation and sets corresponding property.
   * @param label - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   * @param inputName - optional input name to be logged in eventual warn message, 'label' by default
   */
  setLabel(label, compName, inputName = 'label') {
    if (_.isString(label)) {
      this._label = label;
    } else {
      this._label = DEFAULT_LABEL_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, inputName, label, 'string', this.logger);
    }
  }
  /**
   * Performs disabled validation and sets corresponding property.
   * @param disabledVal - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setDisabled(disabledVal, compName) {
    if (_.isBoolean(disabledVal)) {
      this._disabled = disabledVal;
    } else {
      this._disabled = DEFAULT_DISABLED_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'disabled', disabledVal, 'boolean', this.logger);
    }
  }
  /**
   * Performs translateParams validation and sets the corresponding property.
   * @param translateParams - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setTranslateParams(translateParams, compName) {
    if (TypeUtils.isObject(translateParams)) {
      this._translateParams = translateParams;
    } else {
      this._translateParams = DEFAULT_TRANSLATE_PARAMS_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'translateParams', translateParams, 'object', this.logger);
    }
  }
  /**
   * Performs config validation and sets the corresponding property.
   * @param config - set of information needed for proper config validation
   */
  setConfig(config) {
    if (TypeUtils.isObject(config.config)) {
      this._config = new config.configConstructor(config.config, this.logger);
    } else {
      LoggerUtils.logInvalidInput(config.componentName, 'config', config.config, config.correctValType, this.logger);
      this._config = new config.configConstructor({}, this.logger);
    }
  }
}
export { RaUiInputValidationService };
