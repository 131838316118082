/**
 * Default image extensions.
 * ['gif', 'jpg', 'jpeg', 'png', 'svg']
 */
const DefaultImageExtensions = ['gif', 'jpg', 'jpeg', 'png', 'svg'];
/**
 * Custom class for non focusable elements elements in dynamic form.
 */
const RA_UI_DF_NON_FOCUSABLE_CLASS = 'ra-ui-df-non-focusable';
/**
 * Focusable elements selector used for alternative cycle focusing in dynamic form
 */
const RA_UI_FOCUSABLE_ELEMENTS_SELECTOR = `button:not(.${RA_UI_DF_NON_FOCUSABLE_CLASS}), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`;
export { DefaultImageExtensions, RA_UI_DF_NON_FOCUSABLE_CLASS, RA_UI_FOCUSABLE_ELEMENTS_SELECTOR };
