import { isObservable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoggerUtils } from '../../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
class ObservableUtils {
  /**
   * Handle value or observable type of input
   * @params
   * @param params.value - new value
   * @param params.typeCheckFunction - function to check correct type of value
   * @param params.defaultValue - default value
   * @param params.handleFunction - function to set value in component
   * @param params.subscribeFunction - function to set value in component in next function
   * @param params.componentName - name of component
   * @param params.inputName - name of input
   * @param params.correctValueType - correct type of value
   * @param params.logger - logger
   * @param params.destroySub - destroy subject to remove subscription on component destroy
   */
  static handleValueOrObservable(params) {
    const {
      typeCheckFunction,
      componentName,
      inputName,
      correctValueType,
      logger,
      defaultValue,
      destroySub,
      subscribeFunction,
      handleFunction
    } = params;
    let value = params.value;
    let subscription;
    if (!typeCheckFunction(value) && !isObservable(value)) {
      LoggerUtils.logInvalidInput(componentName, inputName, value, correctValueType, logger);
      value = defaultValue;
    }
    if (isObservable(value)) {
      subscription = value.pipe(takeUntil(destroySub)).subscribe({
        next: val => {
          if (!typeCheckFunction(val)) {
            LoggerUtils.logInvalidInput(componentName, inputName, val, correctValueType, logger);
            val = defaultValue;
          }
          subscribeFunction ? subscribeFunction(val) : handleFunction(val);
        },
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: e => {
          logger.error(e);
        }
      });
    } else {
      handleFunction(value);
    }
    return {
      value,
      subscription
    };
  }
  /**
   * Unsubscribes from given subscription if subscription is not closed.
   * @param subscription object to unsubscribe
   */
  static unsubscribeSubscription(subscription) {
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }
}
export { ObservableUtils };
