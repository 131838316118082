import { style, transition, animate, trigger, query, animateChild } from '@angular/animations';
const VISIBLE_STYLE = style({
  opacity: 1,
  height: '*',
  paddingTop: '*',
  paddingBottom: '*'
});
const HIDDEN_STYLE = style({
  opacity: 0,
  height: '0px',
  paddingTop: '0px',
  paddingBottom: '0px'
});
const ENTER_LEAVE_TRANSITIONS = [transition(':enter', [HIDDEN_STYLE, animate('300ms ease-in-out', VISIBLE_STYLE)]), transition(':leave', [VISIBLE_STYLE, animate('300ms ease-in-out', HIDDEN_STYLE)])];
/**
 * Animations used by the Banner.
 */
const bannerAnimations = [trigger('ngIfHost', [
// this trigger via @HostBinding is needed,
// because there is no other way to check whether the host is removed by ngIf
// and run the related leave animations on children elements
transition(':enter, :leave', query('@*', animateChild()))]), trigger('enterLeaveTransition', ENTER_LEAVE_TRANSITIONS)];
export { bannerAnimations };
