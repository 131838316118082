import * as _ from 'lodash';
class RangesUtils {
  /**
   * Sorts ranges by ASC and creates a new range when some ranges are overlapping.
   * @param ranges array of ranges
   * @param maxBoundary starting position have to lower as the argument: `maxBoundary`,
   *                    otherwise this range is not included to the result
   * @return {ListRange[]} new array ranges with disjunct ranges
   */
  static getDisjunctRanges(ranges, maxBoundary = Number.MAX_VALUE) {
    if (_.isNil(ranges)) {
      return [];
    }
    return RangesUtils.getMergedRanges(RangesUtils.getValidRanges(ranges, maxBoundary));
  }
  /**
   * The method sanitizes all ranges, which are not valid and returns new ranges with valid values.
   * Set defaults: undefined   => 0;
   *               start > end => start.
   * Removes all ranges: a range has no length. <5, 5)
   *                     a range is out of maxBoundary
   * The method checks whether type in ranges are finite numbers.
   * Types, which are not  defined as finite numbers: Positive/Negative Infinity, NaN, undefined.
   * @param ranges array of ranges
   * @param maxBoundary max upper limit. If the value of range has greater as this limit, then is not valid
   * @return {ListRange[]} new array ranges with valid ranges
   */
  static getValidRanges(ranges, maxBoundary) {
    ranges = ranges.reduce((result, range) => {
      if (!_.isFinite(range.start) || !_.isFinite(range.end)) {
        return result;
      }
      const start = range.start ?? 0;
      const end = start > range.end ? start : range.end;
      if (start < maxBoundary && start !== end) {
        result.push({
          ...range,
          ...{
            start,
            end
          }
        });
      }
      return result;
    }, []);
    return ranges;
  }
  /**
   * The method merge ranges if some range is subset another range.
   * @param ranges array of ranges
   * @return {ListRange[]} new array ranges with merged ranges
   */
  static getMergedRanges(ranges) {
    // Sorts ranges by start by ASC
    ranges = _.sortBy(ranges, range => {
      return range.start;
    });
    const nextStep = ranges.length > 1;
    let index = 0;
    // Infinite loop is interrupted in body of loop, when
    // a current range or a next range don't exist.
    while (nextStep) {
      const current = ranges[index];
      const next = ranges[index + 1];
      if (_.isNil(current) || _.isNil(next)) {
        break;
      }
      // next interval is subset of current interval
      if (next.start <= current.end) {
        // next interval is starting in current interval, but is ending outside its right border.
        // right border of current interval is increasing about right border of next interval.
        if (next.end >= current.end) {
          current.end = next.end;
        }
        // removes next interval, because the interval can be:
        // - subset of current interval
        // - current interval is updating about next interval.
        // In next iteration checks this interval with next interval
        ranges.splice(index + 1, 1);
      } else {
        // take new interval in iteration
        index++;
      }
    }
    return ranges;
  }
  /**
   * The method checks whether index is subset of any range from list of ranges.
   * @param ranges array of ranges
   * @param index number value
   * @return {boolean} true if input index is subset of any range in input ranges otherwise false
   */
  static isIndexSubsetRange(ranges, index) {
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      if (range.start <= index && range.end > index) {
        return true;
      }
    }
    return false;
  }
  /**
   * The method gets a range if index is subset of any range from list of ranges.
   * @param ranges array of ranges
   * @param index number value
   * @return {ListRange} a range if input index is subset of any range in input ranges otherwise undefined
   */
  static getIndexSubsetRange(ranges, index) {
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      if (range.start <= index && range.end > index) {
        return range;
      }
    }
    return;
  }
  /**
   * The method changes positions (start, end) after applied on text before accelerator key.
   * @param ranges array of ranges
   * @param index checks, whether end position of range is greater as index
   * If it is truth than updates ranges by index, otherwise break this range
   * @return {ListRange[]} new array of ranges shifted by input index
   */
  static _createShiftRangesByIndex(ranges, index) {
    const newHighlights = [];
    if (_.isEmpty(ranges)) {
      return newHighlights;
    }
    for (const range of ranges) {
      // range is not subset of text
      if (range.end <= index) {
        continue;
      }
      const startPosition = range.start - index;
      const newRange = _.clone(range);
      // range is partially subset of text
      if (startPosition < 0) {
        newRange.start = 0;
        newRange.end = range.end - (index + 1);
      }
      // range is not subset of text
      else {
        const diff = index + 1;
        newRange.start -= diff;
        newRange.end -= diff;
      }
      newHighlights.push(newRange);
    }
    return RangesUtils.getDisjunctRanges(newHighlights);
  }
}
export { RangesUtils };
