import * as _ from 'lodash';
import { KeyString } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
class MultilineInputUtils {
  static stopPropagation(event, keys) {
    const isKeyIncluded = _.includes(keys, event.key);
    isKeyIncluded && event.stopPropagation();
  }
  static handleCtrlEnterEvent(el, event, disableEnter) {
    // prevent new line creating
    if (disableEnter && !event.ctrlKey && event.key === KeyString.ENTER) {
      event.preventDefault();
    }
    // creating new line
    if (MultilineInputUtils.isCtrlEnterKey(event)) {
      event.preventDefault();
      MultilineInputUtils.createNewLine(el);
    }
  }
  static isCtrlEnterKey(event) {
    return event.ctrlKey && event.key === KeyString.ENTER;
  }
  static createNewLine(el) {
    const element = el.nativeElement;
    const caretStart = element.selectionStart;
    const caretEnd = element.selectionEnd;
    const value = element.value;
    const part1 = value.substring(0, caretStart);
    const part2 = value.substring(caretEnd, value.length);
    element.value = `${part1}\r\n${part2}`;
    element.selectionStart = element.selectionEnd = caretStart + 1;
    element.blur();
    element.focus();
  }
}
export { MultilineInputUtils };
