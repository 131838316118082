import * as _ from 'lodash';
class ArrayUtils {
  /**
   * Returns index of desired item, if item is not found method returns -1
   * @param searchedItem searched item
   * @param array array to search in
   */
  static findItemIndex(searchedItem, array) {
    return _.findIndex(array, item => {
      return _.isEqual(item, searchedItem);
    });
  }
}
export { ArrayUtils };
