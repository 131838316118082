import { MathUtils } from '../math-utils/math-utils.mjs';
import * as _ from 'lodash';
import { isFunction } from 'lodash';
import { fromEvent } from 'rxjs';
import { Direction, KeyCode } from '../../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
class BrowserUtils {
  /**
   * Adds an event listener to a specified object.
   * @param params configuration object containing information for addEventListener
   * Properties of the params object are
   * 1) element - An element which is going to be listened to.
   * An instance of EventTarget, e.g. HTMLElement, window and document objects
   * 2) eventName - An event's type
   * 3) listener - An object that receives a notification or a function which gets triggered when an event happened.
   * If it's object, it must implement the EventListener interface.
   * 4) useCapture - A boolean indicating that events of this type will be dispatched to the registered listener
   * before being dispatched to any EventTarget beneath it in the DOM tree.
   */
  static addEventListener(params) {
    params.element.addEventListener(params.eventName, params.listener, !!params.useCapture);
  }
  /**
   * Determines if current selection is inside given HTMLElement.
   * @param element
   */
  static isSelectionWithinElement(element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return false;
    }
    const {
      startContainer,
      endContainer
    } = selection.shadowDomRange ?? selection.getRangeAt(0);
    // there are some situations in Edge, where the start/endNode is not the text element but the span
    return (startContainer.parentNode === element || startContainer === element) && (endContainer.parentNode === element || endContainer === element);
  }
  /**
   * Determines if current selection is not empty.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static hasSelection(element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return false;
    }
    const root = BrowserUtils.getRootNode(element);
    /**
     * We need to determine selection range manually, because Selection.isCollapsed is always true in shadowDOM in chromium,
     * see https://stackoverflow.com/questions/68882638/why-is-selection-iscollapsed-always-true-in-a-shadow-dom
     * and https://issues.chromium.org/issues/40805666 for more details
     */
    if (BrowserUtils.isShadowRoot(root)) {
      return selection.anchorOffset - selection.focusOffset !== 0;
    }
    return !selection.isCollapsed;
  }
  /**
   * Determines if given root object is ShadowRoot.
   * @param root
   */
  static isShadowRoot(root) {
    return root instanceof ShadowRoot;
  }
  static getActiveElement(element) {
    if (!element) {
      return;
    }
    const root = element.getRootNode();
    const activeEl = root.activeElement;
    if (!activeEl) {
      return;
    }
    // if the active element has a shadow dom root node attached to it, the active element will only be resolvable from that shadow root
    if (activeEl.shadowRoot) {
      return BrowserUtils.getActiveElement(activeEl.shadowRoot);
    } else {
      return activeEl;
    }
  }
  /**
   * Returns length of string representation of existing selection within current root.
   * Root is determined from given HTMLElement and can be Document object or ShadowRoot.
   * Document object is used by default when no HTMLElement is specified.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getSelectionLength(element) {
    const range = BrowserUtils.getRange(element);
    return range ? range.toString().length : 0;
  }
  /**
   * Sets caret to specific position in targetElement
   * @param targetElement - element to set caret in
   * @param index - new position of caret
   */
  static setCaret(targetElement, index) {
    // We need to set caret for input elements (input, text-area...) using setSelectionRange, because textContent is always 0
    if (targetElement.nodeName === 'INPUT' || targetElement.nodeName === 'TEXTAREA') {
      const el = targetElement;
      const newIndex = el.value.length < index ? 0 : index;
      el.setSelectionRange(newIndex, newIndex);
      return;
    }
    const sel = BrowserUtils.getRootNodeSelection(targetElement);
    if (_.isNil(sel)) {
      return;
    }
    const range = document.createRange();
    range.setStart(targetElement, targetElement.textContent.length < index ? 0 : index);
    range.collapse(true);
    BrowserUtils.removeAllRanges(targetElement, sel);
    sel.addRange(range);
    BrowserUtils.setSelectionRangeInShadowDom(range, sel, targetElement);
  }
  /**
   * Sets Range object into selection object within ShadowRoot. Observe, that addRange() method does not work properly in ShadowRoot.
   * addRange() method in ShadowRoot sets start and end node of given range into the same node,
   * which is not how the method addRange() works in Document object.
   * @param range - Range object to set into Selection
   * @param selection - Selection object that accepts Range object
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static setSelectionRangeInShadowDom(range, selection, element) {
    // We need to save the range into its own property, because addRange method does not work properly in shadowRoot and collapses the range
    if (BrowserUtils.isShadowRoot(BrowserUtils.getRootNode(element))) {
      selection.shadowDomRange = range;
    }
  }
  static getClipboardData(event) {
    const castedWindow = window; //eslint-disable-line @typescript-eslint/no-explicit-any
    let value;
    if (castedWindow.clipboardData && castedWindow.clipboardData.getData) {
      // IE
      value = castedWindow.clipboardData.getData('Text');
    } else if (event.clipboardData && event.clipboardData.getData) {
      value = event.clipboardData.getData('text/plain');
    }
    return value;
  }
  static setClipboardData(value, event) {
    const castedWindow = window; //eslint-disable-line @typescript-eslint/no-explicit-any
    if (castedWindow.clipboardData && castedWindow.clipboardData.setData) {
      // IE
      castedWindow.clipboardData.setData('Text', value);
    } else if (event.clipboardData) {
      event.clipboardData.setData('text/plain', value);
    }
  }
  static getDragData(event) {
    const value = event.dataTransfer.getData('Text');
    return value;
  }
  /**
   * Find parent node of current node according to filter function
   */
  static findParentNode(node, filterFunc) {
    let currentNode = node;
    while (currentNode) {
      if (filterFunc(currentNode)) {
        return currentNode;
      }
      if (currentNode.parentNode) {
        currentNode = currentNode.parentNode;
      } else {
        return;
      }
    }
    return;
  }
  /**
   * Removes an event listener from a specified object.
   * @param params configuration object containing information for removeEventListener
   * Properties of the params object are
   * 1) element - An element which is going to be listened to.
   * An instance of EventTarget, e.g. HTMLElement, window and document objects
   * 2) eventName - An event's type
   * 3) listener - an EventListener function of the event handler to remove from the event target.
   * 4) useCapture - A boolean indicating that events of this type will be dispatched to the registered listener
   * before being dispatched to any EventTarget beneath it in the DOM tree.
   */
  static removeEventListener(params) {
    params.element.removeEventListener(params.eventName, params.listener, !!params.useCapture);
  }
  static removeWhitespaces(value) {
    return value.replace(new RegExp('\\s', 'g'), '');
  }
  /**
   * Returns the current caret position, if there is no selection, otherwise returns undefined
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getCaretPosition(element) {
    const sel = BrowserUtils.getRootNodeSelection(element);
    if (!_.isNil(sel) && !BrowserUtils.hasSelection(element)) {
      return sel.anchorOffset;
    }
    return;
  }
  /**
   * Returns the left position of current selection.
   * Returns -1 when BrowserUtils.getRootNodeSelection() returns undefined
   * (only very specific cases described in https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection).
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getSelectionLeftPosition(element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return -1;
    }
    return selection.anchorOffset;
  }
  /**
   * Returns the right position of current selection.
   * Returns -1 when BrowserUtils.getRootNodeSelection() returns undefined
   * (only very specific cases described in https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection).
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getSelectionRightPosition(element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return -1;
    }
    return selection.focusOffset;
  }
  /**
   * Returns Selection object within current root. Root can be Document object or ShadowRoot object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getRootNodeSelection(element) {
    /**
     * We need to determine root node, because window.getSelection does not work correctly when selection is inside shadowDOM in chromium,
     * see https://issues.chromium.org/issues/41112024 for more details
     */
    const root = BrowserUtils.getRootNode(element);
    if (BrowserUtils.isShadowRoot(root) && 'getSelection' in root && isFunction(root.getSelection)) {
      const shadowRootSelection = root.getSelection();
      return shadowRootSelection ?? undefined;
    }
    return window.getSelection() ?? undefined;
  }
  /**
   * Returns root of the given HTMLElement. Root can be Document object or ShadowRoot object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getRootNode(element) {
    return element?.getRootNode() ?? document;
  }
  /**
   * Returns current Range object from Selection object within current root object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static getRange(element) {
    const sel = BrowserUtils.getRootNodeSelection(element);
    return !_.isNil(sel) && sel.rangeCount > 0 ? sel.shadowDomRange ?? sel.getRangeAt(0) : undefined;
  }
  /**
   * Removes Range objects from Selection object within current root Element. Root can be Document object or ShadowRoot object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   * @param selection
   */
  static removeAllRanges(element, selection) {
    const sel = selection ?? BrowserUtils.getRootNodeSelection(element);
    sel?.removeAllRanges();
    delete sel?.shadowDomRange;
  }
  /**
   * Creates new Range object and adds it into selection within current root Element. Root can be Document object or ShadowRoot object.
   * @param startNode
   * @param startOffset
   * @param endNode
   * @param endOffset
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static addNewRangeToSelection(startNode, startOffset, endNode, endOffset, element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return;
    }
    const selectionExists = selection.rangeCount > 0;
    const range = selectionExists ? selection.getRangeAt(0) : document.createRange();
    startOffset = MathUtils.clamp(startOffset, 0, startNode.textContent ? startNode.textContent.length : 0);
    endOffset = MathUtils.clamp(endOffset, 0, endNode.textContent ? endNode.textContent.length : 0);
    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    !selectionExists && selection.addRange(range);
    BrowserUtils.setSelectionRangeInShadowDom(range, selection, element);
  }
  /**
   * Sets new Range object into selection within current root Element. Root can be Document object or ShadowRoot object.
   * @param range - Range object to set
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static setSelectionRange(range, element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return;
    }
    selection.addRange(range);
    BrowserUtils.setSelectionRangeInShadowDom(range, selection, element);
  }
  /**
   * Updates left or right boundary of the selection based on direction
   * @param direction
   * @param node
   * @param offset
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied.
   */
  static updateBoundaryOfSelection(direction, node, offset, element) {
    if (direction === Direction.Left) {
      BrowserUtils.updateLeftBoundaryOfSelection(node, offset, element);
    } else {
      BrowserUtils.updateRightBoundaryOfSelection(node, offset, element);
    }
  }
  /**
   * Updates left boundary of the selection.
   * @param leftNode
   * @param leftOffset
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied
   */
  static updateLeftBoundaryOfSelection(leftNode, leftOffset, element) {
    const selectionFocus = BrowserUtils.getSelectionFocusProperties(leftNode);
    if (_.isNil(selectionFocus) || _.isNil(selectionFocus.focusNode)) {
      return;
    }
    BrowserUtils.addNewRangeToSelection(leftNode, leftOffset, selectionFocus.focusNode, selectionFocus.focusOffset, element);
  }
  /**
   * Updates right boundary of the selection.
   * @param rightNode
   * @param rightOffset
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied
   */
  static updateRightBoundaryOfSelection(rightNode, rightOffset, element) {
    const selectionAnchor = BrowserUtils.getSelectionAnchorProperties(rightNode);
    if (_.isNil(selectionAnchor) || _.isNil(selectionAnchor.anchorNode)) {
      return;
    }
    BrowserUtils.addNewRangeToSelection(selectionAnchor.anchorNode, selectionAnchor.anchorOffset, rightNode, rightOffset, element);
  }
  static isFnKeyPressed(event) {
    return event.keyCode >= 112 && event.keyCode <= 122;
  }
  static isLaterInDOM(node1, node2) {
    return !!(
    // eslint-disable-next-line no-bitwise
    node1.compareDocumentPosition(node2) & Node.DOCUMENT_POSITION_PRECEDING);
  }
  static isEarlierInDOM(node1, node2) {
    return !!(
    // eslint-disable-next-line no-bitwise
    node1.compareDocumentPosition(node2) & Node.DOCUMENT_POSITION_FOLLOWING);
  }
  static isDescendant(node1, node2) {
    return !!(
    // eslint-disable-next-line no-bitwise
    node1.compareDocumentPosition(node2) & Node.DOCUMENT_POSITION_CONTAINS);
  }
  static isAncestor(node1, node2) {
    return !!(node1.compareDocumentPosition(node2) &
    // eslint-disable-line no-bitwise
    Node.DOCUMENT_POSITION_CONTAINED_BY);
  }
  static getOffsetToAncestor(node, ancestor) {
    let offsetX = 0;
    let offsetY = 0;
    while (BrowserUtils.isAncestor(ancestor, node)) {
      offsetX += node.offsetLeft;
      offsetY += node.offsetTop;
      node = node.offsetParent;
    }
    //ancestor is not an offset ancestor, i.e. it does not have set `relative` or `absolute` position.
    //In this case we need to subtract the ancestor's offset to its own offsetParent
    //(position 0 means that the compared nodes are equal)
    if (node.compareDocumentPosition(ancestor) !== 0) {
      offsetX -= ancestor.offsetLeft;
      offsetY -= ancestor.offsetTop;
    }
    return {
      offsetLeft: offsetX,
      offsetTop: offsetY
    };
  }
  /**
   * If there is a selection and user pressed arrow key, this method will set the right position of the caret based on the specific arrow
   * @param keyCode
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied
   */
  static restoreCaretPositionAfterSelectionCleared(keyCode, element) {
    // saving old position
    const selectionAnchor = BrowserUtils.getSelectionAnchorProperties(element);
    const selectionFocus = BrowserUtils.getSelectionFocusProperties(element);
    if (_.isNil(selectionAnchor) || _.isNil(selectionFocus)) {
      return;
    }
    let targetElement;
    let targetOffset;
    switch (keyCode) {
      case KeyCode.LEFT_ARROW:
        [targetElement, targetOffset] = [selectionAnchor.anchorNode, selectionAnchor.anchorOffset];
        break;
      case KeyCode.RIGHT_ARROW:
        [targetElement, targetOffset] = [selectionFocus.focusNode, selectionFocus.focusOffset];
        break;
      case KeyCode.UP_ARROW:
        [targetElement, targetOffset] = [selectionAnchor.anchorNode, 0];
        break;
      case KeyCode.DOWN_ARROW:
        [targetElement, targetOffset] = [selectionFocus.focusNode, selectionFocus.focusNode.textContent.length];
        break;
      default:
        return;
    }
    const spanElement = targetElement.parentNode;
    spanElement.focus();
    BrowserUtils.setCaret(targetElement, targetOffset);
  }
  /**
   * The mouse events shouldn't be bound on mobile devices, because they can prevent the
   * first tap from firing its click event.
   */
  static boundMouseEventsForDesktopDevice(elementRef, device, mouseEnter,
  //eslint-disable-line @typescript-eslint/no-explicit-any
  mouseLeave,
  //eslint-disable-line @typescript-eslint/no-explicit-any
  mouseMove) {
    const listeners = new Map();
    if (device.isDesktop()) {
      const mouseEnterObservable = fromEvent(elementRef.nativeElement, 'mouseenter').subscribe(e => {
        mouseEnter(e);
      });
      const mouseLeaveObservable = fromEvent(elementRef.nativeElement, 'mouseleave').subscribe(e => {
        mouseLeave(e);
      });
      listeners.set('mouseenter', mouseEnterObservable).set('mouseleave', mouseLeaveObservable);
      if (mouseMove) {
        const mouseMoveObservable = fromEvent(elementRef.nativeElement, 'mousemove').subscribe(e => {
          mouseMove(e);
        });
        listeners.set('mousemove', mouseMoveObservable);
      }
    }
    return listeners;
  }
  static unboundMouseEventsForDesktopDevice(elementRef, device, listeners) {
    if (device.isDesktop()) {
      listeners.forEach(subscription => {
        subscription.unsubscribe();
      });
      listeners.clear();
    }
  }
  /**
   * Return focus node information from current Selection object within root object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied
   */
  static getSelectionFocusProperties(element) {
    const range = BrowserUtils.getRange(element);
    if (_.isNil(range)) {
      return;
    }
    const focusNode = range.endContainer;
    const focusOffset = range.endOffset;
    if (_.isNil(focusNode)) {
      return;
    }
    return {
      focusNode,
      focusOffset
    };
  }
  /**
   * Return anchor node information from current Selection object within root object.
   * @param element - HTMLElement to determine current root object,
   * i.e. Document or ShadowRoot. Document is used as default when no element is supplied
   */
  static getSelectionAnchorProperties(element) {
    const selection = BrowserUtils.getRootNodeSelection(element);
    if (_.isNil(selection)) {
      return;
    }
    const anchorNode = selection.anchorNode;
    const anchorOffset = selection.anchorOffset;
    if (_.isNil(anchorNode)) {
      return;
    }
    return {
      anchorNode: anchorNode,
      anchorOffset: anchorOffset
    };
  }
  /**
   * Gets the viewport size. Returns object with width and height properties.
   */
  static getViewportSize() {
    const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return {
      height: height,
      width: width
    };
  }
  /**
   * Tells whether an element fits into its parent vertically
   * @param element - element to be checked
   * @param parentEl - element's parent (if not provided, element.offsetParent is used)
   * @param stickyTop - the height of sticky top element
   */
  static doesElementFitInsideParentVertically(element, parentEl, stickyTop = 0) {
    const parentElement = parentEl ? parentEl : element.offsetParent;
    if (_.isNull(parentElement)) {
      return false;
    }
    const elementRect = element.getBoundingClientRect();
    const parentElementRect = parentElement.getBoundingClientRect();
    return MathUtils.isSubsetInterval(elementRect.y, elementRect.y + elementRect.height, parentElementRect.y + stickyTop, parentElementRect.y + parentElementRect.height);
  }
  /**
   * Tells whether an element fits into its parent horizontally
   * @param element - element to be checked
   * @param parentEl - element's parent (if not provided, element.offsetParent is used)
   */
  static doesElementFitInsideParentHorizontally(element, parentEl) {
    const parentElement = parentEl ? parentEl : element.offsetParent;
    if (_.isNil(parentElement)) {
      return false;
    }
    return MathUtils.isSubsetInterval(element.offsetLeft, element.offsetLeft + element.clientWidth, parentElement.scrollLeft, parentElement.scrollLeft + parentElement.clientWidth);
  }
}
export { BrowserUtils };
