import * as _ from 'lodash';
import { ThemeColor } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
import { RaUiInputValidationService } from '../../../cdk-input-validation/esm2022/lib/ra-ui-input-validation.service.mjs';
import { BadgeUtils } from '../../../indicators-cdk/esm2022/lib/utils/badge-utils/badge-utils.mjs';
import { isThemeColor } from '../../../cdk-types/esm2022/lib/basic-types/type-guards.mjs';
import { isTooltipContent } from '../../../common-utils-tooltip/esm2022/lib/tooltip.types.mjs';
import { LoggerUtils } from '../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
const DEFAULT_BADGE_VALUE = undefined;
const DEFAULT_BADGE_STRING_VALUE = '';
const DEFAULT_ICON_VALUE = '';
const DEFAULT_COLOR_VALUE = ThemeColor.None;
const DEFAULT_TOOLTIP_TEXT_VALUE = '';
const DEFAULT_IS_ACTIVE_VALUE = false;
class ButtonInputValidationService extends RaUiInputValidationService {
  constructor() {
    super(...arguments);
    this._badgeValue = DEFAULT_BADGE_VALUE;
    this._badgeStringValue = DEFAULT_BADGE_STRING_VALUE;
    this._hideBadge = true;
    this._icon = DEFAULT_ICON_VALUE;
    //TODO: deprecated field should be remove in future versions
    this._color = DEFAULT_COLOR_VALUE;
    this._isDefaultColor = true;
    //TODO: deprecated field should be remove in version 13.0.0
    this._tooltipText = DEFAULT_TOOLTIP_TEXT_VALUE;
    this._tooltip = DEFAULT_TOOLTIP_TEXT_VALUE;
    this._isActive = DEFAULT_IS_ACTIVE_VALUE;
  }
  setBadgeValue(badge, compName) {
    if (!_.isFinite(badge)) {
      LoggerUtils.logInvalidInput(compName, 'badgeValue', badge, 'number', this.logger);
      this._badgeValue = DEFAULT_BADGE_VALUE;
      this._badgeStringValue = DEFAULT_BADGE_STRING_VALUE;
      this._hideBadge = true;
    } else {
      this._badgeValue = badge;
      // badge! - badge cannot be null/undefined here, because _.isFinite(null/undefined) returns false
      this._badgeStringValue = BadgeUtils.getValueBadge(badge, 99);
      this._hideBadge = false;
    }
  }
  setIcon(icon, compName) {
    if (_.isString(icon)) {
      this._icon = icon;
    } else {
      this._icon = '';
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'icon', icon, 'string', this.logger);
    }
  }
  /*
   * //TODO: deprecated method should be remove in future versions
   * @deprecated expected removal in future versions
   * */
  setColor(color, compName) {
    if (isThemeColor(color)) {
      this._color = color;
    } else {
      this._color = DEFAULT_COLOR_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'color', color, 'ThemeColor', this.logger);
    }
    this._isDefaultColor = this._color === DEFAULT_COLOR_VALUE;
  }
  //TODO: deprecated method should be remove in version 13.0.0
  setTooltipText(tooltipText, compName) {
    if (_.isString(tooltipText)) {
      this._tooltipText = tooltipText;
    } else {
      this._tooltipText = DEFAULT_TOOLTIP_TEXT_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'tooltipText', tooltipText, 'string', this.logger);
    }
  }
  setTooltip(tooltip, compName) {
    if (isTooltipContent(tooltip)) {
      this._tooltip = tooltip;
    } else {
      this._tooltip = DEFAULT_TOOLTIP_TEXT_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'tooltip', tooltip, 'TooltipContent', this.logger);
    }
  }
  setIsActive(val, compName) {
    if (_.isBoolean(val)) {
      this._isActive = val;
    } else {
      this._isActive = false;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'isActive', val, 'boolean', this.logger);
    }
  }
}
export { ButtonInputValidationService };
