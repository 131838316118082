import { MenuCssConstants } from '../../../popups-menu/esm2022/lib/menu.types.mjs';
class SelectUtils {
  static getMenuSurroundingSize() {
    return MenuCssConstants.menuTopPadding + MenuCssConstants.menuBottomPadding + MenuCssConstants.borderVertical + 1;
  }
  static getMenuCustomHeight(param) {
    return param + MenuCssConstants.buttonMargin;
  }
  static getMenuParentElementByClass(overlayRef, className) {
    return SelectUtils.getMenuElementByClass(overlayRef, className)?.parentElement?.parentElement;
  }
  static getMenuElementByClass(overlayRef, className) {
    const root = overlayRef?.overlayElement.getRootNode();
    return root?.querySelector(`.${className}`);
  }
  static destroyOverlay(overlayRef) {
    if (!overlayRef) {
      return;
    }
    if (overlayRef.hasAttached()) {
      overlayRef.detach();
    }
    overlayRef.dispose();
  }
  static findIndexOfTheCurrentValue(items, value) {
    let resultIndex = -1;
    for (let i = 0; i < items.length; i++) {
      if (value === items[i].value) {
        resultIndex = i;
        break;
      }
    }
    return resultIndex;
  }
  static getXMenuCoordinate(el) {
    return el.nativeElement.getBoundingClientRect().left;
  }
}
export { SelectUtils };
