import { MatLegacyFormFieldControl } from '@angular/material/legacy-form-field';
import * as _ from 'lodash';
import { isInnerItemBase } from '../../../cdk-types/esm2022/lib/basic-types/type-guards.mjs';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
const DEFAULT_RESET_OPTION_TEXT = 'RA_UI_FORM_SYSTEM.SELECT.NONE';
/**
 * The wrapper class is used in select-inner to fix an error after updating to typescript 4+:
 * 'value'is defined as an accessor in class 'MatFormFieldControl'
 */
/** @docs-private */
class RaUiOverrideMatFormFieldControl extends MatLegacyFormFieldControl {}
/**
 * A type guard checking whether a value is of type {@link ISelectItem}.
 */
function isSelectItem(val) {
  return isInnerItemBase(val) && val.hasOwnProperty('value') && TypeUtils.isObjectValid(val, [{
    name: 'isCustomTemplateDisabled',
    checkFunction: v => _.isBoolean(v)
  }]);
}
/**
 * A method checking whether an array contains isSelectItem elements.
 */
function isSelectItemArray(array) {
  const invalidElementIndex = array.findIndex(el => !isSelectItem(el));
  return {
    invalidArray: invalidElementIndex !== -1,
    invalidElementIndex: invalidElementIndex
  };
}
export { DEFAULT_RESET_OPTION_TEXT, RaUiOverrideMatFormFieldControl, isSelectItem, isSelectItemArray };
