import { BigNumber } from 'bignumber.js';
import * as _ from 'lodash';
const DEFAULT_DECIMAL_POINT_CHAR = '.';
const DEFAULT_MAX_EXPONENT_SHOWN_AS_NORMAL = 20;
const DEFAULT_MIN_EXPONENT_SHOWN_AS_NORMAL = -6;
const DEFAULT_MINIMUM_NUMBER_PADDING = 1;
const EMPTY_STRING = '';
class StringAsNumberUtils {
  /**
   * Method that checks if the string is strictly written in format of defined base.
   * example '1f' is number 31 in hexadecimal, but it should not be convertible to decimal,
   * however native javascript parseInt can convert it to number 1
   */
  static isBase(s, base) {
    s = StringAsNumberUtils.removeLeadingZeroes(s);
    s = StringAsNumberUtils.removePlusSign(s);
    s = StringAsNumberUtils.addPadding(s, 1);
    const parsed = new BigNumber(s, base).toString(base);
    if (StringAsNumberUtils.checkMinusZero(s)) {
      return true;
    }
    return s.toLowerCase() === parsed.toLowerCase();
  }
  /**
   * Method that parses string as parseFloat() method with addition to configure the parse procedure
   * example '000.0000000001' is parsed to '1e-10' but with some settings it should be parsed to '0.00000000001000' or '1.00e-10' etc.
   */
  static parseAsNumber(s, digitsAfterPoint, maxExponentShownAsNormal = DEFAULT_MAX_EXPONENT_SHOWN_AS_NORMAL, minExponentShownAsNormal = DEFAULT_MIN_EXPONENT_SHOWN_AS_NORMAL, decimalPointChar = DEFAULT_DECIMAL_POINT_CHAR) {
    StringAsNumberUtils.throwErrorOnUnsupportedDecimalPoint(decimalPointChar);
    s = s.replace(decimalPointChar, DEFAULT_DECIMAL_POINT_CHAR);
    const num = new BigNumber(s);
    if (!num.isFinite()) {
      return num.toString();
    }
    const toExponential = digitsAfterPoint ? num.toExponential(digitsAfterPoint) : num.toExponential();
    const exponentNumber = parseInt(toExponential.replace(/.*[eE]/, ''), 10);
    const toFixed = digitsAfterPoint ? num.toFixed(digitsAfterPoint) : num.toFixed();
    let result = toFixed;
    if (exponentNumber > maxExponentShownAsNormal || exponentNumber < minExponentShownAsNormal) {
      result = toExponential;
    }
    return result.replace(DEFAULT_DECIMAL_POINT_CHAR, decimalPointChar);
  }
  /**
   * Method that adds leading zeroes to specified number (which is represented as string in base up to 36) length
   * example '-2.34' should be transformed to '-000002.34' once the parameter is set to 6.
   * example '-234.567' should stays as it is once the parameter is set to 3 or less.
   */
  static addPadding(s, numberPadding) {
    // in case of number with minus sign or floating point there is parsed part between (up to base 36)
    const matchDigitsGroup = s.replace(/^[+-]/, '').match(/^[0-9a-zA-Z]*/);
    const beforePointLength = matchDigitsGroup ? matchDigitsGroup[0].length : 0;
    if (numberPadding <= beforePointLength) {
      return s;
    }
    const zeroesToAdd = '0'.repeat(numberPadding - beforePointLength);
    // insert zeroes at start of string or between possible minus sign and integral part
    s = s.replace(/(^[+-])?(.*)/, '$1' + zeroesToAdd + '$2');
    return s;
  }
  /**
   * Method that adds/removes zeroes at the end of number (after the decimal point)
   * to fulfill the minimum length of number after decimal point.
   * example for minDigits = 3 it will do conversions '5' -> '5.000', or '5.1e5' -> '5.100e5', or '5e-3' -> '5.000e-3'
   */
  static toMinDigitsAfterPoint(s, minDigits, decimalPointChar = DEFAULT_DECIMAL_POINT_CHAR) {
    StringAsNumberUtils.throwErrorOnUnsupportedDecimalPoint(decimalPointChar);
    const parts = s.split(decimalPointChar);
    const integralPart = parts[0];
    const floatingPart = parts[1];
    if (!_.isNil(floatingPart)) {
      // floating part without possible engineering notation and ending zeroes
      const floatingNumericPart = floatingPart.replace(/(\d*)([eE].*)?/, '$1').replace(/0*$/, '');
      const floatingNumericPartLength = floatingNumericPart.length;
      const floatingExponentialPart = floatingPart.replace(/^\d*/, '');
      if (floatingNumericPartLength === 0 && (_.isNil(minDigits) || minDigits < 1)) {
        return integralPart + floatingExponentialPart;
      }
      let zeroesToAdd = '';
      if (!_.isNil(minDigits) && minDigits > 0 && minDigits > floatingNumericPartLength) {
        zeroesToAdd = '0'.repeat(minDigits - floatingNumericPartLength);
      }
      return integralPart + decimalPointChar + floatingNumericPart + zeroesToAdd + floatingExponentialPart;
    } else if (!_.isNil(minDigits) && minDigits > 0) {
      const zeroesToAdd = '0'.repeat(minDigits);
      // add decimal point and minimum number of zeroes between integral part and possible engineering notation
      return integralPart.replace(/([+-]?\d*)([Ee].*)?/, '$1' + decimalPointChar + zeroesToAdd + '$2');
    }
    // in case there is no floating part and minDigits, the input string is returned without change
    return s;
  }
  /**
   * Method that removes zeroes from the start
   * example '+00053' -> '+53'
   */
  static removeLeadingZeroes(s) {
    const ret = s.replace(/^([+-])?[0]*/, '$1');
    return ret;
  }
  /**
   * Method that removes plus sign from the start
   * example '+FF5542' -> 'FF5542'
   */
  static removePlusSign(s) {
    const ret = s.replace(/^[+]/, '');
    return ret;
  }
  /**
   * Method that checks if the string is a negative zero
   * it expects that there could be decimal point character that is outside of base 36
   * example '-0' -> true '-0000.00000' -> true '-00,' -> true '-000f00' -> false
   */
  static checkMinusZero(s) {
    const testRegex = /^[-]0*[^1-9a-zA-Z]0*$/;
    return testRegex.test(s);
  }
  /**
   * Method that throws error once the parameter is one of those ['+', '-', 'e', 'E']
   */
  static throwErrorOnUnsupportedDecimalPoint(decimalPointChar) {
    if (_.includes(['+', '-', 'e', 'E'], decimalPointChar)) {
      throw new Error(`Character "${decimalPointChar}" is not allowed as decimal point.`);
    }
  }
}
export { DEFAULT_DECIMAL_POINT_CHAR, DEFAULT_MAX_EXPONENT_SHOWN_AS_NORMAL, DEFAULT_MINIMUM_NUMBER_PADDING, DEFAULT_MIN_EXPONENT_SHOWN_AS_NORMAL, EMPTY_STRING, StringAsNumberUtils };
