import * as _ from 'lodash';

/**
 * Logger utilities used for logging in the toolkit components.
 */
class LoggerUtils {
  /**
   * A method which warns about invalid input.
   */
  static logInvalidInput(component, input,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedValue, correctValue, logger) {
    this.invalidInputLog(component, input, receivedValue, correctValue, logger, 'warn');
  }
  /**
   * A method which errors about invalid input.
   */
  static errorInvalidInput(component, input,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedValue, correctValue, logger) {
    this.invalidInputLog(component, input, receivedValue, correctValue, logger, 'error');
  }
  /**
   * A method which warns about unsupported type.
   */
  static logUnsupportedType(component, control, logger) {
    logger.warn(`Unsupported type of ${control} inside of ${component}`);
  }
  /**
   * A method which warns about invalid input value.
   */
  static logInvalidInputValue(component, input, logger) {
    logger.warn(`Invalid input value of ${input} on ${component}.`);
  }
  /**
   * A method which warns about value mismatch.
   */
  static logInvalidMatchValue(component, input, receivedValue, correctValues, logger) {
    if (receivedValue === 'undefined') {
      return;
    }
    logger.warn(`Invalid input ${input} on ${component}. ` + `Expected some values from [\"${correctValues.join('", "')}\"] but received ${receivedValue}.`);
  }
  /**
   * A method which warns about invalid config.
   */
  static logInvalidConfig(component, configParameter, logger, newValue, reason) {
    let message = `Invalid config.${configParameter} on ${component}`;
    reason && (message += '\n' + `Reason: Parameter "${configParameter}" ${reason}`);
    newValue !== undefined && (message += '\n' + `Parameter value changed to: ${newValue}`);
    logger.warn(message);
  }
  static logInvalidElement(component, input, invalidIndex, correctValue, logger) {
    logger.warn(`Invalid element on index ${invalidIndex.toString()} in ${input} on ${component}. Expected ${correctValue}.`);
  }
  static logDuplicatedIDs(component, logger) {
    logger.warn(`There are duplicated IDs in the ${component}, the component might not work correctly!`);
  }
  static logDeprecatedItem(deprecatedItem, removeInVer, newAlternative, logger) {
    logger.warn(`${deprecatedItem} is deprecated, it will be removed in ${removeInVer} version. Use ${newAlternative} instead.`);
  }
  static logDeprecatedItemWithoutRemove(deprecatedItem, newAlternative, logger, addThirdPartyRecommendation = false) {
    const message = addThirdPartyRecommendation ? `${deprecatedItem} is deprecated. Consult the team for a proper 3rd party software replacement based on your needs.` : `${deprecatedItem} is deprecated. Use ${newAlternative} instead.`;
    logger.warn(message);
  }
  static logInvalidLength(component, expectedLength, logger) {
    logger.warn(`Value longer than ${expectedLength} passed into the ${component}!`);
  }
  static logMissingDependency(component, expectedParam, logger) {
    logger.warn(`${expectedParam} has to be defined for ${component}`);
  }
  static logUnsupportedConfiguration(component, settings, logger) {
    //tslint rule is disabled to force the warn message to be one-lined
    //eslint-disable-next-line max-len
    logger.warn(`${component} settings combination of ${settings.toString()} is not supported! Such setting may lead to unexpected behavior.`);
  }
  static logUnsupportedObservable(component, input, logger, supportedVersion) {
    logger.warn(`Sorry, input ${input} in ${component} does not support Observable yet.
            Support for Observable will be added in ${supportedVersion ?? 'future'} release.`);
  }
  static logUnexpectedNumber(component, property, expectedNumber, logger) {
    logger.warn(`${property} is expected to be set only ${expectedNumber} time(s) for ${component}.`);
  }
  static logItemWithIdNotFound(component, item, id, logger) {
    logger.warn(`${component} - there is no ${item} with id "${id}"`);
  }
  static logError(component, error, logger) {
    logger.error(`Error occurred in ${component}.\n${String(error)}`);
  }
  static logIncompatibleDisplayInputTypeError(displayTypeId, inputTypeId, logger) {
    logger.error(`displayType: '${displayTypeId}' does not have defined conversion functions for inputType: '${inputTypeId}'`);
  }
  static logObservableExceptionError(componentName, observableName, logger) {
    logger.error(`Error occurred in ${componentName}'s '${observableName}' Observable.`);
  }
  /**
   * A private method handles the proper invalid input logging.
   */
  static invalidInputLog(component, input,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedValue, correctValue, logger, log) {
    if (_.isNil(receivedValue)) {
      return;
    }
    const receivedValueType = receivedValue instanceof Array ? 'array' : typeof receivedValue;
    const logString = `Invalid input ${input} on ${component}. Expected ${correctValue} but received ${receivedValueType}.`;
    log === 'warn' ? logger.warn(logString) : logger.error(logString);
  }
}
export { LoggerUtils };
