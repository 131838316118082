import * as _ from 'lodash';

/**
 * A method that checkes whether a value is of type InputType.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isInputType(val) {
  if (_.isNil(val)) {
    return false;
  }
  // val.disableDisplayTypeBlur is optional
  return _.isString(val.id) && _.isFunction(val.typeCheckFn) && val.hasOwnProperty('emptyValue');
}
export { isInputType };
