import { isObservable } from 'rxjs';
import { CursorStyle, TextAlign, TextStyle, SizeUnits, NotificationType, ThemeColor, BackgroundColors, StatusColors, LinkTarget, IconSize, ActionButtonStyles } from './enums.mjs';
import * as _ from 'lodash';
import { TemplateRef } from '@angular/core';
import { TypeUtils } from '../type-utils/type-utils.mjs';

/**
 * A method that concat several Type guards and return one function that runs all checks with same value.
 * @param fns array of Type guards
 */
const chainTypeChecks = (...fns) => {
  return val => {
    return fns.reduce((acc, fn) => {
      return acc ? fn(val) : false;
    }, true);
  };
};
/**
 * Function checks that value is {@link TypeOrObservable}.
 * @param val value to be checked.
 * @param checkFunction value to be checked.
 */
const isTypeOrObservable = (val, checkFunction) => {
  return checkFunction(val) || isObservable(val);
};
// region ValueType
/**
 * A method checking whether a value is of type {@link ValueType}, i.e. string, number, or boolean.
 * @param val value to be checked.
 */
const isValueType = val => {
  return _.isString(val) || _.isNumber(val) || _.isBoolean(val);
};
/**
 * Determines whether a given {@link ValueType} is empty or not. Numbers and booleans are considered as non-empty values.
 * @param val value to be checked.
 */
const isValueTypeEmpty = val => {
  return _.isEmpty(val) && !_.isNumber(val) && !_.isBoolean(val);
};
// endregion ValueType
/**
 * A type guard checking whether a value is instance of {@link IOffset}.
 * @param val value to be checked.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const isIOffset = val => {
  return _.isPlainObject(val) && _.isFinite(val.offsetX) && _.isFinite(val.offsetY);
};
/**
 * A type guard checking whether a value is instance of {@link CursorStyle} or undefined.
 * @param val value to be checked.
 */
const isCursorStyleOrUndefined = val => {
  return _.includes(CursorStyle, val) || _.isNil(val);
};
/**
 * A type guard checking whether a value is instance of {@link TextAlign}.
 * @param val value to be checked.
 */
const isTextAlign = val => {
  return _.includes(TextAlign, val);
};
/**
 * A type guard checking whether a value is instance of {@link TextStyle}.
 * @param val value to be checked.
 */
const isTextStyle = val => {
  return _.includes(TextStyle, val);
};
/**
 * A type guard checking whether a value is instance of {@link TemplateRef}.
 * @param val value to be checked.
 */
const isTypeTemplateRef = val => {
  return val instanceof TemplateRef;
};
/**
 * A method checking whether a variable is instance of {@link SizeUnits}.
 * @param val value to be checked.
 */
const isSizeUnits = val => {
  return !_.isNil(val) && _.includes(SizeUnits, val);
};
/**
 * A method checking whether a variable is instance of {@link ISizeWithUnits}.
 * @param object value to be checked.
 */
const instanceOfSizeWithUnits = (
//eslint-disable-next-line @typescript-eslint/no-explicit-any
object) => {
  return !_.isNil(object) && _.isNumber(object.size) && isSizeUnits(object.units);
};
/**
 * A method checking whether a variable is instance of {@link IFocusable}.
 * @param val value to be checked.
 */
const instanceOfFocusable = val => {
  return _.isObject(val) && _.isFunction(val.focus); //eslint-disable-line @typescript-eslint/no-explicit-any
};
/**
 * A type guard checking whether a value is instance of {@link ClassDef}.
 * @param val value to be checked.
 */
const isClassDef = val => {
  return _.isString(val) || TypeUtils.isArrayOf(val, el => {
    return _.isString(el);
  }) || _.isPlainObject(val) && Object.values(val).every(el => {
    return _.isBoolean(el);
  }) || val instanceof Set && Array.from(val).every(el => {
    return _.isString(el);
  });
};
/**
 * A type guard checking whether a value is of type {@link IInnerItem}.
 * @param val value to be checked.
 */
function isInnerItem(val) {
  return isInnerItemBase(val) && TypeUtils.isObjectValid(val, [{
    name: 'value',
    checkFunction: v => isValueType(v),
    isMandatory: true
  }]);
}
/**
 * A type guard checking whether a value is of type {@link IInnerItemBase}
 * @param val value to be checked.
 */
function isInnerItemBase(val) {
  // IInnerItemBase.data is optional and any => no need to check that property
  return TypeUtils.isObjectValid(val, [{
    name: 'label',
    checkFunction: v => _.isString(v)
  }, {
    name: 'disabled',
    checkFunction: v => _.isBoolean(v)
  }, {
    name: 'id',
    checkFunction: v => _.isString(v)
  }]);
}
/**
 * A method checking whether an array contains {@link IInnerItem} elements.
 * @param array array to be checked.
 */
const isInnerItemArray = array => {
  let invalidItems = false;
  let invalidElementIndex = -1;
  for (let i = 0; i < array.length; i++) {
    if (!isInnerItem(array[i])) {
      invalidItems = true;
      invalidElementIndex = i;
      break;
    }
  }
  return {
    invalidArray: invalidItems,
    invalidElementIndex: invalidElementIndex
  };
};
/**
 * A method that checks whether a value is of type {@link NotificationType}.
 * @param val value to be checked.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNotificationType = val => {
  return _.includes(NotificationType, val);
};
/**
 * Checks whether the parameter value is material constant which exists in {@link ThemeColor}, but not {@link ThemeColor.None}
 * @param val value to be checked.
 */
const isMaterialColor = val => {
  return _.includes(ThemeColor, val) && val !== ThemeColor.None;
};
/**
 * Checks whether the parameter value is {@link ThemeColor} constant.
 * @param val value to be checked.
 */
const isThemeColor = val => {
  return val === ThemeColor.None || isMaterialColor(val);
};
/**
 * Checks that color is from {@link BackgroundColors}.
 * @param val value to be checked.
 */
const isBackgroundColors = val => {
  return _.includes(BackgroundColors, val);
};
/**
 * Checks that color is from {@link BackgroundColorOrTransparent}.
 * @param val value to be checked.
 */
const isBackgroundColorOrTransparent = val => {
  return isBackgroundColors(val) || val === 'transparent';
};
/**
 * Checks that color is from {@link StatusColors}.
 * @param val value to be checked.
 */
const isStatusColors = val => {
  return _.includes(StatusColors, val);
};
/**
 * Checks whether the parameter value is hexadecimal color
 * @param value value to be checked
 */
const isHexadecimalColor = value => {
  return /(^#[0-9A-F]{6}$)/i.test(value);
};
/**
 * A method that checks whether the value is finite number.
 * @param val value to be checked.
 */
const isFiniteNumber = val => {
  return _.isFinite(val);
};
/**
 * A method that checks whether the decimal precision is valid.
 * @param val value to be checked.
 */
const isValidDecimalPrecision = val => {
  return isFiniteNumber(val) && _.inRange(val, 0, 21);
};
/**
 * A method that checks whether the multiplication is valid.
 * @param val value to be checked.
 */
const isValidMultiplication = val => {
  return isFiniteNumber(val) && val > 0;
};
/**
 * Function that checks whether a non-numeric value includes special numeric characters (+, -, e, .) with possible numeric value
 * @param val - value that should be checked
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasNumericValue = val => {
  if (_.isNumber(val)) {
    // we only want to pass through values that have numeric value, but are not numbers
    return false;
  }
  const value = val.toString();
  const numberWithSpecialCharacters = new RegExp('^[+-.]?\\d*\\.?\\d*[eE][+-]?\\d*$');
  const onlySigns = new RegExp('^[+-.][.]?$');
  return numberWithSpecialCharacters.test(value) || onlySigns.test(value);
};
/**
 * Function that checks whether a value is positive
 * @param val - value that should be checked
 */
const isPositiveInteger = val => {
  return _.isNumber(val) && _.isInteger(val) && val > 0;
};
/**
 * Function that return function which checks if value is less than passed maximum value inclusive.
 * @param maxValue - maximum value inclusive;
 */
const isNumberLessThan = maxValue => {
  return val => {
    return _.isNumber(val) && val <= maxValue;
  };
};
/**
 * A method that checks whether a value is of type {@link LinkTarget}.
 * @param val value to be checked.
 */
const isLinkTarget = val => {
  return _.includes(LinkTarget, val);
};
/**
 * Function that checks if value is of type boolean or observable
 * @param val value to be checked.
 */
const isTypeOrObservableBoolean = val => {
  return isTypeOrObservable(val, v => {
    return _.isBoolean(v);
  });
};
/**
 * A method that checks whether a value is of type {@link IconSize}.
 * @param val value to be checked.
 */
const isIconSize = val => {
  return _.includes(IconSize, val);
};
/**
 * A method that checks whether a value is from enum {@link ActionButtonStyles}.
 * @param val value to be checked
 */
const isActionButtonStyle = val => {
  return _.includes(ActionButtonStyles, val);
};
/**
 * Checks whether value is of type {@link ListRange}
 * @param value value to be checked
 */
const isListRange = value => {
  return TypeUtils.isObjectValid(value, [{
    name: 'start',
    checkFunction: item => {
      return _.isNumber(item);
    },
    isMandatory: true
  }, {
    name: 'end',
    checkFunction: item => {
      return _.isNumber(item);
    },
    isMandatory: true
  }]);
};
/**
 * Checks whether value is an array of {@link ListRange} objects. Allows empty array as well.
 * @param value value to be checked
 */
const isListRangesArray = value => {
  return TypeUtils.isArrayOf(value, isListRange);
};
/**
 * Checks whether a value is of type ListRange[] or Observable<ListRange[]>.
 * @param val - value to be checked
 */
const isTypeOrObservableRanges = val => {
  return !_.isNil(val) && TypeUtils.isArrayOf(val, item => {
    return isListRange(item);
  }) || isObservable(val);
};
export { chainTypeChecks, hasNumericValue, instanceOfFocusable, instanceOfSizeWithUnits, isActionButtonStyle, isBackgroundColorOrTransparent, isBackgroundColors, isClassDef, isCursorStyleOrUndefined, isFiniteNumber, isHexadecimalColor, isIOffset, isIconSize, isInnerItem, isInnerItemArray, isInnerItemBase, isLinkTarget, isListRange, isListRangesArray, isMaterialColor, isNotificationType, isNumberLessThan, isPositiveInteger, isSizeUnits, isStatusColors, isTextAlign, isTextStyle, isThemeColor, isTypeOrObservable, isTypeOrObservableBoolean, isTypeOrObservableRanges, isTypeTemplateRef, isValidDecimalPrecision, isValidMultiplication, isValueType, isValueTypeEmpty };
