import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
import { isTypeContent } from '../../../content-rendering-content-projection/esm2022/lib/content-projection.types.mjs';

/**
 * Checks whether a value is of type INavigationTabItem.
 * @param val - value to be checked
 */
function isINavigationTabItem(val) {
  return TypeUtils.isObjectValid(val, [{
    name: 'id',
    checkFunction: v => {
      return _.isString(v);
    }
  }, {
    name: 'label',
    checkFunction: v => {
      return _.isString(v);
    },
    isMandatory: true
  }, {
    name: 'icon',
    checkFunction: v => {
      return _.isString(v);
    },
    isMandatory: false
  }, {
    name: 'content',
    checkFunction: v => {
      return isTypeContent(v);
    },
    isMandatory: false
  }, {
    name: 'width',
    checkFunction: v => {
      return _.isNumber(v);
    },
    isMandatory: false
  }]);
}
/**
 * Checks whether a value is of type INavigationTabItem[].
 * @param val - value to be checked
 */
function isINavigationTabItems(val) {
  return TypeUtils.isArrayOf(val, item => {
    return isINavigationTabItem(item);
  });
}
export { isINavigationTabItem, isINavigationTabItems };
