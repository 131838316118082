import { TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { strEnum } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';

//region TooltipPosition
const TooltipPosition = strEnum(['Cover', 'Top', 'Left', 'Bottom', 'Right', 'Center', 'Cursor']);
/*
 * Checks if value is tooltip
 */
const isTooltipContent = val => {
  return _.isString(val) || val instanceof TemplateRef;
};
export { TooltipPosition, isTooltipContent };
