/**
 * Immutable decorator entry point.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
//eslint-disable-next-line @typescript-eslint/naming-convention
function Immutable(decoratedClass) {
  return class extends decoratedClass {
    constructor(...args) {
      // tslint:disable-next-line:no-inferred-empty-object-type
      super(...args);
      Object.freeze(this);
      Object.freeze(decoratedClass.prototype);
    }
  };
}
export { Immutable };
