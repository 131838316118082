import * as _ from 'lodash';
import { TypeUtils } from '../../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
import { LoggerUtils } from '../../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
class IdUtils {
  /**
   * A method that adds default IDs when they are not specified.
   */
  static addDefaultIDs(array, customIDString) {
    if (!TypeUtils.isArrayOf(array, val => {
      return TypeUtils.isObject(val);
    })) {
      // return it back when it is not array of objects (there is spec for that case)
      return array;
    }
    const helperArray = _.cloneDeep(array);
    helperArray.forEach((item, index) => {
      if (_.isNil(item.id) || _.isString(item.id) && _.isEmpty(item.id)) {
        item.id = `ra-ui-${customIDString}-${index.toString()}`;
      }
    });
    return helperArray;
  }
  /**
   * Checks whether the provided id exists in an array.
   */
  static checkIfIdExists(id, array) {
    if (!TypeUtils.isArrayOf(array, val => {
      return TypeUtils.isObject(val);
    })) {
      return -1;
    }
    /**
     * the result has to be stored in a constant instead of returning it right away
     * to avoid 'Lambda not supported' compiler error
     */
    const result = _.findIndex(array, item => {
      return item.id === id;
    });
    return result;
  }
  /**
   * When the array contains more objects with the same id property, it logs warning to console
   * @param array - array to be checked
   * @param component - name of the component
   * @param logger - logger instance
   * @param hasOptionalId - ignores undefined id properties as duplicates
   */
  static checkForDuplicates(array, component, logger, hasOptionalId = false) {
    array.some(element => {
      if (_.isNil(element.id) && hasOptionalId) {
        return false;
      }
      const identifier = element.id;
      const result = array.filter(el => {
        return el.id === identifier;
      });
      if (result.length > 1) {
        LoggerUtils.logDuplicatedIDs(component, logger);
        return true;
      } else {
        return false;
      }
    });
  }
}
export { IdUtils };
