/**
 * TO DO: review for dead code
 */

import { MessageIcons, Role } from './+state/common-admin.model';

const noMoreTrialsCampaignMsg =
  'Your trial reservation was not created. There are no more trials available in this campaign.';

export interface MessageIconMap {
  [key: string]: { icon: MessageIcons; message: string };
}

export class CommonConstants {
  // Session Timeout Configuration

  private static readonly userActivityIntervalMinutes = 25;
  private static readonly sessionBannerMaxDurationInMinutes = 3;

  public static readonly userActivityIntervalMillis = CommonConstants.userActivityIntervalMinutes * 60 * 1000;
  public static readonly sessionBannerMaxDurationInSeconds = CommonConstants.sessionBannerMaxDurationInMinutes * 60; // 3 Minute

  private static readonly sessionBannerMaxDurationInMillis = CommonConstants.sessionBannerMaxDurationInSeconds * 1000; // 3 Minute
  public static readonly allowedElapsedTimeWithoutActivityMillis =
    CommonConstants.userActivityIntervalMillis + CommonConstants.sessionBannerMaxDurationInMillis;

  public static readonly responseMessageIconColorMap: MessageIconMap = {
    'add-access': {
      icon: MessageIcons.Success,
      message: 'Access has been added successfully.',
    },
    'duplicate-add-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with the selected user.',
    },
    'duplicate-edit-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with this user.',
    },
    'edit-access': {
      icon: MessageIcons.Success,
      message: 'Access has been modified successfully.',
    },
    'invite-user': {
      icon: MessageIcons.Error,
      message: 'Email address you entered is not valid',
    },
    'license-agreement': {
      icon: MessageIcons.LicenseScroll,
      message: "Scroll to enable 'Accept' button",
    },
    'remove-access': {
      icon: MessageIcons.Info,
      message: 'Access has been removed successfully.',
    },
  };

  // Help Screen Data
  public static readonly CONTACT_SUPPORT_URL = 'https://rockwellautomation.custhelp.com/app/chat/chat_landing/contacts.email/';
  static readonly entitlementNewPeriodInDays = 3;

  public static getEmailDomain(emailString: string): string {
    return emailString.split('@')[1];
  }
}

export const SELECTABLE_ROLES = [Role.Admin, Role.BillingAdmin, Role.Contributor];

export const ErroMessageMap: MessageIconMap = {
  EntitlementAlreadyConsumed: {
    message: 'Entitlement already consumed',
    icon: MessageIcons.Error,
  },
  EntitlementTransactionInProgress: {
    message: 'Service provisioning is in progress, please complete the provisioning before applying the entitlement',
    icon: MessageIcons.Error,
  },
  'EntitlementError-InactiveEntitlement': {
    message: 'Cannot allocate, the entitlement is no more active',
    icon: MessageIcons.Error,
  },

  'EntitlementError-platform entitlements cannot overlap': {
    message: 'Service already has a platform entitlement allocated for the time period',
    icon: MessageIcons.Error,
  },
  CannotApplyEntitlementToPersonalTenant: {
    message: 'Entitlements cannot be allocated to a personal organization',
    icon: MessageIcons.Error,
  },
  EntitlementNotActive: {
    message: 'Entitlement is not active',
    icon: MessageIcons.Error,
  },
  InvitationAlreadyExists: {
    message: 'Invitation for the user already exists',
    icon: MessageIcons.Warning,
  },
  MinimumTimeBetweenInvitationReissueNotSatisfied: {
    message: 'Minimum time between invitation reissue not satisfied',
    icon: MessageIcons.Error,
  },
  UserAlreadyHasAccessToSpecifiedResource: {
    message: 'Your account already has access to the organization',
    icon: MessageIcons.Error,
  },
  'EntitlementError-Platform/Trial/Additive entitlement needs to applied for activating the service': {
    message: 'Platform/Additive entitlement needs to applied before applying an Add-on entitlement',
    icon: MessageIcons.Error,
  },
  'EntitlementError-Entitlement kind disallows quantity more than 1': {
    message: 'Only one entitlement of type Platform can be allocated at a time',
    icon: MessageIcons.Error,
  },
  InvitationAlreadyAccepted: {
    message: 'Invitation is already accepted for this organization.',
    icon: MessageIcons.Error,
  },
  InvitationAlreadyCancelled: {
    message: 'Invitation is cancelled. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
  InvitationExpired: {
    message: 'Invitation is expired. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
  'invitation not in active state': {
    message: 'Invitation is not active. Please contact your administrator.',
    icon: MessageIcons.Error,
  },
  TrialCampaignCreditsExhausted: {
    message: noMoreTrialsCampaignMsg,
    icon: MessageIcons.Error,
  },
  TrialCampaignHasEnded: {
    message: 'The operation failed: The trial campaign has ended.',
    icon: MessageIcons.Error,
  },
  InvalidTrialCampaign: {
    message: `The operation failed: Trial Campaign is Invalid.`,
    icon: MessageIcons.Error,
  },
  TrialCampaignIsNotActive: {
    message: 'The operation failed: The trial campaign is not active anymore.',
    icon: MessageIcons.Error,
  },
  TrialCampaignHasPaused: {
    message: 'The operation failed: The trial campaign is paused.',
    icon: MessageIcons.Error,
  },
  InvalidTrialReservation: {
    message: noMoreTrialsCampaignMsg,
    icon: MessageIcons.Error,
  },
  TrialPlatformQuantityExhausted: {
    message: noMoreTrialsCampaignMsg,
    icon: MessageIcons.Error,
  },
  TrialCannotBeRedeemed: {
    message: 'This trial couldn’t be redeemed. Close all tabs and try again.',
    icon: MessageIcons.Error,
  },
  CannotDeReserveRedeemedTrial: {
    message: 'This reservation could not be cancelled. The trial has already been redeemed.',
    icon: MessageIcons.Error,
  },
  InvalidTrialCampaignName: {
    message: 'There was an error trying to create the trial campaign: Invalid Trial Campaign Name.',
    icon: MessageIcons.Error,
  },
  InvalidTrialCampaignQuantity: {
    message: 'There was an error trying to create the trial campaign: Invalid Trial Campaign Quantity.',
    icon: MessageIcons.Error,
  },
  InvalidTrialCampaignCredits: {
    message: 'There was an error trying to create the trial campaign: Invalid Trial Campaign Credits.',
    icon: MessageIcons.Error,
  },
  InvalidTrialRedemptionLimit: {
    message: 'There was an error trying to create the trial campaign: Invalid Trial Redemption Limit.',
    icon: MessageIcons.Error,
  },
  InvalidTrialDuration: {
    message: 'There was an error trying to create the trial campaign: Invalid Trial Duration.',
    icon: MessageIcons.Error,
  },
  InvalidPerTrialCredits: {
    message: 'There was an error trying to create the trial campaign: Invalid Per Trial Credits.',
    icon: MessageIcons.Error,
  },
  UserDoesNotHaveMfaEnabled: {
    message: 'MFA not enabled for user account',
    icon: MessageIcons.Error,
  },
};

export class ErrorPartMessageMapper {
  static readonly ErrorPartMessageMap: MessageIconMap = {
    'does not overlap platform entitlement': {
      message: 'Add-On entitlement dates does not overlap with platform entitlement dates',
      icon: MessageIcons.Error,
    },
  };
  public static getMessageIconMap(errorCode: string): { icon: MessageIcons; message: string } | undefined {
    const errorPartKeys: string[] = Object.keys(this.ErrorPartMessageMap);
    const foundErrorPartKey = errorPartKeys.find((errorPart) => errorCode.match(errorPart));
    return foundErrorPartKey ? this.ErrorPartMessageMap[foundErrorPartKey] : undefined;
  }
}

export const minDate: string = '0001-01-01T00:00:00+00:00';

export const maxDate: string = '9999-12-31T23:59:59.9999999+00:00';
