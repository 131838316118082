import * as _ from 'lodash';
import { isObservable } from 'rxjs';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

/**
 * Checks whether a value is of type BreadcrumbTitle.
 * Returns true when it is.
 * @param val - a value to be checked
 */
function isBreadcrumbTitle(val) {
  return _.isString(val) || isIBreadcrumbItem(val);
}
/**
 * Checks whether a value is of type IBreadcrumb.
 * Returns true when it is.
 * @param value - a value to be checked
 */
function isIBreadcrumbItem(value) {
  return TypeUtils.isObjectValid(value, [{
    name: 'label',
    checkFunction: item => {
      return _.isString(item);
    },
    isMandatory: true
  }, {
    name: 'id',
    checkFunction: item => {
      return _.isString(item);
    }
  }, {
    name: 'icon',
    checkFunction: item => {
      return _.isString(item);
    }
  }, {
    name: 'disabled',
    checkFunction: item => {
      return _.isBoolean(item);
    }
  }, {
    name: 'children',
    checkFunction: item => {
      return isBreadcrumbItems(item);
    }
  }]);
}
/**
 * Checks whether a value is of type IBreadcrumbItem[].
 * Returns true when it is.
 * @param value - a value to be checked
 */
function isIBreadcrumbItemsArray(value) {
  return TypeUtils.isArrayOf(value, item => {
    return isIBreadcrumbItem(item);
  });
}
/**
 * Checks whether a value is of type BreadcrumbItems.
 * Returns true when it is.
 * @param value - a value to be checked
 */
function isBreadcrumbItems(value) {
  return isIBreadcrumbItemsArray(value) || isObservable(value);
}
export { isBreadcrumbItems, isBreadcrumbTitle, isIBreadcrumbItem, isIBreadcrumbItemsArray };
