import * as _ from 'lodash';

/**
 * Typeguard.
 */
function isDynamicallyCreatableFormControl(
//eslint-disable-next-line @typescript-eslint/no-explicit-any
componentInstance) {
  return componentInstance && _.isFunction(componentInstance.setControl) && _.isFunction(componentInstance.setPropsByObject);
}
export { isDynamicallyCreatableFormControl };
