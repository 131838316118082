import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
function isResponsiveListItem(
//eslint-disable-next-line @typescript-eslint/no-explicit-any
listItem) {
  return TypeUtils.isObject(listItem) && !_.isArray(listItem) && (listItem.hasOwnProperty('id') ? _.isString(listItem.id) : true);
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isResponsiveList(list) {
  if (!(TypeUtils.isObject(list) && !_.isArray(list)) || !list.hasOwnProperty('minWidth') || !_.isNumber(list.minWidth) || !list.hasOwnProperty('items') || !_.isArray(list.items) || !list.hasOwnProperty('hiddenItemsMenu') || !(TypeUtils.isObject(list.hiddenItemsMenu) && !_.isArray(list.hiddenItemsMenu)) || !list.hasOwnProperty('defaultItemWidth') || !_.isNumber(list.defaultItemWidth)) {
    return false;
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const someListItemIsNotIResponsiveListItem = list.items.some(item => {
    return !isResponsiveListItem(item);
  });
  if (someListItemIsNotIResponsiveListItem) {
    return false;
  }
  return isResponsiveListItem(list.hiddenItemsMenu);
}
export { isResponsiveList, isResponsiveListItem };
