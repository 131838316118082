import * as _ from 'lodash';
import { DomUtils } from '../../../cdk-utils/esm2022/lib/dom-utils/dom-utils.mjs';
import { RangesUtils } from '../../../cdk-utils/esm2022/lib/ranges-utils/ranges-utils.mjs';
import { TextStyle } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
class AcceleratorUtils {
  /**
   * A method which searches for the accelerator key.
   */
  static findAcceleratorKey(text) {
    // That has to be this unpleasant way for AoT compilation and Rollup.
    const result = text.search(/&[A-Za-z]/);
    return result;
  }
  /**
   * Routine that removes an ampersand from a text which is to be used as an accelerator key, also returns its index and a char,
   * if the accelerator key was found.
   */
  static parseText(text) {
    text = text.replace('\\&', '\u0000');
    const index = AcceleratorUtils.findAcceleratorKey(text);
    if (index !== -1) {
      text = text.replace('\u0000', '&');
      return {
        text: text.slice(0, index) + text.slice(index + 1),
        index: index,
        key: text[index + 1].toLowerCase()
      };
    } else {
      text = text.replace('\u0000', '&');
      return {
        text: text
      };
    }
  }
  /**
   * Determines whether the parsedText contains accelerator key and index.
   * @param parsedText
   */
  static isAcceleratorDefined(parsedText) {
    return !_.isNil(parsedText.key) && !_.isNil(parsedText.index);
  }
  /**
   * Create 3 spans when middle span contain accelerator key with hidden underline and 2 other spans with text before and after
   */
  static generateSpansWithAcceleratorInElement(renderer, element, acceleratorCharIndex, inputText = '', highlightRanges) {
    const text = inputText || '';
    const acceleratorChar = text[acceleratorCharIndex];
    const textBefore = !_.isNil(acceleratorChar) ? text.slice(0, acceleratorCharIndex) : text;
    const textAfter = !_.isNil(acceleratorChar) ? text.slice(acceleratorCharIndex + 1) : '';
    DomUtils.removeAllChildren(renderer, element);
    const disjunctRanges = RangesUtils.getDisjunctRanges(highlightRanges);
    // render text before accelerator
    const firstText = this._getHighlightElementByRanges(renderer, disjunctRanges, textBefore);
    // render char with accelerator
    const underscore = AcceleratorUtils.appendHighlightHtmlElement(renderer, AcceleratorUtils.createUnderlineSvgElement(renderer, element), acceleratorChar, RangesUtils.getIndexSubsetRange(disjunctRanges, acceleratorCharIndex));
    // render text after accelerator
    const secondText = this._getHighlightElementByRanges(renderer, RangesUtils._createShiftRangesByIndex(disjunctRanges, textBefore.length), textAfter);
    renderer.appendChild(element, firstText);
    renderer.appendChild(element, underscore);
    renderer.appendChild(element, secondText);
  }
  /**
   * The method highlights all occurrences in a text based on the input ranges.
   * The method manipulates with DOM structure and all occurrences are wrapped to the `strong` element
   * @param renderer - edits DOM structure
   * @param highlightRanges - array of ranges, which highlights all occurrences of text.
   *                          Every range has start (inclusive) and end position (exclusive)
   * @param inputText - input text, which will be used to search all occurrences by array of ranges.
   * @param disjunctRanges - this parameter will take care of sanitizing ranges before all occurrences are highlighted in a text
   * @param element - new DOM structure is wrapped to this element, in default state is used a `span` element.
   */
  static _getHighlightElementByRanges(renderer, highlightRanges, inputText, disjunctRanges = false, element) {
    const result = element ?? renderer.createElement('span');
    if (_.isEmpty(highlightRanges)) {
      renderer.appendChild(result, renderer.createText(inputText));
      return result;
    }
    // valid ranges
    if (disjunctRanges) {
      highlightRanges = RangesUtils.getDisjunctRanges(highlightRanges, inputText.length);
    }
    AcceleratorUtils.createHighlightElementByRanges(renderer, highlightRanges, inputText, result);
    return result;
  }
  /**
   * A method which creates SVG element for the underline.
   * @param renderer edits DOM structure.
   * @return {SVGSVGElement} svg element with styles.
   */
  static createUnderlineSvg(renderer) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    renderer.setStyle(svg, 'position', 'absolute');
    renderer.setStyle(svg, 'height', '100%');
    renderer.setStyle(svg, 'width', '100%');
    renderer.setStyle(svg, 'left', '0px');
    renderer.setStyle(svg, 'top', '0px');
    renderer.setStyle(svg, 'display', 'none');
    return svg;
  }
  /**
   * A method which creates the SVG line.
   */
  static createUnderlineLine(element) {
    const elementColor = window.getComputedStyle(element).color;
    const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    line.setAttribute('x1', '10%');
    line.setAttribute('y1', '85%');
    line.setAttribute('x2', '90%');
    line.setAttribute('y2', '85%');
    line.setAttribute('style', 'stroke:' + elementColor + ';stroke-width:1');
    return line;
  }
  /**
   * The method takes all ranges and these ranges use on the HTML element between <strong> element.
   * @param renderer edits DOM structure.
   * @param ranges array of ranges.
   * @param inputText text, which should be highlighted by ranges.
   * @param resultElement all changes are renderer to this HTML element.
   * @private
   */
  static createHighlightElementByRanges(renderer, ranges, inputText, resultElement) {
    if (_.isEmpty(ranges)) {
      resultElement.appendChild(renderer.createText(inputText));
      return;
    }
    const maxLength = inputText.length;
    let previousEnd = 0;
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      // adds un-highlighted text between end position of the previous range and a start position of next range.
      if (range.start - previousEnd > 0) {
        resultElement.appendChild(AcceleratorUtils.createTextElementByRange(renderer, previousEnd, range.start, inputText));
      }
      // adds highlighted text, range is subset of input text.
      if (maxLength > range.start) {
        resultElement.appendChild(AcceleratorUtils.createHighlightTextElementByRange(renderer, range.start, range.end, inputText, range.highlightStyle));
      }
      previousEnd = range.end;
    }
    // adds un-highlighted text between end position of the last range and a position of the last char of text.
    if (ranges[ranges.length - 1].end < maxLength) {
      resultElement.appendChild(AcceleratorUtils.createTextElementByRange(renderer, ranges[ranges.length - 1].end, maxLength, inputText));
    }
  }
  /**
   * The method creates element as element for DOM.
   * @param renderer edits DOM structure.
   * @param start index of beginning the text, including.
   * @param end index of ending the text, excluding.
   * @param text input text trimmed by the start and.
   * @return {HTMLElement} creates element by the range.
   * @private
   */
  static createTextElementByRange(renderer, start, end, text) {
    return renderer.createText(text.substring(start, end));
  }
  /**
   * The method creates highlight element as element for DOM.
   * @param renderer edits DOM structure.
   * @param start index of beginning the text, including.
   * @param end index of ending the text, excluding.
   * @param text input text trimmed by the start and.
   * @param highlightStyle optional style to use for text highlighting
   * @return {HTMLElement} creates element by the range.
   * @private
   */
  static createHighlightTextElementByRange(renderer, start, end, text, highlightStyle) {
    const highlightElement = AcceleratorUtils.createTextElementByHighlightStyle(renderer, highlightStyle);
    renderer.appendChild(highlightElement, AcceleratorUtils.createTextElementByRange(renderer, start, end, text));
    return highlightElement;
  }
  /**
   * The method creates svg underline element and append to the input element as child.
   * @param renderer edits DOM structure.
   * @param element parent element where appends an underline element
   * @return {HTMLElement} returns an element with svg underline
   * @private
   */
  static createUnderlineSvgElement(renderer, element) {
    const underscore = renderer.createElement('span');
    renderer.setStyle(underscore, 'position', 'relative');
    const svg = this.createUnderlineSvg(renderer);
    const line = this.createUnderlineLine(element);
    renderer.appendChild(svg, line);
    renderer.appendChild(underscore, svg);
    return underscore;
  }
  /**
   * The method creates an HTML element containing element with highlighting or not, depends on input boolean value.
   * @param renderer edits DOM structure.
   * @param element parent element where appends an underline element.
   * @param char adds as content in result element.
   * @param highlightRange optional range to highlight.
   * @return {HTMLElement} returns element with appending element as element input.
   * @private
   */
  static appendHighlightHtmlElement(renderer, element, char, highlightRange) {
    let el;
    const textElement = renderer.createText(char || '');
    if (highlightRange) {
      el = AcceleratorUtils.createTextElementByHighlightStyle(renderer, highlightRange.highlightStyle);
      renderer.appendChild(el, textElement);
    } else {
      el = textElement;
    }
    renderer.appendChild(element, el);
    return element;
  }
  /**
   * The method creates an HTML element based on provided style.
   * Returns `strong` element by default.
   * @param renderer edits DOM structure.
   * @param highlightStyle style to use
   * @private
   */
  static createTextElementByHighlightStyle(renderer, highlightStyle) {
    let resolvedElement = 'strong';
    if (highlightStyle === TextStyle.Italic) {
      resolvedElement = 'i';
    }
    return renderer.createElement(resolvedElement);
  }
}
export { AcceleratorUtils };
