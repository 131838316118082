import { DEFAULT_ILAYOUT_CONFIG, Orientation } from './dynamic-form-layout.interface.mjs';
import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

// endregion
//region CLASSES
class DynamicFormItemBase {
  /**
   * sets the isWrapped and hasRightBorderStickedToParent properties to inneritems
   */
  static setFlagsToInnerItems(parentColSpan, innerItems, orientation) {
    let childrenColSpanTotal = 0;
    let rightBorderColumnPosition = 0;
    for (let i = 0; i < innerItems.length; i++) {
      const item = innerItems[i];
      if (orientation === Orientation.Column) {
        item.isWrapped = i !== 0;
        item.hasRightColumnStickedToParent = item.colSpan === parentColSpan;
      } else {
        childrenColSpanTotal += item.colSpan;
        item.isWrapped = childrenColSpanTotal > parentColSpan;
        rightBorderColumnPosition += item.colSpan;
        // reset the position to next row
        rightBorderColumnPosition > parentColSpan && (rightBorderColumnPosition = item.colSpan);
        // once the position is equal to parent colSpan then it is sticked to parent
        item.hasRightColumnStickedToParent = rightBorderColumnPosition === parentColSpan;
      }
    }
  }
  constructor(layoutName, layout, logger) {
    this.layoutName = layoutName;
    this.logger = logger;
    this.colSpanLock = false;
    this.minColSpanLock = false;
    this.maxColSpanLock = false;
    this.isWrapped = false;
    this.hasRightColumnStickedToParent = true;
    if (_.isNil(layout)) {
      return;
    }
    if (!_.isNil(layout.customClass)) {
      this.customClass = layout.customClass;
    }
    if (!_.isNil(layout.colSpan) && (!_.isNil(layout.minColSpan) || !_.isNil(layout.maxColSpan))) {
      //eslint-disable-next-line max-len
      this.logger && this.logger.warn(`Dynamic Form ${layoutName}.colSpan property cannot be set together with minColSpan or maxColSpan. The colSpan property override minColSpan and maxColSpan`);
      layout.minColSpan = undefined;
      layout.maxColSpan = undefined;
    } else if (_.isNil(layout.colSpan)) {
      if (!_.isNil(layout.minColSpan) && _.isNil(layout.maxColSpan)) {
        //eslint-disable-next-line max-len
        this.logger && this.logger.warn(`Dynamic Form ${layoutName}.minColSpan property cannot be set without maxColSpan. The maxColSpan is set to minColSpan`);
        layout.maxColSpan = layout.minColSpan;
      } else if (_.isNil(layout.minColSpan) && !_.isNil(layout.maxColSpan)) {
        //eslint-disable-next-line max-len
        this.logger && this.logger.warn(`Dynamic Form ${layoutName}.maxColSpan property cannot be set without minColSpan. The minColSpan is set to maxColSpan`);
        layout.minColSpan = layout.maxColSpan;
      } else if (!_.isNil(layout.minColSpan) && !_.isNil(layout.maxColSpan) && layout.minColSpan > layout.maxColSpan) {
        //eslint-disable-next-line max-len
        this.logger && this.logger.warn(`Dynamic Form ${layoutName}.maxColSpan property cannot be lesser than minColSpan. The maxColSpan is changed to minColSpan value`);
        layout.maxColSpan = layout.minColSpan;
      }
    }
  }
  /**
   * Method that updates own colSpan
   * param colsChange - negative number means that the item colSpan should be lower and vice versa.
   * returns {number} which indicates the allowed colsChange on that item (and its innerItems).
   */
  updateMyColSpan(colsChange) {
    if (this.colSpan + colsChange > this.maxColSpan) {
      colsChange = this.maxColSpan - this.colSpan;
    } else if (this.colSpan + colsChange < this.minColSpan) {
      colsChange = this.minColSpan - this.colSpan;
    }
    this.colSpan += colsChange;
    return colsChange;
  }
  /**
   * Method that updates colSpan on innerItems.
   * param realColsChangeOnThisLevel - the colsChange that can be processed on innerItems without any unprocessed change
   */
  updateInnerItemsColSpan(orientation, noWrap, realColsChangeOnThisLevel, innerItemsRef) {
    if (orientation === Orientation.Row && noWrap) {
      this.updateNecessaryInnerItemsColSpan(innerItemsRef, realColsChangeOnThisLevel);
    } else {
      this.updateAllInnerItemsDiffColSpan(innerItemsRef);
    }
    DynamicFormItemBase.setFlagsToInnerItems(this.colSpan, innerItemsRef, orientation);
  }
  /**
   * Method that updates colSpan on just some innerItems because the processing colsChange on all items is not needed.
   * It is used for the Row oriented children items WITHOUT WRAP
   * param colsChange
   */
  updateNecessaryInnerItemsColSpan(innerItems, colsChange) {
    const isIncreasing = colsChange > 0;
    if (isIncreasing) {
      for (const item of innerItems) {
        colsChange = item.updateColSpan(colsChange);
      }
    } else {
      // reverse for loop
      const innerItemsLength = innerItems.length;
      for (let index = innerItemsLength - 1; index >= 0; index--) {
        colsChange = innerItems[index].updateColSpan(colsChange);
      }
    }
  }
  /**
   * Method that updates colSpan on all items. It is used for the Column orientation,
   * or Row WITH WRAP, because by wrapping children - each child colSpan can be same as parent (diff is used as updateColSpan param).
   */
  updateAllInnerItemsDiffColSpan(innerItems) {
    for (const item of innerItems) {
      item.updateColSpan(this.colSpan - item.colSpan);
    }
  }
}
/**
 * Main logic for the wrappable items. The classes that inherits need to implement just setInnerItems method,
 * because the innerItems can differ in each type of wrappable item.
 */
class WrappableItemBase extends DynamicFormItemBase {
  constructor(layoutName, layout, logger, parentOrientation) {
    super(layoutName, layout, logger);
    this.layoutName = layoutName;
    this.logger = logger;
    this.parentOrientation = parentOrientation || Orientation.Column;
    if (_.isNil(layout)) {
      layout = {
        orientation: Orientation.Column,
        noWrap: true
      };
    }
    if ((_.isNil(layout.orientation) || layout.orientation === Orientation.Column) && layout.noWrap === false) {
      this.logger && this.logger.warn(`Dynamic Form ${layoutName}.noWrap property cannot be set to false, when  ${layoutName}.orientation property is set to column.
Setting noWrap property to "true".`);
      layout.noWrap = true;
    }
    if (!_.isNil(layout.colSpan) && !_.isInteger(layout.colSpan)) {
      this.logger && this.logger.warn(`Dynamic Form ${layoutName}.colSpan property must be integer. Setting colSpan to maximum.`);
      layout.colSpan = undefined;
    }
    this.orientation = layout.orientation || Orientation.Column;
    this.noWrap = this.orientation === Orientation.Column ? true : layout.noWrap || false;
    this.setInnerItems(layout);
    this.setFlexAndHeight(layout);
    this.setMinAndMaxColSpans(layout);
    this.setColSpanFromLayout(layout);
    DynamicFormItemBase.setFlagsToInnerItems(this.colSpan, this.innerItems, this.orientation);
  }
  /**
   * Method that can be called to update its colSpan (and its innerItems).
   * param colsChange - negative number means that the item colSpan should be lower and vice versa.
   * returns {number} which indicates the remaining change which is not able to be processed on this item.
   */
  updateColSpan(colsChange) {
    if (!_.isInteger(colsChange)) {
      this.logger && this.logger.error(`Dynamic Form ${this.layoutName}.updateColSpan method require colsChange parameter that is integer.`);
      return colsChange;
    }
    if (colsChange === 0 || this.colSpanLock) {
      let innerItemsColSpanTotal = 0;
      for (const item of this.innerItems) {
        innerItemsColSpanTotal += item.colSpan;
      }
      const diff = this.colSpan - innerItemsColSpanTotal;
      this.updateInnerItemsColSpan(this.orientation, this.noWrap, diff, this.innerItems);
      return colsChange;
    }
    const isIncreasing = colsChange > 0;
    const cannotBeChanged = isIncreasing && this.maxColSpan <= this.colSpan || !isIncreasing && this.minColSpan >= this.colSpan;
    if (cannotBeChanged) {
      return colsChange;
    }
    const colsChangeProcessed = this.updateMyColSpan(colsChange);
    this.updateInnerItemsColSpan(this.orientation, this.noWrap, colsChangeProcessed, this.innerItems);
    return colsChange - colsChangeProcessed;
  }
  /**
   * This method should handle the situation when some innerItem changes its dimensions and the current control have locked colSpan
   */
  runTimeUpdate() {
    this.setColSpansToMax();
    let innerItemsColSpanTotal = 0;
    for (const item of this.innerItems) {
      innerItemsColSpanTotal += item.colSpan;
    }
    const diff = this.colSpan - innerItemsColSpanTotal;
    this.updateInnerItemsColSpan(this.orientation, this.noWrap, diff, this.innerItems);
  }
  /**
   * This method checks the innerItems isHeightFilled property and change own isHeightFilled property and fxFlex property when
   * it is needed because of some change on the innerItems level.
   */
  onInnerItemsFilledHeightUpdate() {
    if (!_.isNil(this.flex)) {
      return;
    }
    let newFilledHeight = false;
    for (const item of this.innerItems) {
      if (item.isHeightFilled) {
        newFilledHeight = true;
        break;
      }
    }
    if (this.isHeightFilled !== newFilledHeight) {
      this.isHeightFilled = newFilledHeight;
      this.fxFlex = newFilledHeight && this.parentOrientation === Orientation.Column ? '1 0 auto' : '0 0 auto';
    }
  }
  /**
   * Method that updates this item colSpan to maxColSpan and all InnerItems colSpan to MaxColSpan recursively
   */
  setColSpansToMax() {
    if (this.colSpanLock) {
      return;
    }
    this.colSpan = this.maxColSpan;
    for (const item of this.innerItems) {
      item.setColSpansToMax();
    }
  }
  /**
   * Method that calculates minColSpan and maxColSpan based on the innerItems.
   */
  setMinAndMaxColSpans(colSpanItemLayout) {
    if (this.minColSpanLock && this.maxColSpanLock) {
      return;
    }
    let minColSpan = 0;
    let maxColSpan = 0;
    for (const item of this.innerItems) {
      if (isDynamicFormProperty(item) && !item.isAttached) {
        continue;
      }
      if (this.orientation === Orientation.Column) {
        minColSpan < item.minColSpan && (minColSpan = item.minColSpan);
        maxColSpan < item.maxColSpan && (maxColSpan = item.maxColSpan);
      } else if (this.noWrap) {
        minColSpan += item.minColSpan;
        maxColSpan += item.maxColSpan;
      } else {
        minColSpan < item.minColSpan && (minColSpan = item.minColSpan);
        maxColSpan += item.maxColSpan;
      }
    }
    !_.isNil(colSpanItemLayout) && this.setMinAndMaxColSpansFromLayout(colSpanItemLayout, minColSpan, maxColSpan);
    !this.minColSpanLock && (this.minColSpan = minColSpan);
    !this.maxColSpanLock && (this.maxColSpan = maxColSpan);
  }
  updateDimensions() {
    const isNeedToBeUpdatedOnParent = !this.colSpanLock;
    if (this.colSpanLock) {
      this.runTimeUpdate();
    } else {
      this.setMinAndMaxColSpans();
    }
    return isNeedToBeUpdatedOnParent;
  }
  updateFilledHeight() {
    const previousFilledHeight = this.isHeightFilled;
    this.onInnerItemsFilledHeightUpdate();
    const isNeedToBeUpdatedOnParent = previousFilledHeight !== this.isHeightFilled;
    return isNeedToBeUpdatedOnParent;
  }
  setMinAndMaxColSpansFromLayout(colSpanItemLayout, calculatedMinColSpan, calculatedMaxColSpan) {
    if (!_.isNil(colSpanItemLayout.minColSpan)) {
      if (colSpanItemLayout.minColSpan < calculatedMinColSpan) {
        this.logger &&
        //eslint-disable-next-line max-len
        this.logger.warn(`Dynamic Form ${this.layoutName}.minColSpan is lesser than the sum of its children minColSpans. minColSpan is changed to this sum minColSpan to preserve overflow.`);
        this.minColSpan = calculatedMinColSpan;
        if (!_.isNil(colSpanItemLayout.maxColSpan) && colSpanItemLayout.maxColSpan < this.minColSpan) {
          colSpanItemLayout.maxColSpan = this.minColSpan;
          this.logger && this.logger.warn(`Dynamic Form ${this.layoutName}.maxColSpan is also changed to this sum minColSpan.`);
        }
      } else {
        this.minColSpan = colSpanItemLayout.minColSpan;
      }
      this.minColSpanLock = true;
    }
    if (!_.isNil(colSpanItemLayout.maxColSpan)) {
      if (colSpanItemLayout.maxColSpan > calculatedMaxColSpan) {
        this.logger &&
        //eslint-disable-next-line max-len
        this.logger.info(`Dynamic Form ${this.layoutName}.maxColSpan is greater than the sum of its children maxColSpans. Are you sure you need that empty space ?`);
      }
      this.maxColSpan = colSpanItemLayout.maxColSpan;
      this.maxColSpanLock = true;
    }
  }
  /**
   * Method that set the initial colSpan on this item, based on the layout.colSpan and the minColSpan and maxColSpan.
   * By setting layout.colSpan, this.colSpan is locked and cannot be updated by updateColSpan method.
   * This is done by changing the minColSpan and maxColSpan to the same value (this.colSpan)
   */
  setColSpanFromLayout(layout) {
    if (_.isNil(layout.colSpan)) {
      this.colSpan = this.maxColSpan;
      return;
    }
    if (layout.colSpan < this.minColSpan) {
      this.logger &&
      //eslint-disable-next-line max-len
      this.logger.warn(`Dynamic Form ${this.layoutName}.colSpan is lesser than the sum of its children colSpans. colSpan is changed to minColSpan to preserve overflow.`);
      this.colSpan = this.minColSpan;
    } else {
      //eslint-disable-next-line max-len
      layout.colSpan > this.maxColSpan && this.logger && this.logger.info(`Dynamic Form ${this.layoutName}.colSpan is greater than the sum of its children colSpans. Are you sure you need that empty space ?`);
      this.colSpan = layout.colSpan;
    }
    this.updateInnerItemsColSpan(this.orientation, this.noWrap, this.colSpan - this.maxColSpan, this.innerItems);
    this.minColSpan = this.colSpan;
    this.maxColSpan = this.colSpan;
    this.colSpanLock = true;
    this.minColSpanLock = true;
    this.maxColSpanLock = true;
  }
  setFlexAndHeight(layout) {
    if (!_.isNil(layout.flex) && !_.isNil(this.parentOrientation) && this.parentOrientation === Orientation.Row) {
      this.logger && this.logger.warn(`Dynamic Form ${this.layoutName}.flex property cannot be set, when  ${this.layoutName}.orientation property is set to row.
Setting flex property to "undefined".`);
      layout.flex = undefined;
    } else {
      this.flex = layout.flex;
    }
    if (!_.isNil(this.flex)) {
      const grow = !_.isNil(this.flex.grow) ? this.flex.grow : '1';
      const basis = !_.isNil(this.flex.basis) ? this.flex.basis : 'auto';
      this.fxFlex = `${grow} 0 ${basis}`;
      this.isHeightFilled = true;
    } else {
      this.fxFlex = '0 0 auto';
      this.isHeightFilled = false;
    }
    if (!this.isHeightFilled) {
      for (const item of this.innerItems) {
        if (item.isHeightFilled) {
          this.isHeightFilled = true;
          this.fxFlex = this.orientation === Orientation.Column ? '1 0 auto' : '0 0 auto';
          break;
        }
      }
    }
  }
}
/**
 * Represents the InnerSchema item in the view by defined schema, layout and value from the Dynamic Form Component
 */
class DynamicFormInnerSchemaItem {
  constructor(innerSchemaLayout, logger) {
    this.logger = logger;
    this.value = innerSchemaLayout.value;
    // For now, this can be only Column
    if (innerSchemaLayout.orientationToProperty === Orientation.Row) {
      this.logger && this.logger.warn('Dynamic Form InnerSchema.orientationToProperty cannot be set to row.');
    }
    this.orientationToProperty = Orientation.Column;
    this.group = new DynamicFormGroup(innerSchemaLayout.group, this.logger); //tslint:disable-line no-use-before-declare
  }
}
/**
 * Represents the Property item in the view by defined schema, layout and value from the Dynamic Form Component
 */
class DynamicFormProperty extends DynamicFormItemBase {
  set currentInnerSchemaItem(innerSchemaItem) {
    this._currentInnerSchemaItem = innerSchemaItem;
    if (innerSchemaItem && innerSchemaItem.group) {
      this.group = innerSchemaItem.group;
      this.fxFlex = this.group.isHeightFilled ? '1 0 auto' : '0 0 auto';
      this.isHeightFilled = this.group.isHeightFilled;
    } else {
      this.group = undefined;
      this.fxFlex = '0 0 auto';
      this.isHeightFilled = false;
      this.colSpan = this.initialColSpan;
    }
    this.setMinAndMaxColSpans();
  }
  get currentInnerSchemaItem() {
    return this._currentInnerSchemaItem;
  }
  constructor(controlLayout, logger, parentOrientation) {
    super(`IControlLayout("${controlLayout.schemaPropName}")`, controlLayout, logger);
    this.logger = logger;
    /**
     * Determines when the property is displayed in Dynamic Form
     */
    this.isAttached = true;
    this.parentOrientation = parentOrientation || Orientation.Column;
    this.schemaPropName = controlLayout.schemaPropName;
    this.listOrientation = Orientation.Column;
    const arrOpts = controlLayout.arrayOptions;
    const accordionOptionsDefault = {
      isHidden: false,
      isCollapsed: false,
      focusable: false,
      disableCapitalization: false,
      disableToggle: false
    };
    if (!_.isNil(arrOpts)) {
      this.listOrientation = arrOpts.listOrientation || Orientation.Column;
      this.arrayOptions = {
        chipsDisabled: arrOpts.chipsDisabled || false,
        chipsLabel: arrOpts.chipsLabel,
        listOrientation: this.listOrientation,
        noWrap: this.listOrientation === Orientation.Column ? true : arrOpts.noWrap || false,
        accordionOptions: arrOpts.accordionOptions || accordionOptionsDefault,
        editableWithoutChips: arrOpts.chipsDisabled && arrOpts.editableWithoutChips || false,
        inheritEmptyArrayMessageColSpan: arrOpts.inheritEmptyArrayMessageColSpan || false
      };
    } else {
      this.arrayOptions = {
        chipsDisabled: false,
        listOrientation: this.listOrientation,
        noWrap: this.listOrientation === Orientation.Column ? true : false,
        accordionOptions: accordionOptionsDefault,
        editableWithoutChips: false
      };
    }
    this.setFlexAndHeight(controlLayout);
    if (!_.isNil(controlLayout.group)) {
      //tslint:disable-next-line no-use-before-declare
      this.group = new DynamicFormGroup(controlLayout.group, this.logger, this.listOrientation);
      this.isHeightFilled = this.group.isHeightFilled;
      this.fxFlex = this.isHeightFilled ? '1 0 auto' : '0 0 auto';
      if (controlLayout.flex) {
        this.logger &&
        //eslint-disable-next-line max-len
        this.logger.warn(`Dynamic Form ControlLayout.flex property cannot be set, when ControlLayout.group.flex property is defined. Please specify the ControlLayout.group.flex.`);
      }
    }
    this.setMinAndMaxColSpans(controlLayout);
    this.setColSpanFromLayout(controlLayout);
    if (!_.isNil(controlLayout.innerSchema)) {
      this.innerSchema = [];
      for (const schema of controlLayout.innerSchema) {
        // process the innerSchemaLayout.colSpan to group
        if (!_.isNil(controlLayout.colSpan)) {
          if (!_.isNil(schema && schema.group && schema.group.colSpan)) {
            this.logger &&
            //eslint-disable-next-line max-len
            this.logger.warn('Dynamic Form IControlLayout.colSpan cannot be defined together with IControlLayout.InnerSchema.group.colSpan (this will take precedence).');
          } else if (schema.group) {
            schema.group.colSpan = controlLayout.colSpan;
          } else {
            schema.group = {
              colSpan: controlLayout.colSpan
            };
          }
        }
        this.innerSchema.push(new DynamicFormInnerSchemaItem(schema, this.logger));
      }
    }
    this.initialColSpan = this.colSpan;
  }
  /**
   * This method handles the logic of changing colSpan of the current ControlLayout and its children
   * (in case the group property is defined)
   * and returns the remaining colsChange, that is needed to be processed.
   */
  updateColSpan(colsChange) {
    if (!_.isInteger(colsChange)) {
      this.logger && this.logger.error('Dynamic Form ControlLayout.updateColSpan method require colsChange parameter that is integer.');
      return colsChange;
    }
    if (colsChange === 0 || !this.isAttached) {
      return colsChange;
    }
    if (!this.group || this.arrayProperties) {
      const processedColsChange = this.updateMyColSpan(colsChange);
      this.arrayProperties && this.updateInnerItemsColSpan(this.arrayOptions.listOrientation, this.arrayOptions.noWrap, processedColsChange, this.arrayProperties);
      return colsChange - processedColsChange;
    }
    const remainingColsChange = this.group.updateColSpan(colsChange);
    this.colSpan = this.group.colSpan;
    this.minColSpan = this.group.minColSpan;
    this.maxColSpan = this.group.maxColSpan;
    return remainingColsChange;
  }
  /**
   * Method that updates this item colSpan to maxColSpan and all InnerItems colSpan to MaxColSpan recursively
   */
  setColSpansToMax() {
    if (this.colSpanLock) {
      if (this.arrayOptions && this.arrayOptions.chipsDisabled) {
        this.colSpan = this.maxColSpan;
      }
      return;
    }
    this.colSpan = this.maxColSpan;
    if (this.group) {
      this.group.setColSpansToMax();
    }
  }
  /**
   * Method that calculates minColSpan and maxColSpan based on the group or own colSpan.
   */
  setMinAndMaxColSpans(colSpanItemLayout) {
    if (this.group) {
      this.minColSpan = this.group.minColSpan;
      this.maxColSpan = this.group.maxColSpan;
      return;
    }
    if (!_.isNil(colSpanItemLayout)) {
      if (!_.isNil(colSpanItemLayout.minColSpan)) {
        this.minColSpan = colSpanItemLayout.minColSpan;
        this.minColSpanLock = true;
      }
      if (!_.isNil(colSpanItemLayout.maxColSpan)) {
        this.maxColSpan = colSpanItemLayout.maxColSpan;
        this.maxColSpanLock = true;
      }
    }
    if (!this.minColSpanLock) {
      const defaultColSpan = 1;
      this.minColSpan = !_.isNil(this.colSpan) ? this.colSpan : defaultColSpan;
    }
    if (!this.maxColSpanLock) {
      this.maxColSpan = this.minColSpan;
    }
  }
  updateDimensions() {
    this.group && !this.arrayOptions.chipsDisabled && this.setMinAndMaxColSpans();
    // this need to be allways updated on parent so it return only true
    return true;
  }
  updateFilledHeight() {
    const previousFilledHeight = this.isHeightFilled;
    if (this.group) {
      this.isHeightFilled = this.group.isHeightFilled;
      this.fxFlex = this.isHeightFilled ? '1 0 auto' : '0 0 auto';
    }
    const isNeedToBeUpdatedOnParent = previousFilledHeight !== this.isHeightFilled;
    return isNeedToBeUpdatedOnParent;
  }
  setColSpanFromLayout(controlLayout) {
    if (this.group) {
      this.minColSpan = this.group.minColSpan;
      this.maxColSpan = this.group.maxColSpan;
      this.colSpan = this.group.colSpan;
      if (controlLayout.colSpan) {
        this.logger &&
        //eslint-disable-next-line max-len
        this.logger.warn(`Dynamic Form ControlLayout.colSpan property cannot be set, when ControlLayout.group property is defined. Please specify the ControlLayout.group.colSpan.`);
      }
      return;
    }
    if (!controlLayout.colSpan) {
      this.colSpan = this.maxColSpan;
    } else if (!_.isInteger(controlLayout.colSpan)) {
      this.logger && this.logger.warn(`Dynamic Form ControlLayout.colSpan property must be integer. Setting colSpan to ${this.maxColSpan}.`);
      this.colSpan = this.maxColSpan;
    } else {
      this.colSpan = controlLayout.colSpan;
      this.minColSpan = this.colSpan;
      this.maxColSpan = this.colSpan;
      this.colSpanLock = true;
    }
  }
  setFlexAndHeight(controlLayout) {
    if (!_.isNil(controlLayout.flex) && !_.isNil(this.parentOrientation) && this.parentOrientation === Orientation.Row) {
      this.logger && this.logger.warn(`Dynamic Form ${controlLayout}.flex property cannot be set, when  ${controlLayout}.orientation property is set to row.
Setting flex property to "undefined".`);
      controlLayout.flex = undefined;
    } else {
      this.flex = controlLayout.flex;
    }
    if (!_.isNil(this.flex)) {
      const grow = !_.isNil(this.flex.grow) ? this.flex.grow : '1';
      const basis = !_.isNil(this.flex.basis) ? this.flex.basis : 'auto';
      this.fxFlex = `${grow} 0 ${basis}`;
      this.isHeightFilled = true;
    } else {
      this.fxFlex = '0 0 auto';
      this.isHeightFilled = false;
    }
  }
}
/**
 * Represents the Area item in the view by defined schema, layout and value from the Dynamic Form Component
 */
class DynamicFormArea extends WrappableItemBase {
  constructor(areaLayout, logger, parentOrientation) {
    super('AreaLayout', areaLayout, logger, parentOrientation);
    this.area = 'area';
    this.disableControlsSpacing = areaLayout?.disableControlsSpacing || false;
    this.title = areaLayout?.title;
    this.description = areaLayout?.description;
  }
  setInnerItems(layout) {
    this.innerItems = [];
    if (!_.isNil(layout.controls) && !_.isEmpty(layout.controls)) {
      for (const control of layout.controls) {
        this.innerItems.push(new DynamicFormProperty(control, this.logger, this.orientation));
      }
    } else if (!_.isNil(layout.areas) && !_.isEmpty(layout.areas)) {
      for (const area of layout.areas) {
        this.innerItems.push(new DynamicFormArea(area, this.logger, this.orientation));
      }
    } else {
      throw new Error(`Dynamic Form AreaLayout.controls || AreaLayout.areas are not present.`);
    }
  }
}
/**
 * Represents the Category item in the view by defined schema, layout and value from the Dynamic Form Component
 */
class DynamicFormCategory extends WrappableItemBase {
  constructor(categoryLayout, logger, parentOrientation) {
    super('CategoryLayout', categoryLayout, logger, parentOrientation);
    this.title = categoryLayout?.title;
    this.description = categoryLayout?.description;
    this.accordionOptions = categoryLayout?.accordionOptions || {
      isCollapsed: false,
      isHidden: false,
      disableCapitalization: false,
      disableToggle: false,
      focusable: false
    };
    if (categoryLayout && categoryLayout.categoryName === true) {
      this.logger && this.logger.warn('Dynamic Form CategoryLayout.categoryName should be undefined, string or false value, but true has been passed ' + '- undefined (default) categoryName will be used.');
      categoryLayout.categoryName = undefined;
    }
    this.categoryName = _.isNil(categoryLayout) ? undefined : !_.isBoolean(categoryLayout.categoryName) ? categoryLayout.categoryName : false;
  }
  setInnerItems(layout) {
    this.innerItems = [];
    if (!_.isNil(layout.areas) && !_.isEmpty(layout.areas)) {
      for (const area of layout.areas) {
        this.innerItems.push(new DynamicFormArea(area, this.logger, this.orientation));
      }
    } else {
      throw new Error(`Dynamic Form CategoryLayout.areas are not present.`);
    }
  }
}
/**
 * Represents the Group item in the view by defined schema, layout and value from the Dynamic Form Component
 */
class DynamicFormGroup extends WrappableItemBase {
  constructor(groupLayout, logger, parentOrientation) {
    super('GroupLayout', groupLayout, logger, parentOrientation);
    this.accordionOptions = groupLayout?.accordionOptions || {
      isCollapsed: false,
      isHidden: false,
      disableCapitalization: false,
      disableToggle: false,
      focusable: false
    };
  }
  setInnerItems(groupLayout) {
    this.innerItems = [];
    if (!_.isNil(groupLayout.categories) && !_.isEmpty(groupLayout.categories)) {
      for (const category of groupLayout.categories) {
        this.innerItems.push(new DynamicFormCategory(category, this.logger, this.orientation));
      }
    } else {
      throw new Error(`Dynamic Form GroupLayout.categories are not present.`);
    }
  }
}
/**
 * Represents the Main Dynamic Form Config with all items in the view by defined schema, layout and value from the Dynamic Form Component
 */
class LayoutConfig {
  constructor(layoutConfig, logger) {
    this.logger = logger;
    if (layoutConfig.maxCols < 1 || !_.isInteger(layoutConfig.maxCols)) {
      this.logger && this.logger.warn(`Dynamic Form ILayoutConfig.maxCols property have to be integer greater or equal to 1.
Setting to ${DEFAULT_ILAYOUT_CONFIG.maxCols} as default`);
      layoutConfig.maxCols = DEFAULT_ILAYOUT_CONFIG.maxCols;
    }
    this.maxCols = layoutConfig.maxCols;
    if (layoutConfig.minColSizePx <= 0) {
      this.logger && this.logger.warn(`Dynamic Form ILayoutConfig.minColSizePx property have to greater than 0.
Setting to ${DEFAULT_ILAYOUT_CONFIG.minColSizePx} as default`);
      layoutConfig.minColSizePx = DEFAULT_ILAYOUT_CONFIG.minColSizePx;
    }
    this.minColSizePx = layoutConfig.minColSizePx;
    if (!_.isNil(layoutConfig.maxColSizePx)) {
      if (layoutConfig.maxColSizePx >= this.minColSizePx) {
        this.maxColSizePx = layoutConfig.maxColSizePx;
      } else {
        this.logger && this.logger.warn(`Dynamic Form ILayoutConfig.maxColSizePx property have to greater than ILayoutConfig.minColSizePx.
Setting to ${DEFAULT_ILAYOUT_CONFIG.maxColSizePx} as default`);
        this.maxColSizePx = DEFAULT_ILAYOUT_CONFIG.maxColSizePx;
      }
    }
    this.group = new DynamicFormGroup(layoutConfig.group, this.logger, Orientation.Column);
  }
}
//endregion
// region TYPEGUARDS
function isDynamicFormPropertyItem(
//eslint-disable-next-line @typescript-eslint/no-explicit-any
property) {
  return TypeUtils.isObject(property) && property.hasOwnProperty('schemaPropName') && property.hasOwnProperty('colSpan') && property.hasOwnProperty('minColSpan') && property.hasOwnProperty('maxColSpan') && _.isFunction(property.updateColSpan);
}
function isDynamicFormProperty(
//eslint-disable-next-line @typescript-eslint/no-explicit-any
property) {
  return isDynamicFormPropertyItem(property);
}
// endregion

export { DynamicFormArea, DynamicFormCategory, DynamicFormGroup, DynamicFormInnerSchemaItem, DynamicFormItemBase, DynamicFormProperty, LayoutConfig, WrappableItemBase, isDynamicFormProperty, isDynamicFormPropertyItem };
