import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

/**
 * Accessor of Angular FormControl which provides extended functionality.
 */
class RaUiFormControl extends FormControl {
  constructor() {
    super(...arguments);
    /**
     * Changes of the dirty state.
     */
    this.dirtyChanges = new Subject();
    /**
     * Changes of the touched state.
     */
    this.touchedChanges = new Subject();
  }
  /**
   * Marks the control as dirty.
   */
  markAsDirty(opts) {
    super.markAsDirty(opts);
    this.dirtyChanges.next(true);
  }
  /**
   * Marks the control as pristine.
   */
  markAsPristine(opts) {
    super.markAsPristine(opts);
    this.dirtyChanges.next(false);
  }
  /**
   * Marks the control as touched.
   */
  markAsTouched(opts) {
    super.markAsTouched(opts);
    this.touchedChanges.next(true);
  }
  /**
   * Marks the control as untouched.
   */
  markAsUntouched(opts) {
    super.markAsUntouched(opts);
    this.touchedChanges.next(false);
  }
  /**
   * Sets value to the control.
   * fromCode determines whether the new value has been set programmatically or not.
   */
  //eslint-disable-next-line @typescript-eslint/no-explicit-any, unused-imports/no-unused-vars
  setValue(value, options, fromCode) {
    super.setValue(value, options);
  }
}
/**
 * A method checking that ngControl is of type RaUiFormControl.
 */
function isRaUiFormControl(ngControl) {
  return ngControl instanceof RaUiFormControl;
}
export { RaUiFormControl, isRaUiFormControl };
