import * as _ from 'lodash';
import { RaUiInputValidationService } from '../../../../cdk-input-validation/esm2022/lib/ra-ui-input-validation.service.mjs';
import { LoggerUtils } from '../../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
const DEFAULT_ICON_VALUE = '';
class BannerInputValidationService extends RaUiInputValidationService {
  constructor() {
    super(...arguments);
    this._icon = DEFAULT_ICON_VALUE;
  }
  setIcon(icon, compName) {
    if (_.isString(icon)) {
      this._icon = icon;
    } else {
      this._icon = DEFAULT_ICON_VALUE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'icon', icon, 'string', this.logger);
    }
  }
}
export { BannerInputValidationService };
