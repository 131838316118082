import { ComponentRef } from '@angular/core';
import { isString } from 'lodash';
import { isTypeTemplateRef, isTypeOrObservable } from '../../../cdk-types/esm2022/lib/basic-types/type-guards.mjs';

/**
 * A method that checks whether a value is of type ComponentRef.
 * @param val - value to be checked.
 */
function isTypeComponentRef(val) {
  return val instanceof ComponentRef;
}
/**
 * A method that checks whether a value is of type Content.
 * @param value - value to be checked.
 */
function isTypeContent(val) {
  return isTypeTemplateRef(val) || isTypeComponentRef(val) || isTypeOrObservable(val, isString);
}
export { isTypeComponentRef, isTypeContent };
