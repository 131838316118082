import * as _ from 'lodash';
import { isObservable } from 'rxjs';
import { isTypeOrObservableBoolean } from '../../../../cdk-types/esm2022/lib/basic-types/type-guards.mjs';
import { TypeUtils } from '../../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

/**
 * Checks whether a value is of type IInnerButton.
 * @param val - value to be checked
 */
function isIInnerButton(val) {
  return TypeUtils.isObjectValid(val, [{
    name: 'label',
    checkFunction: v => {
      return _.isString(v);
    }
  }, {
    name: 'icon',
    checkFunction: v => {
      return _.isString(v);
    }
  }, {
    name: 'disabled',
    checkFunction: v => {
      return isTypeOrObservableBoolean(v);
    }
  }, {
    name: 'tabIndex',
    checkFunction: v => {
      return _.isNumber(v);
    }
  }, {
    name: 'tooltipText',
    checkFunction: v => {
      return _.isString(v);
    }
  }]);
}
/**
 * Checks whether a value is of type IInnerButton[].
 * @param val - value to be checked
 */
function isIInnerButtons(val) {
  return TypeUtils.isArrayOf(val, item => {
    return isIInnerButton(item);
  });
}
/**
 * Checks whether a value is of type InnerButtonsData.
 * @param val - value to be checked
 */
function isInnerButtonsData(val) {
  return !_.isNil(val) && (isIInnerButtons(val) || isObservable(val));
}
export { isIInnerButton, isIInnerButtons, isInnerButtonsData };
