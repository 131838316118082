import { strEnum } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';

/**
 * Position of truncate
 */
const TruncatePosition = strEnum(['right', 'left', 'center']);
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTruncatePosition(val) {
  return val === TruncatePosition.left || val === TruncatePosition.right || val === TruncatePosition.center;
}
export { TruncatePosition, isTruncatePosition };
