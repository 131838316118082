import { takeUntil, first } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { SelectUtils } from '../select-utils.mjs';
import { StringUtils } from '../../../../cdk-utils/esm2022/lib/string-utils/string-utils.mjs';
class SelectMenuBuilder {
  constructor() {
    this.valueChanged$ = new Subject();
    this.menuPositionChanged$ = new Subject();
    this.isFocusedChanged$ = new Subject();
    this.shouldPreventFocus$ = new BehaviorSubject(false);
  }
  withInstance(val) {
    this.instance = val;
    return this;
  }
  withDevice(val) {
    this.device = val;
    return this;
  }
  withChangeRef(val) {
    this.changeRef = val;
    return this;
  }
  withOverlayRef(val) {
    this.overlayRef = val;
    return this;
  }
  withInnerSelect(val) {
    this.innerSelect = val;
    return this;
  }
  withDestroyed(val) {
    this.destroyed = val;
    return this;
  }
  withOnOpen(val) {
    this.onOpen = val;
    return this;
  }
  withOnClose(val) {
    this.onClose = val;
    return this;
  }
  withMenuHeight(val) {
    this.menuHeight = val;
    return this;
  }
  withSelectedIndex(val) {
    this.selectedIndex = val;
    return this;
  }
  withMaxItems(val) {
    this.maxItems = val;
    return this;
  }
  withItems(val) {
    this.instance._items = val;
    return this;
  }
  withMenuWidth(val) {
    this.instance._menuWidth = val;
    return this;
  }
  withMenuControl(val) {
    this.instance._menuControl = val;
    return this;
  }
  withTriggerWidth(val) {
    this.instance._triggerWidth = val;
    return this;
  }
  withCustomItemHeight(val) {
    this.instance._customItemHeight = val;
    return this;
  }
  withTranslateParams(val) {
    this.instance._translateParams = val;
    return this;
  }
  withMenuItemTemplate(val) {
    this.instance._menuItemTemplate = val;
    return this;
  }
  menuTriggerChange(openedByKeyboard) {
    this.instance._menuTriggerChange.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.subscribeForObservables();
      this.openMenu(openedByKeyboard);
      this.setMenuContainer();
      this.menuPositionChanged();
      this.menuFocusItem(openedByKeyboard);
    });
    return this;
  }
  build() {
    return this.instance;
  }
  /**
   * This method will shift the menu from left side with specific value in px
   * @param left the value in px from right indent
   */
  updatePosition(left) {
    const el = SelectUtils.getMenuParentElementByClass(this.overlayRef, this.instance?._uniqueId);
    if (el) {
      el.style.left = StringUtils.numberToPx(left);
    }
  }
  openMenu(openedByKeyboard) {
    this.instance && this.instance._menuRef.openMenu(openedByKeyboard);
  }
  menuFocusItem(openedByKeyboard) {
    this.instance && this.instance._menuRef._focusItem(this.selectedIndex, this.maxItems, openedByKeyboard);
  }
  menuPositionChanged() {
    const menuEl = SelectUtils.getMenuElementByClass(this.overlayRef, this.instance?._uniqueId);
    if (menuEl) {
      this.menuWidth = menuEl.getBoundingClientRect().width;
      this.menuPositionChanged$.next(this.menuWidth);
    }
  }
  setMenuContainer() {
    const el = SelectUtils.getMenuParentElementByClass(this.overlayRef, this.instance?._uniqueId);
    if (el) {
      const menuHeight = StringUtils.numberToPx(SelectUtils.getMenuCustomHeight(this.menuHeight));
      el.style.height = menuHeight;
      el.style.maxHeight = menuHeight;
      el.style.minHeight = menuHeight;
    }
  }
  subscribeMenuOpened() {
    this.instance._menuOpened.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.onOpen.emit();
    });
  }
  subscribeAnimationDone() {
    this.instance._animationDone.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.menuPositionChanged();
    });
  }
  subscribeMenuClosed() {
    this.instance._menuClosed.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.onClose.emit();
      SelectUtils.destroyOverlay(this.overlayRef);
    });
  }
  subscribeFocusIn() {
    this.instance._focusIn.pipe(takeUntil(this.destroyed)).subscribe(() => {
      /**
       * Unlike in _focusOut subscribe, we don't need to explicitly set _isFocus here
       * because FormControlBase handles it itself.
       */
      this.innerSelect?.focus();
    });
  }
  subscribeFocusOut() {
    this.instance._focusOut.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.isFocusedChanged$.next(false);
    });
  }
  subscribeKeyFocusOut() {
    this.instance._keyFocusOut.pipe(takeUntil(this.destroyed)).subscribe(event => {
      if (event === 'tab') {
        this.shouldPreventFocus$.next(true);
      }
      // We need to ensure that focus change happens when select menu is really closed
      // to prevent execution of focus out logic in menu component before it closes
      this.instance._menuClosed.pipe(first(), takeUntil(this.destroyed)).subscribe(() => {
        this.innerSelect?.focus();
        this.shouldPreventFocus$.next(false);
      });
    });
  }
  subscribeMenuClicked() {
    this.instance._menuClicked.pipe(takeUntil(this.destroyed)).subscribe(clickedItem => {
      this.valueChanged$.next(clickedItem.value);
    });
  }
  subscribeForObservables() {
    this.subscribeMenuOpened();
    this.subscribeMenuClosed();
    this.subscribeFocusIn();
    this.subscribeFocusOut();
    this.subscribeKeyFocusOut();
    this.subscribeMenuClicked();
    this.subscribeAnimationDone();
  }
}
export { SelectMenuBuilder };
