@if ((authService.isAuthenticated$ | async) === false) {
  <lib-landing (signInClick)="signInClicked()"></lib-landing>
}
@if (userLoggedIn$ | async) {
  <div class="app-container">
    @if ((userId$ | async) && (userInfo$ | async)) {
    }
    <lib-common-mat-navbar
      #commonNavbar
      [displaySlideToggleTheme]="false"
      [initialTheme]="logoTypeTheme"
      [logotypeClass]="logoTypeThemeClass"
      [isAuthenticated]="(authService.isAuthenticated$ | async) || false"
      (raLogoClick)="goToHome()"
      (goToHomeClick)="goToHome()"
      (menuItemClick)="menuItemClick($event)"
      [enableUserActionsSideNav]="true"
      [navItems]="(navItems$ | async) || []"
      [enableUserProfile]="true"
      [accountMenu]="userInfo"
      [bannerItems]="bannerItems"
      (logoutClick)="logoutClicked()"
      >
      <div class="app-content-container">
        <div class="main-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </lib-common-mat-navbar>
  </div>
}

@if (isLoading$ | async) {
  <app-spinner></app-spinner>
}
<app-snackbar></app-snackbar>

@if (sessionTimedOut$ | async) {
}
@if (countDownTimer$ | async) {
}
