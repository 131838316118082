import * as _ from 'lodash';

/**
 * A method that checkes whether a value is of type IDisplayType.
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDisplayType(val) {
  return _.isNil(val) ? false : val.hasOwnProperty('id') && val.isInputValidFn !== undefined;
}
export { isDisplayType };
