import { InjectionToken } from '@angular/core';

/* eslint-disable max-classes-per-file */
/**
 * The class generates new ID by incrementing index starting with 1.
 * You can override the method 'getIdBasedOnCurrentIndex' and call the method 'next' with new ID.
 */
class IdGeneratorBase {
  /**
   * The abstract class constructor.
   * @param prefix Prefix that is always added in front of new ID.
   * @param minLength If generated string with prefix is shorter than this number, remaining places are filled with zeroes.
   */
  constructor(prefix, minLength) {
    this.prefix = prefix;
    this.minLength = minLength;
    this._currentIndex = 0;
  }
  /**
   * The method returning new generated ID.
   */
  next() {
    this._currentIndex++;
    const nextIncrement = this.getIdBasedOnCurrentIndex();
    const emptyCharsCount = this.getSizeOfPadding(nextIncrement);
    return this.prefix + '0'.repeat(emptyCharsCount) + nextIncrement;
  }
  /**
   * Current numeric index.
   */
  getCurrentIndex() {
    return this._currentIndex;
  }
  getSizeOfPadding(st) {
    const diff = this.minLength - (st.length + this.prefix.length);
    return diff > 0 ? diff : 0;
  }
}
/**
 * Generates new IDs by converting number to string by base36
 */
class IdGeneratorBase36 extends IdGeneratorBase {
  constructor(prefix, minLength) {
    super(prefix, minLength);
  }
  getIdBasedOnCurrentIndex() {
    return this.getCurrentIndex().toString(36);
  }
}
const ID_GENERATOR_TOKEN = new InjectionToken('Default generator', {
  providedIn: 'root',
  factory: () => {
    return new IdGeneratorBase36('a', 8);
  }
});
export { ID_GENERATOR_TOKEN, IdGeneratorBase, IdGeneratorBase36 };
