class StringUtils {
  /**
   * Method converts number value to string with px
   * @param val number to be converted
   */
  static numberToPx(val) {
    return `${val}px`;
  }
  /**
   * Method converts string value with px to number value
   * @param value string to be converted
   */
  static pxToNumber(value) {
    return parseInt(value, 10) || 0;
  }
  /**
   * Method removes wrapping double quotes from string only if they are both present.
   * @param value string value to be processed
   */
  static removeWrappingDoubleQuotes(value) {
    // if first and last char is double quote (") remove it.
    return StringUtils.isWrappedByDoubleQuotes(value) ? value.substring(1, value.length - 1) : value;
  }
  /**
   * Method checks whether string is wrapped by double quotes (first and last char is: ").
   * @param value string text to be checked
   */
  static isWrappedByDoubleQuotes(value) {
    return value.length > 1 && value.charAt(0) === '"' && value.charAt(value.length - 1) === '"';
  }
}
export { StringUtils };
