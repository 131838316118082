import * as _ from 'lodash';

/**
 * Version Input possible errors in a form.
 */
class VersionInputErrors {}
class VersionInputValidators {
  static {
    this.VERSION_SUBREGEX_BASE = '[0-9]';
  }
  static {
    this.VERSION_SUBREGEX = `(${VersionInputValidators.VERSION_SUBREGEX_BASE}{1,3})`;
  }
  /**
   * Gets regular expression of a single cell.
   */
  static getVersionInputInputCellRegex() {
    return `^${VersionInputValidators.VERSION_SUBREGEX}$`;
  }
  /**
   * Gets regular expression of a single cell with dynamic max length
   */
  static getCustomLengthNumberRegex(max) {
    return `^(${VersionInputValidators.VERSION_SUBREGEX_BASE}{1,${max}})$`;
  }
  /**
   * Gets regular expression of the whole Version input. ^((([0-9]){1,3})\.){2}(([0-9]){1,})
   */
  static getVersionInputRegex() {
    return `^(${VersionInputValidators.VERSION_SUBREGEX}\\.){2}(${VersionInputValidators.VERSION_SUBREGEX_BASE}{1,})$`;
  }
  /**
   * Gets regular expression of the patch part of Version input.
   */
  static getPatchMaxLengthVersionInputRegex(max) {
    return `\\.(${VersionInputValidators.VERSION_SUBREGEX_BASE}{1,${max}})$`;
  }
  /**
   * Method to determine if the value is not empty and is a valid Version input
   */
  static required(control) {
    let hasErrors = false;
    if (_.isNil(control.value) || !_.isString(control.value)) {
      hasErrors = true;
    } else {
      hasErrors = !control.value.match(new RegExp(VersionInputValidators.getVersionInputRegex()));
    }
    //eslint-disable-next-line no-null/no-null
    return hasErrors ? {
      required: true
    } : null;
  }
  static patchMaxLength(maxLength) {
    return control => {
      if (_.isNil(control.value) || !_.isString(control.value)) {
        // eslint-disable-next-line no-null/no-null
        return null;
      }
      const regex = new RegExp(VersionInputValidators.getPatchMaxLengthVersionInputRegex(maxLength));
      // eslint-disable-next-line no-null/no-null
      return control.value.match(regex) ? null : {
        patchMaxLength: true
      };
    };
  }
  /**
   * Method to determine, if the value satisfies the requirements (min, max, range)
   */
  static valid(rules) {
    const fn = control => {
      const errors = new VersionInputErrors();
      if (!_.isNil(control.value)) {
        rules.forEach(rule => {
          this.validationFunction(control, rule, errors);
        });
      }
      //eslint-disable-next-line no-null/no-null
      return _.keys(errors).length !== 0 ? errors : null;
    };
    return fn;
  }
  static isVersionInRange(version, min, max) {
    return version >= min && version <= max;
  }
  /**
   * Function to convert Version input to a number
   */
  static convertVersionToNumber(version) {
    let result = 0;
    version.split('.').forEach((cell, index) => {
      result += parseInt(cell, 10) * Math.pow(1000, 3 - index);
    });
    return result;
  }
  static validationFunction(control, rule, errors) {
    const versionAsNumber = VersionInputValidators.convertVersionToNumber(control.value);
    let min;
    let max;
    // simple rule (invalid Version Input)
    if (typeof rule === 'string') {
      if (rule.indexOf('*') !== -1) {
        // rule is a mask
        min = VersionInputValidators.convertVersionToNumber(rule.replace(/\*/g, '0'));
        max = VersionInputValidators.convertVersionToNumber(rule.replace(/\*/g, '999'));
        if (VersionInputValidators.isVersionInRange(versionAsNumber, min, max)) {
          errors.invalid = {
            rule: rule
          };
        }
      } else {
        // check if current value is equal to invalid Version Input
        if (control.value === rule) {
          errors.invalid = {
            rule: rule
          };
        }
      }
      // range rule
    } else if (Array.isArray(rule) && rule.length === 2) {
      // rule is a range
      min = VersionInputValidators.convertVersionToNumber(rule[0]);
      max = VersionInputValidators.convertVersionToNumber(rule[1]);
      if (VersionInputValidators.isVersionInRange(versionAsNumber, min, max)) {
        errors.range = {
          min: rule[0],
          max: rule[1]
        };
      }
    }
  }
}
export { VersionInputErrors, VersionInputValidators };
