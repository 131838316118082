import * as _ from 'lodash';
import { StringUtils } from '../string-utils/string-utils.mjs';
import { TypeUtils } from '../../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';
class DomUtils {
  static removeAllChildren(renderer, el) {
    const children = el.childNodes;
    for (let i = children.length - 1; i >= 0; i--) {
      //NOTE: solve this in future - angular DebugRenderer2 is asynchronous and therefore it causes a lot of issues
      // -> we are using native HTML functions
      //renderer.removeChild(el, children[i]);
      el.removeChild(children[i]);
    }
  }
  /**
   * Returns references to all parent elements of an element that matches the className, you can specify `breakOnClass` parameter
   * so the search won't continue when this class has been found.
   */
  static getParentsOfClass(el, className, breakOnClass = '') {
    const result = [];
    let parent = el.parentElement;
    while (parent) {
      if (parent.classList.contains(breakOnClass)) {
        break;
      }
      if (parent.classList.contains(className)) {
        result.push(parent);
      }
      parent = parent.parentElement;
    }
    return result;
  }
  /**
   * Returns sum of element's left and right paddings.
   * @param element
   */
  static getHorizontalPadding(element) {
    const style = window.getComputedStyle(element);
    const paddingLeft = style.paddingLeft ? StringUtils.pxToNumber(style.paddingLeft) : 0;
    const paddingRight = style.paddingRight ? StringUtils.pxToNumber(style.paddingRight) : 0;
    return paddingLeft + paddingRight;
  }
  /**
   * Returns element's width.
   * @param element
   */
  static getWidth(element) {
    return element.getBoundingClientRect().width;
  }
  /**
   * Parses css size property string value and returns object with value and unit.
   * Returns undefined when value is empty or invalid.
   * @param value
   */
  static parseStyleDimensionValue(value) {
    if (!TypeUtils.isStringCssUnitFormat(value)) {
      return undefined;
    }
    const parsedValue = value.match(/^[0-9]*\.?[0-9]+/);
    const parsedUnit = value.match(/(%|cm|mm|in|pc|pt|px|em|ex|rem|lh|rlh|vw|vh|vmin|vmax)$/);
    return !_.isNil(parsedValue) && !_.isNil(parsedUnit) ? {
      value: Number(parsedValue[0]),
      unit: parsedUnit[0]
    } : undefined;
  }
  /**
   * Returns value of the root style property.
   * @param propertyName
   */
  static getRootStylePropValue(propertyName) {
    return getComputedStyle(document.documentElement).getPropertyValue(propertyName);
  }
  /**
   * Returns base font size of the root element as number.
   */
  static getRootFontSize() {
    const {
      value
    } = DomUtils.parseStyleDimensionValue(DomUtils.getRootStylePropValue('font-size'));
    return value;
  }
  /**
   * Returns parsed value of the root style property. It is expected to be used for dimension related
   * style properties, that contains value and unit.
   * Returns undefined when property does not exist or when value is empty or invalid.
   * @param propertyName
   */
  static getParsedRootStylePropValue(propertyName) {
    return DomUtils.parseStyleDimensionValue(DomUtils.getRootStylePropValue(propertyName));
  }
}
export { DomUtils };
