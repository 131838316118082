import { StringUtils } from '../../../../cdk-utils/esm2022/lib/string-utils/string-utils.mjs';

/**
 * A service for the tree component.
 */
class ToastStyleBuilder {
  constructor(height, interval, isAnimationExit) {
    this.height = height;
    this.interval = interval;
    this.isAnimationExit = isAnimationExit;
    this.style = {};
    this.height = height;
    this.interval = interval;
    this.isAnimationExit = isAnimationExit;
  }
  withPositionTopStart() {
    let marginTop = -this.height + -this.interval;
    let marginBottom = 0;
    let opacity = 0;
    if (this.isAnimationExit) {
      marginTop = 0;
      marginBottom = this.interval;
      opacity = 1;
    }
    return this.opacity(opacity).marginBottom(marginBottom).marginTop(marginTop).build();
  }
  withPositionBottomStart() {
    const marginTop = this.interval;
    let marginBottom = -this.height + -this.interval;
    let opacity = 0;
    if (this.isAnimationExit) {
      marginBottom = 0;
      opacity = 1;
    }
    return this.opacity(opacity).marginBottom(marginBottom).marginTop(marginTop).build();
  }
  withPositionTopEnd() {
    let marginTop = 0;
    const marginBottom = this.interval;
    if (this.isAnimationExit) {
      marginTop = -this.height + -this.interval;
    }
    return this.marginBottom(marginBottom).marginTop(marginTop).build();
  }
  withPositionBottomEnd() {
    const marginTop = this.interval;
    let marginBottom = 0;
    if (this.isAnimationExit) {
      marginBottom = -this.height + -this.interval;
    }
    return this.marginTop(marginTop).marginBottom(marginBottom).build();
  }
  withOpacityEnd() {
    let opacity = 1;
    if (this.isAnimationExit) {
      opacity = 0;
    }
    return this.opacity(opacity).build();
  }
  build() {
    return this.style;
  }
  marginTop(val) {
    this.style.marginTop = StringUtils.numberToPx(val);
    return this;
  }
  marginBottom(val) {
    this.style.marginBottom = StringUtils.numberToPx(val);
    return this;
  }
  opacity(val) {
    this.style.opacity = val;
    return this;
  }
}
export { ToastStyleBuilder };
