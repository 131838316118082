import * as _ from 'lodash';
import { isObservable } from 'rxjs';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

/**
 * Checks whether a value is of type IToastButton.
 * @param val - value to be checked
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIToastButton(val) {
  if (_.isNil(val)) {
    return false;
  }
  let disabledCheck = true;
  let tabIndexCheck = true;
  let tooltipTextCheck = true;
  if (val.hasOwnProperty('disabled')) {
    disabledCheck = _.isBoolean(val.disabled) || isObservable(val.disabled);
  }
  if (val.hasOwnProperty('tabIndex')) {
    tabIndexCheck = _.isFinite(val.tabIndex) || _.isUndefined(val.tabIndex);
  }
  if (val.hasOwnProperty('tooltipText')) {
    tooltipTextCheck = _.isString(val.tooltipText);
  }
  return val.hasOwnProperty('label') && _.isString(val.label) && disabledCheck && tabIndexCheck && tooltipTextCheck;
}
/**
 * Checks whether a value is of type ToastButtonData.
 * @param val - value to be checked
 */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isToastButtonData(val) {
  return !_.isNil(val) && (TypeUtils.isArrayOf(val, item => {
    return isIToastButton(item);
  }) || isObservable(val));
}
/**
 * Directive that attaches a toast to the host element. Animates the showing and
 * hiding of a toast provided position (defaults to bottomCenter the element).
 */

export { isIToastButton, isToastButtonData };
