import { FormGroup, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { RaUiFormControl } from './form-control.mjs';
import { RaFormControlChangeNotificationType } from './cdk-form.types.mjs';

/* eslint-disable max-classes-per-file */
/**
 * Accessor of Angular Form Group object which provides extended functionality.
 */
class RaUiFormGroup extends FormGroup {
  constructor(controls, validatorOrOpts, asyncValidator) {
    super(controls, validatorOrOpts, asyncValidator);
    /**
     * Subscribe here for value changes, you will get information about which particular control has changed rather that Angular
     * way providing overall objects.
     */
    this.objectChanges = new Subject();
    this._subscriptions = {};
    Object.keys(controls).forEach(key => {
      this.subscribeControl(key);
    });
  }
  /**
   * Adds new instance of control into the group, overridden.
   * @param name Name of control to add.
   * @param control Control instance.
   */
  addControl(name, control) {
    super.addControl(name, control);
    this.subscribeControl(name);
    this.objectChanges.next({
      controlName: name,
      value: control.value,
      type: RaFormControlChangeNotificationType.Add
    });
  }
  /**
   * Removes control.
   * @param name Name of control to remove.
   */
  removeControl(name) {
    this.unsubscribeControl(name);
    super.removeControl(name);
    this.objectChanges.next({
      controlName: name,
      value: null,
      //eslint-disable-line no-null/no-null
      type: RaFormControlChangeNotificationType.Remove
    });
  }
  /**
   * Replaces specific control.
   * @param name Name of control to replace.
   * @param control Control instance.
   */
  setControl(name, control) {
    this.unsubscribeControl(name);
    super.setControl(name, control);
    this.subscribeControl(name);
    this.objectChanges.next({
      controlName: name,
      value: control.value,
      type: RaFormControlChangeNotificationType.Update
    });
  }
  subscribeControl(name) {
    const formPart = this.get(name);
    if (formPart instanceof RaUiFormControl) {
      this._subscriptions[name] = formPart.valueChanges.subscribe(value => {
        setTimeout(() => {
          //#929
          this.objectChanges.next({
            controlName: name,
            value: value,
            type: RaFormControlChangeNotificationType.Update
          });
        });
      });
    } else if (formPart instanceof RaUiFormGroup) {
      this._subscriptions[name] = formPart.objectChanges.subscribe(notification => {
        setTimeout(() => {
          //#929
          this.objectChanges.next({
            controlName: `${name}.${notification.controlName}`,
            value: notification.value,
            type: RaFormControlChangeNotificationType.Update
          });
        });
      });
    }
    // tslint:disable-next-line no-use-before-declare
    else if (formPart instanceof RaUiFormArray) {
      this._subscriptions[name] = formPart.arrayChanges.subscribe(notification => {
        setTimeout(() => {
          //#929
          this.objectChanges.next({
            controlName: `${name}[${notification.index}]${notification.controlName === '' ? '' : '.' + notification.controlName}`,
            value: notification.value,
            type: RaFormControlChangeNotificationType.Update
          });
        });
      });
    } else {
      throw new Error('Invalid Form Control type with name ' + name + '.');
    }
  }
  unsubscribeControl(name) {
    const subscription = this._subscriptions[name];
    if (subscription) {
      subscription.unsubscribe();
      delete this._subscriptions[name];
    }
  }
}
function isRaUiFormGroup(val) {
  return val instanceof RaUiFormGroup;
}
/**
 * Accessor of Angular Form Array object which provides extended functionality.
 */
class RaUiFormArray extends FormArray {
  constructor(controls, validatorOrOpts, asyncValidator) {
    super(controls, validatorOrOpts, asyncValidator);
    /**
     * Subscribe here for value changes.
     */
    this.arrayChanges = new Subject();
    this._subscriptions = {};
    for (let i = 0; i < controls.length; i++) {
      this.subscribeControl(i);
    }
  }
  /**
   * Pushes new item into array.
   * @param control Control instance.
   */
  push(control) {
    super.push(control);
    this.subscribeControl(this.controls.length - 1);
    this.arrayChanges.next({
      controlName: '',
      index: this.controls.length - 1,
      value: control.value,
      type: RaFormControlChangeNotificationType.Add
    });
  }
  /**
   * Inserts new control under given index.
   * @param index Index of newly added control.
   * @param control Control instance.
   */
  insert(index, control) {
    super.insert(index, control);
    // re-index subscriptions
    for (let i = index; i < this.controls.length; i++) {
      this.unsubscribeControl(i);
      this.subscribeControl(i);
    }
    this.arrayChanges.next({
      controlName: '',
      index: index,
      value: control.value,
      type: RaFormControlChangeNotificationType.Add
    });
  }
  /**
   * Removes control at specific index.
   * @param index Index to remove.
   */
  removeAt(index) {
    this.unsubscribeControl(index);
    super.removeAt(index);
    this.arrayChanges.next({
      controlName: '',
      index: index,
      value: null,
      //eslint-disable-line no-null/no-null
      type: RaFormControlChangeNotificationType.Remove
    });
    // shift subscriptions
    for (let i = index; i < this.controls.length; i++) {
      this.unsubscribeControl(i + 1);
      this.subscribeControl(i);
    }
  }
  /**
   * Replaces control at specific index.
   * @param index Index to replace.
   * @param control Control instance.
   */
  setControl(index, control) {
    this.unsubscribeControl(index);
    super.setControl(index, control);
    this.subscribeControl(index);
    this.arrayChanges.next({
      controlName: '',
      index: index,
      value: control.value,
      type: RaFormControlChangeNotificationType.Update
    });
  }
  subscribeControl(index) {
    const formPart = this.at(index);
    if (formPart instanceof RaUiFormControl) {
      this._subscriptions[index] = formPart.valueChanges.subscribe(value => {
        this.arrayChanges.next({
          controlName: '',
          index: index,
          value: value,
          type: RaFormControlChangeNotificationType.Update
        });
      });
    } else if (formPart instanceof RaUiFormGroup) {
      this._subscriptions[index] = formPart.objectChanges.subscribe(notification => {
        this.arrayChanges.next({
          controlName: notification.controlName,
          index: index,
          value: notification.value,
          type: RaFormControlChangeNotificationType.Update
        });
      });
    } else if (formPart instanceof RaUiFormArray) {
      this._subscriptions[index] = formPart.arrayChanges.subscribe(notification => {
        this.arrayChanges.next({
          controlName: notification.controlName + '[' + index + ']',
          index: notification.index,
          value: notification.value,
          type: RaFormControlChangeNotificationType.Update
        });
      });
    }
  }
  unsubscribeControl(index) {
    const subscription = this._subscriptions[index];
    if (subscription) {
      subscription.unsubscribe();
      delete this._subscriptions[index];
    }
  }
}
export { RaUiFormArray, RaUiFormGroup, isRaUiFormGroup };
