/**
 * RA WTUI default state matcher.
 */
class RaUiDefaultStateMatcher {
  /**
   * Determines whether a component is in error state.
   */
  isErrorState(control,
  //eslint-disable-next-line unused-imports/no-unused-vars
  form) {
    return !!(control && control.invalid && control.touched);
  }
}
export { RaUiDefaultStateMatcher };
