import { KeyCode, SizeUnits } from './basic-types/enums.mjs';
import { instanceOfSizeWithUnits } from './basic-types/type-guards.mjs';

/**
 * Function that returns key from {@link KeyboardEvent}.
 * Handles altKey modifier (macOS).
 * Handles Numeric keypad numbers.
 */
function getKeyFromKeyboardEvent(event) {
  if (event.key && !event.altKey) {
    return event.key;
  }
  //magic from StackOverflow
  /* eslint-disable quote-props */
  const toAscii = {
    '188': '44',
    '109': '45',
    '190': '46',
    '191': '47',
    '192': '96',
    '220': '92',
    '222': '39',
    '221': '93',
    '219': '91',
    '173': '45',
    '187': '61',
    //IE Key codes
    '186': '59',
    //IE Key codes
    '189': '45' //IE Key codes
  };
  const shiftUps = {
    '96': '~',
    '49': '!',
    '50': '@',
    '51': '#',
    '52': '$',
    '53': '%',
    '54': '^',
    '55': '&',
    '56': '*',
    '57': '(',
    '48': ')',
    '45': '_',
    '61': '+',
    '91': '{',
    '93': '}',
    '92': '|',
    '59': ':',
    '39': '"',
    '44': '<',
    '46': '>',
    '47': '?'
  };
  /* eslint-enable quote-props */
  let keyCode = event.which;
  let char;
  if (toAscii.hasOwnProperty(keyCode)) {
    keyCode = toAscii[keyCode.toString()];
  }
  if (!event.shiftKey && typeof keyCode === 'number' && keyCode >= 65 && keyCode <= 90) {
    char = String.fromCharCode(keyCode + 32);
  } else if (event.shiftKey && shiftUps.hasOwnProperty(keyCode)) {
    char = shiftUps[keyCode.toString()];
  } else {
    char = String.fromCharCode(KeyCode.NUMPAD_0 <= keyCode && keyCode <= KeyCode.NUMPAD_9 ? keyCode - KeyCode.NUMBER_0 : keyCode);
  }
  return char;
}
/**
 * Size with units class implementing {@link ISizeWithUnits} interface.
 */
class SizeWithUnits {
  /**
   * Converts Size to SizeWithUnits.
   */
  static fromSize(size) {
    if (instanceOfSizeWithUnits(size)) {
      return size;
    }
    return new SizeWithUnits(size);
  }
  constructor(size, units = SizeUnits.Pixels) {
    this.size = size;
    this.units = units;
  }
}
export { SizeWithUnits, getKeyFromKeyboardEvent };
