import * as _ from 'lodash';
import { SimpleChange, EventEmitter } from '@angular/core';
class AgGridUtils {
  static assignPropsFromAgInit(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  params,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  component,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultProps) {
    if (!params || !_.isObject(params.value)) {
      return;
    }
    // Store instance to component so we can use it later
    params.value.component = component;
    let changes;
    if (!params.value.hasOwnProperty('props')) {
      changes = {};
      changes.props = new SimpleChange(undefined, defaultProps, true);
      params.value.props = defaultProps;
    } else {
      changes = AgGridUtils.setPropsByObject(params.value.props, component, defaultProps);
      //We need to handle config separately because we have cases where we need to set only partial configs by default
      if (params.value.props.config && defaultProps && _.isObject(defaultProps.config)) {
        for (const defaultProp in defaultProps.config) {
          if (defaultProps.config.hasOwnProperty(defaultProp) && params.value.props.config[defaultProp] === undefined) {
            params.value.props.config[defaultProp] = defaultProps.config[defaultProp];
          }
        }
      }
    }
    if (component['ngOnChanges']) {
      //eslint-disable-line dot-notation
      component['ngOnChanges'](changes); //eslint-disable-line dot-notation
    }
    if (params.value.hasOwnProperty('onAgInit') && typeof params.value.onAgInit === 'function') {
      params.value.onAgInit(component, params.value);
    }
  }
  static setPropsByObject(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  props,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  component,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultProps) {
    const changes = {};
    if (!_.isObject(defaultProps)) {
      defaultProps = {};
    }
    for (const prop in props) {
      if (props.hasOwnProperty(prop)) {
        changes[prop] = new SimpleChange(component[prop], props[prop], true);
        component[prop] = props[prop];
      }
    }
    for (const defaultProp in defaultProps) {
      if (defaultProps.hasOwnProperty(defaultProp) && !props.hasOwnProperty(defaultProp)) {
        changes[defaultProp] = new SimpleChange(component[defaultProp], defaultProps[defaultProp], true);
        component[defaultProp] = defaultProps[defaultProp];
      }
    }
    return changes;
  }
  static getPropsFromNativeGridParams(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  params,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  component) {
    let parsedProps = {};
    // this is how we distinguish between renderer and editor
    const userParams = params?.cellStartedEdit ? params?.colDef?.cellEditorParams : params?.colDef?.cellRendererParams;
    // the userParams are resolved like in native AgGrid code
    if (_.isFunction(userParams)) {
      parsedProps = userParams(params);
    } else if (_.isObject(userParams)) {
      parsedProps = userParams;
    }
    // we need to check the userParams, if there are any we dont want to assign
    const propsToAssign = {};
    // we need to exclude methods (without setters / getters) for the next processing
    const componentMethods = Object.getOwnPropertyNames(Object.getPrototypeOf(component)).filter(p => {
      return typeof component[p] === 'function';
    });
    for (const prop of Object.keys(parsedProps)) {
      // the params.value is executed via control value accessor below
      // we want to exclude properties on components like functions
      if (prop !== 'value' && componentMethods.indexOf(prop) === -1 && !(component[prop] instanceof EventEmitter)) {
        propsToAssign[prop] = parsedProps[prop];
      }
    }
    return propsToAssign;
  }
}
export { AgGridUtils };
