import * as _ from 'lodash';
import { isPrefixSuffixType } from '../types/types.mjs';
import { RaUiInputValidationService } from '../../../../cdk-input-validation/esm2022/lib/ra-ui-input-validation.service.mjs';
import { LoggerUtils } from '../../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
const DEFAULT_HINT_MESSAGE = '';
const DEFAULT_PLACEHOLDER = '';
const DEFAULT_PREFIX = undefined;
const DEFAULT_SUFFIX = undefined;
class RaUiFormSystemInputValidationService extends RaUiInputValidationService {
  constructor(logger) {
    super(logger);
    this.logger = logger;
    this._isReadOnly = false;
    this._hintMessageStart = DEFAULT_HINT_MESSAGE;
    this._hintMessageEnd = DEFAULT_HINT_MESSAGE;
    this._placeholder = DEFAULT_PLACEHOLDER;
    this._prefix = DEFAULT_PREFIX;
    this._suffix = DEFAULT_SUFFIX;
  }
  /**
   * Performs readOnly validation and sets corresponding property.
   * @param value - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setIsReadOnly(value, compName) {
    if (!_.isBoolean(value)) {
      this._isReadOnly = false;
      LoggerUtils.logInvalidInput(compName, 'isReadOnly', value, 'boolean', this.logger);
    } else {
      this._isReadOnly = value;
    }
  }
  /**
   * Performs start hint message validation and sets corresponding property.
   * @param message - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setHintMessageStart(message, compName) {
    if (_.isString(message)) {
      this._hintMessageStart = message;
    } else {
      this._hintMessageStart = DEFAULT_HINT_MESSAGE;
      //log error to the console
      LoggerUtils.logInvalidInput(compName, 'hintMessageStart', message, 'string', this.logger);
    }
  }
  /**
   * Performs end hint message validation and sets corresponding property.
   * @param message - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setHintMessageEnd(message, compName) {
    if (_.isString(message)) {
      this._hintMessageEnd = message;
    } else {
      this._hintMessageEnd = DEFAULT_HINT_MESSAGE;
      // log error to the console
      LoggerUtils.logInvalidInput(compName, 'hintMessageEnd', message, 'string', this.logger);
    }
  }
  /**
   * Performs placeholder validation and sets corresponding property.
   * @param placeholder - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setPlaceholder(placeholder, compName) {
    if (_.isString(placeholder)) {
      this._placeholder = placeholder;
    } else {
      this._placeholder = DEFAULT_PLACEHOLDER;
      LoggerUtils.logInvalidInput(compName, 'placeholder', placeholder, 'string', this.logger);
    }
  }
  /**
   * Performs prefix validation and sets corresponding property.
   * @param prefix - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setPrefix(prefix, compName) {
    if (isPrefixSuffixType(prefix)) {
      this._prefix = prefix;
    } else {
      this._prefix = DEFAULT_PREFIX;
      LoggerUtils.logInvalidInput(compName, 'prefix', prefix, 'PrefixSuffixType', this.logger);
    }
  }
  /**
   * Performs suffix validation and sets corresponding property.
   * @param suffix - value to be validated and set
   * @param compName - component name to be logged in eventual warn message
   */
  setSuffix(suffix, compName) {
    if (isPrefixSuffixType(suffix)) {
      this._suffix = suffix;
    } else {
      this._suffix = DEFAULT_SUFFIX;
      LoggerUtils.logInvalidInput(compName, 'suffix', suffix, 'PrefixSuffixType', this.logger);
    }
  }
}
export { RaUiFormSystemInputValidationService };
