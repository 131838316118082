import * as _ from 'lodash';
import { RaUiFormControl } from '../../form/form-control.mjs';
import { FloatLabel, isFloatLabel } from '../../types/types.mjs';
import { LoggerUtils } from '../../../../../cdk-logger/esm2022/lib/loggerUtils.mjs';
class FormTypeUtils {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  static writeValueCheck(params) {
    let result = params.receivedVal;
    if (!_.isNil(result) && !params.checkFun(result)) {
      LoggerUtils.logInvalidInput(params.component, 'ngModel', result, params.expectedType, params.logger);
      result = params.defaultVal;
      if (!_.isNil(params.ngControl) && !_.isNil(params.ngControl.control)) {
        params.ngControl.control instanceof RaUiFormControl ? params.ngControl.control.setValue(result, undefined, true) : params.ngControl.control.setValue(result);
      }
    }
    return result;
  }
  static parseFloatLabel(val) {
    let result = _.get(val, 'defaultFloatLabel', FloatLabel.Auto);
    const componentName = _.get(val, 'componentName');
    const logger = _.get(val, 'logger');
    const localFloatLabel = _.get(val, 'options.floatLabel');
    const globalFloatLabel = _.get(val, 'globalOptions.floatLabel');
    if (isFloatLabel(localFloatLabel)) {
      result = localFloatLabel;
    } else if (isFloatLabel(globalFloatLabel)) {
      result = globalFloatLabel;
    } else if (!_.isNil(localFloatLabel) && !isFloatLabel(localFloatLabel)) {
      logger && LoggerUtils.logInvalidInput(componentName, 'floatLabel', localFloatLabel, 'FloatLabel', logger);
    } else if (!_.isNil(globalFloatLabel) && !isFloatLabel(globalFloatLabel)) {
      logger && LoggerUtils.logInvalidInput(componentName, 'floatLabel', globalFloatLabel, 'FloatLabel', logger);
    }
    return result;
  }
}
export { FormTypeUtils };
