import { MenuCssConstants, ItemType } from './menu.types.mjs';
import * as _ from 'lodash';
import { TypographyLevels } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';

/**@docs-private*/
class MenuUtils {
  static calculateMenuItemHeight(typography, customItemHeight) {
    const result = !customItemHeight ? typography.getTypographyLevel(TypographyLevels.Body1).fontSize * MenuCssConstants.buttonHeightRatio : customItemHeight + 2 * typography.getTypographyLevel(TypographyLevels.Body1).fontSize * MenuCssConstants.itemPaddingRatio;
    return result;
  }
  static calculateMenuHeight(typography, menuItems, numberOfItems, customItemHeight) {
    if (customItemHeight) {
      const itemPadding = 2 * typography.getTypographyLevel(TypographyLevels.Body1).fontSize * MenuCssConstants.itemPaddingRatio;
      return numberOfItems * (customItemHeight + itemPadding);
    }
    /*
        Method can be used in Menu component which already includes categories in the list
        but also in other components where categories are not yet included.
        To make calculation consistent we filter category items first.
        */
    const itemList = menuItems.filter(item => {
      return !item._isCategory;
    });
    // Calculate final number of items, item captions and item categories that fit into defined number of items.
    let itemCount = numberOfItems;
    let categoryCount = 0;
    let captionCount = 0;
    const categories = [];
    for (let i = 0; i < itemCount; i++) {
      if (!_.isNil(itemList[i]?.category)) {
        if (!categories.includes(itemList[i].category)) {
          categories.push(itemList[i].category);
          categoryCount++;
          itemCount--;
        }
      }
      if (!_.isNil(itemList[i]?.caption) && itemCount > 0) {
        captionCount++;
      }
    }
    const categoryPadding = 2 * typography.getTypographyLevel(TypographyLevels.Input).fontSize * MenuCssConstants.categoryPaddingRatio;
    const itemHeight = typography.getTypographyLevel(TypographyLevels.Body1).fontSize * MenuCssConstants.buttonHeightRatio;
    const categoryHeight = typography.getTypographyLevel(TypographyLevels.Input).lineHeight + categoryPadding;
    const captionHeight = typography.getTypographyLevel(TypographyLevels.Caption).lineHeight;
    return itemCount * itemHeight + categoryCount * categoryHeight + captionCount * captionHeight;
  }
  static getItemType(item) {
    if (item.isDivider) {
      return ItemType.ITEM_DIVIDER;
    }
    if (item._isCategory) {
      return ItemType.CATEGORY_LABEL;
    }
    return ItemType.DEFAULT;
  }
}
export { MenuUtils };
