import * as _ from 'lodash';
import * as i0 from '@angular/core';
import { createComponent, Injectable } from '@angular/core';
import { HiddenStylesComponent } from './hidden-styles/hidden-styles.component.mjs';
import { RaUiTypographyModule } from './ra-ui-typography.module.mjs';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NullableBehaviorSubject } from '../../../cdk-nullable-behavior-subject/esm2022/lib/nullable-behavior-subject.mjs';
import { TypographyLevels } from '../../../cdk-types/esm2022/lib/basic-types/enums.mjs';
class TypographyService {
  static {
    this.typographyChange = new NullableBehaviorSubject();
  }
  static {
    this._typographyLevels = {};
  }
  /**
   * The shadowRootEl setter should be used in the component with ViewEncapsulation.Shadow
   * When the Typography service is used somewhere under the ShadowRoot it is necessary to be set.
   * Otherwise, the components which uses Typography service under shadowRoot does not work correctly.
   */
  set _shadowRootEl(shadowRoot) {
    this.__shadowRootEl = shadowRoot;
    this.appendComponentToHostEl(shadowRoot);
    this.updateItems();
  }
  get _shadowRootEl() {
    return this.__shadowRootEl;
  }
  constructor(appRef, injector) {
    this.appRef = appRef;
    this.injector = injector;
    this.destroyed = new Subject();
    this.hostEl = document.body;
    this.hiddenCompTagName = 'ra-ui-hidden';
    this.initHiddenStylesComponent();
    this.appendComponentToHostEl();
    this.onDocumentLoad();
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
  /**
   * The method reads all typography values from html template and calls next by Subject
   */
  refresh() {
    this.updateItems();
    TypographyService.typographyChange.next(this.getAllTypographyLevels());
  }
  /**
   * The method reads all typography values from html template and calls next by Subject only in case there is some change
   */
  refreshWhenChange() {
    const previousTypographyLevels = TypographyService._typographyLevels;
    this.updateItems();
    if (_.isEqual(previousTypographyLevels, TypographyService._typographyLevels)) {
      return;
    }
    TypographyService.typographyChange.next(this.getAllTypographyLevels());
  }
  /**
   * The method returns values by specific level
   * @param level specific typography level
   */
  getTypographyLevel(level) {
    _.isEmpty(TypographyService._typographyLevels) && this.updateItems();
    return TypographyService._typographyLevels[level];
  }
  /**
   * The method returns collection to all typography levels with typography values
   */
  getAllTypographyLevels() {
    _.isEmpty(TypographyService._typographyLevels) && this.updateItems();
    return TypographyService._typographyLevels;
  }
  onDocumentLoad() {
    // global handler to be sure there is everything updated
    fromEvent(window, 'load').pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.refreshWhenChange();
    });
  }
  /**
   * Dynamically creates the HiddenStylesComponent and attach the component to AppRef
   */
  initHiddenStylesComponent() {
    //Create a component reference from the component
    this.hiddenStylesCmpRef = createComponent(HiddenStylesComponent, {
      environmentInjector: this.injector
    });
    const instance = this.hiddenStylesCmpRef.instance;
    this.typographyWrapperSelector = instance._wrapperClass;
    instance.changeRef.detectChanges();
    instance.initialized.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.refresh();
    });
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(this.hiddenStylesCmpRef.hostView);
  }
  /**
   * Method that gets and ensures the HiddenStylesComponent exists under host element by getting it from their children
   */
  getHiddenStylesElement() {
    const children = Array.from(this.hostEl.children);
    return children.find(child => child === this.hiddenStylesCmpRef.location.nativeElement);
  }
  /**
   * Method that append HiddenStylesComponent to the provided shadowRootEl.
   * When the shadowRootEl is not provided (most of the cases will be used in light DOM) then it uses document.body
   */
  appendComponentToHostEl(shadowRootEl) {
    this.hostEl = shadowRootEl ?? document.body;
    // Append DOM element to the body only if does not exist on target
    !this.getHiddenStylesElement() && this.hostEl.appendChild(this.hiddenStylesCmpRef.location.nativeElement);
  }
  updateItems() {
    TypographyService._typographyLevels = {};
    _.forEach(TypographyLevels, level => {
      TypographyService._typographyLevels[level] = this.getTypography(level);
    });
  }
  getTypography(name) {
    const wrapperElement = this.getHiddenStylesElement()?.getElementsByClassName(this.typographyWrapperSelector)[0];
    if (_.isNil(wrapperElement)) {
      throw new Error(`Typography ${name} does not exist`);
    }
    const searchedElement = wrapperElement.getElementsByClassName(name);
    const styles = getComputedStyle(searchedElement[0]);
    const letterSpacing = styles.getPropertyValue('letter-spacing');
    return {
      fontSize: parseFloat(styles.getPropertyValue('font-size')),
      fontWeight: parseFloat(styles.getPropertyValue('font-weight')),
      lineHeight: parseFloat(styles.getPropertyValue('line-height')),
      fontFamily: styles.getPropertyValue('font-family'),
      letterSpacing: _.lowerCase(letterSpacing) === 'normal' ? letterSpacing : parseFloat(letterSpacing)
    };
  }
  /** @nocollapse */
  static {
    this.ɵfac = function TypographyService_Factory(t) {
      return new (t || TypographyService)(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.EnvironmentInjector));
    };
  }
  /** @nocollapse */
  static {
    this.ɵprov = /** @pureOrBreakMyCode */i0.ɵɵdefineInjectable({
      token: TypographyService,
      factory: TypographyService.ɵfac,
      providedIn: RaUiTypographyModule
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
export { TypographyService };
