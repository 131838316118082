import * as _ from 'lodash';
import { TypeUtils } from '../../../cdk-types/esm2022/lib/type-utils/type-utils.mjs';

/**
 * Checks if value is IIpDisabledStateObject
 */
const isIpDisabledStateObject = val => {
  return !_.isNil(val) && TypeUtils.hasOnlyProvidedProperties(val, ['first', 'second', 'third', 'fourth']) && TypeUtils.isObjectValid(val, [{
    name: 'first',
    checkFunction: value => {
      return _.isBoolean(value);
    }
  }, {
    name: 'second',
    checkFunction: value => {
      return _.isBoolean(value);
    }
  }, {
    name: 'third',
    checkFunction: value => {
      return _.isBoolean(value);
    }
  }, {
    name: 'fourth',
    checkFunction: value => {
      return _.isBoolean(value);
    }
  }]);
};
export { isIpDisabledStateObject };
