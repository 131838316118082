import * as _ from 'lodash';
import { DEFAULT_MINIMUM_NUMBER_PADDING, StringAsNumberUtils } from '../../../cdk-utils/esm2022/lib/string-as-number-utils/string-as-number-utils.mjs';
class NumericDisplayOpts {
  constructor(opts) {
    this.numberPadding = opts && !_.isNil(opts.numberPadding) && _.gt(opts.numberPadding, 0) ? opts.numberPadding : DEFAULT_MINIMUM_NUMBER_PADDING;
  }
}
/**
 * Abstract base class for numeric display types.
 */
class NumericDisplayType {
  constructor(numericOpts) {
    /**
     * Regular expression pattern.
     */
    this.pattern = /^[+-]?\d*$/;
    /**
     * Options for the NumericDisplayType that are set through the constructor
     */
    this.opts = new NumericDisplayOpts();
    this.opts = new NumericDisplayOpts(numericOpts);
  }
  isInputValidFn(s) {
    // Infinity and NaN are allowed and they are excluded from validation.
    if (s === 'Infinity' || s === 'NaN') {
      return true;
    }
    return this.pattern.test(s);
  }
  onBlur(s) {
    // Keep invalid or empty string as it is
    // Keep string on blur for Infinity or NaN.
    if (s === 'Infinity' || s === 'NaN' || s === '' || !this.pattern.test(s)) {
      return s;
    }
    s = StringAsNumberUtils.removePlusSign(s);
    s = StringAsNumberUtils.removeLeadingZeroes(s);
    s = StringAsNumberUtils.addPadding(s, this.opts.numberPadding);
    if (StringAsNumberUtils.checkMinusZero(s)) {
      return s.replace(/[-]/, '');
    }
    return s;
  }
}
export { NumericDisplayOpts, NumericDisplayType };
